import React from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { formatLabwareTypeName, LabwareType } from 'client/app/state/LabwarePreference';
import Colors from 'common/ui/Colors';

type Props = {
  labwareType?: LabwareType;
  numSelected: number;
  isExpanded: boolean;
  onClick: () => void;
};

export function LabwareChip({ labwareType, numSelected, isExpanded, onClick }: Props) {
  const isActive = labwareType !== undefined;
  const label = labwareType
    ? `[${numSelected}] ${formatLabwareTypeName(labwareType)}`
    : 'Select Labware';

  return (
    <Chip onClick={onClick}>
      <LabelContainer isActive={isActive}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </LabelContainer>
      <EndIconContainer isActive={isActive}>
        {isExpanded ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
      </EndIconContainer>
    </Chip>
  );
}

const Chip = styled('div')({
  cursor: 'pointer',
  display: 'flex',
});

const LabelContainer = styled('div', {
  shouldForwardProp: props => props !== 'isActive',
})<{ isActive: boolean }>(({ isActive, theme }) => ({
  alignItems: 'center',
  borderRadius: '16px 0 0 16px',
  display: 'flex',
  height: '32px',
  padding: theme.spacing(2, 3, 2, 5),
  ...(isActive
    ? { backgroundColor: Colors.GREY_70, color: Colors.PRIMARY_CONTRAST }
    : {
        backgroundColor: Colors.GREY_0,
        border: `1px solid ${Colors.GREY_30}`,
      }),
}));

const EndIconContainer = styled('div', {
  shouldForwardProp: props => props !== 'isActive',
})<{ isActive: boolean }>(({ isActive, theme }) => ({
  alignItems: 'center',
  borderRadius: '0 16px 16px 0',
  display: 'flex',
  height: '32px',
  paddingRight: theme.spacing(2),
  ...(isActive
    ? { color: Colors.PRIMARY_CONTRAST, backgroundColor: Colors.PRIMARY_DARK }
    : {
        backgroundColor: Colors.GREY_0,
        border: `1px solid ${Colors.GREY_30}`,
        borderLeft: 'none',
      }),
}));
