import React from 'react';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import PlateLayoutEditorContents from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContents';
import { PlateNamesWithAssignment } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';

type Props = {
  value: PlateNamesWithAssignment;
  onChange: (newValue: PlateNamesWithAssignment) => void;
  isDisabled: boolean;
  hasLiquidsInPlate: boolean;
  elementInstanceId: string;
  onClose: () => void;
};

export default function PlateLayoutEditorPanel(props: Props) {
  return (
    <Grow in unmountOnExit>
      <Panel elevation={4}>
        <PlateLayoutEditorContents {...props} />
      </Panel>
    </Grow>
  );
}

const Panel = styled(Paper)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  height: '100%',
  width: '100%',
  overflow: 'auto',
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
});
