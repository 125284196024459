import React, { useCallback } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';

import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { ConfigParameterList } from 'client/app/components/WorkflowSettings/ConfigParameterList';
import { CHERRYPICK_CONFIG } from 'client/app/lib/workflow/workflowConfigProperties';
import { ScreenRegistry } from 'client/app/registry';
import { ParameterValue } from 'common/types/bundle';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function OtherSimulationSettings() {
  const { setBundleConfig, bundleConfig, isReadonly } = useCherryPickContext();

  const handleChangeSettings = useCallback(
    (parameterChanged: string, value: ParameterValue) => {
      logEvent('settings-others', ScreenRegistry.CHERRY_PICKER, parameterChanged);
      setBundleConfig(prevValues => ({
        ...prevValues,
        global: {
          ...prevValues.global,
          [parameterChanged]: value,
        },
      }));
    },
    [setBundleConfig],
  );

  return (
    <Paper>
      <CardHeader title="Simulation settings" />
      <CardContent>
        <DialogContentText gutterBottom>
          Please specify these additional parameters for the simulation
        </DialogContentText>
        <ConfigParameterList
          parameters={CHERRYPICK_CONFIG}
          parameterValueDict={bundleConfig.global || {}}
          onChange={handleChangeSettings}
          isDisabled={isReadonly}
        />
      </CardContent>
    </Paper>
  );
}
