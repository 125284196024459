import React, { useCallback } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';

import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import DeviceSelector from 'client/app/components/Parameters/DeviceSelector';
import { ScreenRegistry } from 'client/app/registry';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { ConfiguredDevice } from 'common/types/bundle';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function DeviceSettings() {
  const { setBundleConfig, bundleConfig, setSelectedDevice, isReadonly } =
    useCherryPickContext();
  const { setActiveConfiguredDeviceIds } = useConfiguredDevicesContext();

  const handleSelectDevices = useCallback(
    (configuredDevices: ConfiguredDevice[]) => {
      logEvent('settings-change-device', ScreenRegistry.CHERRY_PICKER);
      const configWithDevices = {
        ...bundleConfig,
        configuredDevices: configuredDevices,
      };
      setBundleConfig(configWithDevices);
      setSelectedDevice(configuredDevices);
      setActiveConfiguredDeviceIds(configuredDevices.map(v => v.id));
    },
    [bundleConfig, setActiveConfiguredDeviceIds, setBundleConfig, setSelectedDevice],
  );

  return (
    <Paper>
      <CardHeader title="Select an execution mode" />
      <CardContent>
        <DialogContentText gutterBottom>
          Please select an execution mode to run this CherryPick on
        </DialogContentText>
        <DeviceSelector
          configuredDevices={bundleConfig.configuredDevices ?? []}
          onSelectedDevicesChange={handleSelectDevices}
          isDisabled={isReadonly}
        />
      </CardContent>
    </Paper>
  );
}
