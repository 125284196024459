import { useMemo } from 'react';

import { Identifiable } from 'client/app/lib/workflow/cloneWithUUID';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { Connection, ElementInstance, Liquid } from 'common/types/bundle';

export function buildElementContext(
  element: ElementInstance,
  elements: ElementInstance[],
  connections: Identifiable<Connection>[],
) {
  return {
    outputs: Object.fromEntries(
      Object.entries(element.Meta.outputs ?? {}).map(([param, value]) => {
        const parameter = element.element.outputs.find(p => p.name === param);
        return [param, { parameter, value }];
      }),
    ),
    inputs: Object.fromEntries(
      connections.flatMap(conn => {
        if (conn.Target.ElementInstance === element.name) {
          const source = elements.find(e => e.name === conn.Source.ElementInstance);
          if (source) {
            const parameter = element.element.inputs.find(
              p => p.name === conn.Target.ParameterName,
            );

            return [
              [
                conn.Target.ParameterName,
                {
                  parameter,
                  value: source.Meta.outputs?.[conn.Source.ParameterName],
                },
              ],
            ];
          }
        }

        return [];
      }),
    ),
  };
}

export function useElementContext(elementInstanceId: string) {
  const elements = useWorkflowBuilderSelector(state => state.elementInstances);
  const element = elements.find(element => element.Id === elementInstanceId);
  const connections = useWorkflowBuilderSelector(state => state.InstancesConnections);
  const isSaving = useWorkflowBuilderSelector(state => state.isSaving);

  return useMemo(() => {
    if (!element) {
      return { loading: false, context: null };
    }

    return {
      loading: isSaving, // Loading completed when the workflow has saved
      context: buildElementContext(element, elements, connections),
    };
  }, [connections, element, elements, isSaving]);
}

/**
 * Gets the array of liquids from a defined input parameter.
 * The input parameter should be of type Liquid[]
 *
 * @param elementInstanceId id of the element of interest
 * @param inputParameter The element input parameter to read liquids from.
 * @returns An array of Liquid.
 */
export function useInputLiquids(
  elementInstanceId: string,
  inputParameter: string | string[],
) {
  const { loading, context } = useElementContext(elementInstanceId);

  return useMemo(() => {
    const params = Array.isArray(inputParameter) ? inputParameter : [inputParameter];
    const inputs = params.map(
      inputParameter => (context?.inputs[inputParameter]?.value as Liquid[]) ?? [],
    );

    return { loading, inputLiquids: inputs };
  }, [context?.inputs, inputParameter, loading]);
}
