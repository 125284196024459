import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  widthWrap: number;
  containerRef: React.RefObject<HTMLDivElement>;
};

export function useContainerResize({ widthWrap, containerRef }: Props) {
  const [isNarrowWidth, setIsNarrowWidth] = useState(false);

  const onWindowResize = useCallback(() => {
    if (containerRef?.current) {
      setIsNarrowWidth(containerRef.current.clientWidth < widthWrap);
    }
  }, [containerRef, widthWrap]);

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  return isNarrowWidth;
}
