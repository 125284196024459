import React, { useCallback, useMemo } from 'react';

import {
  ExistingPlate,
  PlateParameterValue,
} from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import {
  ArrayAdditionalProps,
  AutocompleteAdditionalProps,
  MapAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getArrayTypeFromAnthaType,
  getCompoundEditorTypeFromAnthaType,
  getDefaultEditorForAnthaType,
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { alphanumericCompare } from 'common/lib/strings';
import { mapObject } from 'common/object';
import { BundleParameters, ElementInstance, ParameterValue } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { FiltrationDesign } from 'common/types/filtration';
import { WellContents } from 'common/types/mix';
import { PlateSetDescription } from 'common/types/plateSetDescription';
import { SheetConfiguration, SpreadsheetConfiguration } from 'common/types/spreadsheet';
import { CellValue, DataTable } from 'common/types/spreadsheetEditor';

/**
 * Corresponds to the value of 'maps.DefaultKey' in the elements stdlib. This
 * is a special value that is added to Autocomplete editors via the
 * provideDefaultKey option and used as part of some map keys.
 **/
export const STDLIB_DEFAULT_KEY = 'default';

export const LIQUID_NAME_ANTHA_TYPE =
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidName';
export const FILTER_MATRIX_NAME_ANTHA_TYPE =
  'github.com/Synthace/antha/stdlib/schemas/aliases.FilterMatrixName';
export const PLATE_NAME_ANTHA_TYPE =
  'github.com/Synthace/antha/stdlib/schemas/aliases.PlateSetName';
export const COLUMN_NAME_ANTHA_TYPE =
  'github.com/Synthace/antha/antha/anthalib/data.ColumnName';
const LIQUID_GROUP_NAME_ANTHA_TYPE =
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidGroupName';

type AutocompleteCalculationMap = Record<string, Set<ParameterValue>>;
type AutocompleteOptionsMap = Record<string, ParameterValue[]>;

export type AutocompleteParameterValuesContext = {
  /**  A map of types to a list of values used for parameters of that type, e.g:
   *   {
   *     "RESIN_TYPE": ['resin A', 'resin B'],
   *     "LIQUID_TYPE": ['glycerol', 'tryptone', 'water']
   *   }
   * */
  autocompleteOptions: AutocompleteOptionsMap;
  /** Returns a sorted list of options for the given type */
  optionsForType: <T = string>(type: string) => T[];
  /** Returns a sorted list of options for the given types */
  optionsForTypes: <T = string>(types: string[]) => T[];
};

function getOptionsForOptionsMapAndType<T>(
  optionsMap: AutocompleteOptionsMap,
  type: string,
): T[] {
  return optionsMap[type] ?? [];
}

function optionsForType(_: string) {
  return [];
}

function optionsForTypes(_: string[]) {
  return [];
}

const DEFAULT_CONTEXT = {
  autocompleteOptions: {},
  optionsForType,
  optionsForTypes,
};

export const AutocompleteParameterValuesContext =
  React.createContext<AutocompleteParameterValuesContext>(DEFAULT_CONTEXT);

type Props = {
  instances: ElementInstance[];
  parameters: BundleParameters;
  stagedParameters?: BundleParameters;
  children: React.ReactNode;
};

function isValidAutocompleteValue(value: ParameterValue) {
  return !(
    value === undefined ||
    value === null ||
    value === '' ||
    // Omitting strings that start with "*" is to support the integrated DOE(ms)
    // tooling. (The user shouldn't see map keys beginning with "*", as these
    // are used to serialize DOE-specific information.) TODO: Remove this
    // https://phabricator.synthace.us/T5846.
    (typeof value === 'string' && value.startsWith('*'))
  );
}

function isExistingPlate(plate: PlateParameterValue): plate is ExistingPlate {
  return plate !== null && typeof plate !== 'string';
}

export const calculateAutocompleteOptions = (
  instances: ElementInstance[],
  allParameters: BundleParameters,
): AutocompleteOptionsMap => {
  // This map keeps a track of what values are provided for each type.
  // It's recalculated whenever the parameters are updated, which seems suboptimal but for now it doesn't
  // appear to be slow.
  const autocompleteMap: AutocompleteCalculationMap = {};

  /**
   * Handles the actual insertion of a value into the map.
   * Filters out invalid autocomplete values.
   * */
  function addValueToMapForType(value: ParameterValue, newType: string) {
    if (!isValidAutocompleteValue(value)) {
      return;
    }
    const isTypeAlreadyInMap = !!autocompleteMap[newType];
    if (!isTypeAlreadyInMap) {
      autocompleteMap[newType] = new Set<ParameterValue>();
    }
    autocompleteMap[newType].add(value);
  }

  /**
   * Given an antha type or override, inserts the constituent values under the relevant types
   * in the autocomplete map. It's recursive so that it can handle nested compound types.
   * */
  function findAndAddValueToMapForTypeRecursively(
    value: ParameterValue,
    type: string,
    configuration?: ParameterEditorConfigurationSpec,
  ) {
    const editorType =
      configuration?.type ??
      getCompoundEditorTypeFromAnthaType(type) ??
      getDefaultEditorForAnthaType(type);

    // We don't want to include null and undefined in autocomplete.
    if (!isValidAutocompleteValue(value)) {
      return;
    }

    // Base cases: it's not a compound editor type so just add the value to
    // the map straight away (with some processing if applicable).

    // If it uses an autocomplete editor, add the value under the associated
    // override.
    if (configuration?.type === EditorType.AUTOCOMPLETE) {
      const override = (configuration.additionalProps as AutocompleteAdditionalProps)
        .anthaTypeOverride;
      // The default key value is actively injected via a special option in the parameter
      // configuration settings for autocomplete editors. We don't want to include it as
      // an option in lists that have not opted into it just because it was used in a
      // another parameter.
      if (value !== STDLIB_DEFAULT_KEY) {
        addValueToMapForType(value, override ?? type);
      }
      return;
    }

    const isExistingPlateEditor =
      editorType === EditorType.EXISTING_PLATE || editorType === EditorType.PLATE;

    // Extract plate name and liquid or filter matrix names from existing
    // plates and add them to the autocomplete map under the relevant antha
    // types.
    if (isExistingPlateEditor && isExistingPlate(value)) {
      const { contents, name: plateName } = value.item;
      addValueToMapForType(plateName, PLATE_NAME_ANTHA_TYPE);

      if (!contents) {
        return;
      }
      const contentsList: WellContents[] = Object.values(contents).flatMap(Object.values);
      const liquids = contentsList.filter(liquid => liquid.kind === 'liquid_summary');
      const filterMatrices = contentsList.filter(
        liquid => liquid.kind === 'filter_matrix_summary',
      );
      liquids.forEach(({ name }) => {
        addValueToMapForType(name, LIQUID_NAME_ANTHA_TYPE);
      });
      filterMatrices.forEach(({ name }) => {
        addValueToMapForType(name, FILTER_MATRIX_NAME_ANTHA_TYPE);
      });
      return;
    } else if (editorType === EditorType.PLATE_DESCRIPTIONS) {
      (value as PlateSetDescription[])?.forEach(v => {
        v && addValueToMapForType(v.name, PLATE_NAME_ANTHA_TYPE);
      });
    } else if (editorType === EditorType.ARRAY) {
      const itemType = getArrayTypeFromAnthaType(type);
      const itemConfig = configuration?.additionalProps as ArrayAdditionalProps;
      value?.forEach((itemValue: any) => {
        findAndAddValueToMapForTypeRecursively(
          itemValue,
          itemType,
          itemConfig?.itemEditor ?? undefined,
        );
      });
    } else if (editorType === EditorType.MAP) {
      const mapConfig = configuration?.additionalProps as MapAdditionalProps;
      const mapKeyType = getKeyTypeFromAnthaType(type);
      Object.keys(value).forEach((key: any) => {
        findAndAddValueToMapForTypeRecursively(key, mapKeyType, mapConfig?.keyEditor);
      });
      const mapValueType = getValueTypeFromAnthaType(type);
      Object.values(value).forEach((mapValue: any) => {
        findAndAddValueToMapForTypeRecursively(
          mapValue,
          mapValueType,
          mapConfig?.valueEditor,
        );
      });
    } else if (editorType === EditorType.SPREADSHEET) {
      if (!configuration) {
        return;
      }

      const spreadsheetConfig = configuration.additionalProps as SpreadsheetConfiguration;
      const tables: DataTable[] = Array.isArray(value) ? value : [value];

      tables
        .filter(table => isValidAutocompleteValue(table))
        .forEach((table, tableIndex) => {
          if (tableIndex < spreadsheetConfig.sheets.length) {
            const valuesToAdd = extractTableValues(
              table,
              spreadsheetConfig.sheets[tableIndex],
            );
            valuesToAdd.forEach(({ value, anthaType }) => {
              addValueToMapForType(value, anthaType);
            });

            table.schema.fields.forEach(column => {
              addValueToMapForType(column.name, COLUMN_NAME_ANTHA_TYPE);
            });
          }
        });
    } else if (editorType === EditorType.FILTER_PLATE_PROTOCOL_DESIGN) {
      const design = value as FiltrationDesign;

      if (design) {
        design.liquidGroups?.forEach(({ name }) => {
          addValueToMapForType(name, LIQUID_GROUP_NAME_ANTHA_TYPE);
        });
      }
    } else {
      addValueToMapForType(value, type);
    }
  }

  for (const instance of instances) {
    for (const input of instance.element.inputs) {
      const paramValue = allParameters[instance.name]?.[input.name];
      findAndAddValueToMapForTypeRecursively(
        paramValue,
        input.type,
        input.configuration?.editor,
      );
    }
  }

  // Convert the sets into sorted lists to supply as is to input fields.
  const optionsMap = mapObject(autocompleteMap, (_type, optionSet) =>
    [...optionSet].sort(),
  );

  return optionsMap;
};

export default function AutocompleteParameterValuesContextProvider(props: Props) {
  const autocompleteMap = useMemo(
    () =>
      calculateAutocompleteOptions(props.instances, {
        ...props.parameters,
        ...(props.stagedParameters ?? {}),
      }),
    [props.instances, props.parameters, props.stagedParameters],
  );

  const optionsForType = useCallback(
    function optionsForType<T = string>(type: string) {
      return getOptionsForOptionsMapAndType<T>(autocompleteMap, type);
    },
    [autocompleteMap],
  );

  const optionsForTypes = useCallback(
    function optionsForTypes<T = string>(types: string[]) {
      const allOptions = types.flatMap(type =>
        getOptionsForOptionsMapAndType<T>(autocompleteMap, type),
      );

      return Array.from(new Set(allOptions)).sort((a, b) =>
        alphanumericCompare(String(a), String(b)),
      );
    },
    [autocompleteMap],
  );

  const context = useMemo(() => {
    return {
      autocompleteOptions: autocompleteMap,
      optionsForType,
      optionsForTypes,
    };
  }, [autocompleteMap, optionsForType, optionsForTypes]);

  return (
    <AutocompleteParameterValuesContext.Provider value={context}>
      {props.children}
    </AutocompleteParameterValuesContext.Provider>
  );
}

function extractTableValues(
  table: DataTable,
  sheetConfig: SheetConfiguration,
): { value: CellValue; anthaType: string }[] {
  const anthaTypeColumnMap = Object.fromEntries(
    sheetConfig.columns
      .filter(({ anthaType }) => anthaType !== '')
      .map(({ name, anthaType }) => [name, anthaType]),
  );

  return table.data.flatMap(row =>
    Object.entries(row)
      .map(([columnName, value]) => ({
        value,
        anthaType: anthaTypeColumnMap[columnName],
      }))
      // If the table was generated via file upload it may have columns that
      // have no configuration.
      .filter(({ anthaType }) => anthaType !== undefined),
  );
}
