import React from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Menu from '@mui/material/Menu';

import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';

type Props = {
  anchorEl: HTMLElement | null;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
  isDisabled: boolean;
};

export function ActionsMenu({ anchorEl, onEdit, onDelete, onClose, isDisabled }: Props) {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <MenuItemWithIcon
        onClick={onEdit}
        icon={isDisabled ? <VisibilityOutlinedIcon /> : <EditOutlinedIcon />}
        text={isDisabled ? 'View' : 'Edit'}
      />
      {!isDisabled && (
        <MenuItemWithIcon onClick={onDelete} icon={<DeleteIcon />} text="Delete" />
      )}
    </Menu>
  );
}
