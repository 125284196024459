import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  DeviceConfigOption,
  useAllConfigurationOptions,
  useParsedConfigurationOption,
} from 'client/app/state/ConfiguredDevicesProvider/configurationOptions';
import { ConfiguredDevice } from 'common/types/bundle';
import { isDeviceThatCanPerformLiquidHandling } from 'common/types/bundleConfigUtils';

type ConfiguredDevicesContextType = {
  allConfiguredDevices: ConfiguredDevice[];
  activeConfiguredDevices: ConfiguredDevice[];
  activeMainConfiguredDevice?: ConfiguredDevice;
  activeMainConfiguredDeviceConfig?: ParsedDeviceConfig;
  setActiveConfiguredDeviceIds: (configuredDeviceIds: string[]) => void;
  availableMainDeviceConfigOptions: DeviceConfigOption[];
  loadingConfigOptions: boolean;
  loadingErrorMessage?: string;
};

export const ConfiguredDevicesContext = createContext<
  ConfiguredDevicesContextType | undefined
>(undefined);

export const useConfiguredDevicesContext = () => {
  const context = useContext(ConfiguredDevicesContext);
  if (context === undefined) {
    throw new Error(
      'useConfiguredDevicesContext must be used within a ConfiguredDevicesProvider',
    );
  }
  return context;
};

type Props = PropsWithChildren<{
  devices: ConfiguredDevice[];
}>;

/**
 * ConfiguredDevicesProvider provides read only context about all devices and
 * their specific configurations. As such, it should used near the point of
 * fetching this data from any external state (e.g. a db).
 *
 * It intentionally does not enable updates of the configured devices, and
 * stores very minimal state. Its goal is to allow parents to tell children
 * which of these devices they should care about along with useful information
 * about them.
 */
export function ConfiguredDevicesProvider({ devices, children }: Props) {
  // these ids don't need to immediately match up with props devices. This is to
  // allow the ids to be updated before props devices, which may take longer
  // since they come from some other state.
  const [activeDeviceIds, setActiveDeviceIds] = useState<string[]>([]);

  const activeDevices = useMemo(
    () => devices.filter(({ id }) => activeDeviceIds.includes(id)) ?? [],
    [activeDeviceIds, devices],
  );

  // currently we support only one liquid handler being active at a time, so
  // find first is ok
  const activeMainConfiguredDevice = useMemo(
    () => activeDevices.find(isDeviceThatCanPerformLiquidHandling),
    [activeDevices],
  );

  const availableMainDeviceConfigOptions = useAllConfigurationOptions(
    activeMainConfiguredDevice?.deviceId,
  );

  // the error from useParsedConfigurationOption should be sufficient to cover
  // the errors from useAllConfigurationOptions too
  const { config, loading, error } = useParsedConfigurationOption(
    activeMainConfiguredDevice?.deviceId,
    activeMainConfiguredDevice?.runConfigId,
  );

  const state: ConfiguredDevicesContextType = {
    allConfiguredDevices: devices,
    activeConfiguredDevices: activeDevices,
    activeMainConfiguredDevice,
    activeMainConfiguredDeviceConfig: config,
    setActiveConfiguredDeviceIds: setActiveDeviceIds,
    availableMainDeviceConfigOptions,
    loadingConfigOptions: loading,
    loadingErrorMessage: error?.message,
  };

  return (
    <ConfiguredDevicesContext.Provider value={state}>
      {children}
    </ConfiguredDevicesContext.Provider>
  );
}
