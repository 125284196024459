import React from 'react';

import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import Colors from 'common/ui/Colors';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

export type Props = {
  variant: ButtonVariant;
  /**
   * component="span" or "label" is needed when using the Button to open
   * the file selection of an <input type="file" />
   */
  component?: React.ElementType;
} & Omit<MuiButtonProps, 'variant'>;

/**
 * A button component which is currently a wrapper around the MUI button to
 * make it clearer which variant should be used when. It accepts MUI props
 * other than "variant".
 * Documentation: https://material-ui.com/api/button/
 *
 * Pick a variant based on the emphasis the action should have...
 * "Primary" is for the most emphasised action, like "Schedule".
 * "Secondary" is for when there's multiple actions of similar emphasis.
 * "Tertiary" is for deemphasised actions and dialogs.
 *
 * This component has its ref forwarded to the child MUI button to ensure that Tooltips
 * work. See https://material-ui.com/guides/composition/#caveat-with-refs
 * */
export default React.forwardRef(function Button(
  { variant, color = 'inherit', ...buttonProps }: Props,
  ref: React.Ref<HTMLButtonElement>,
) {
  const sx =
    buttonProps.sx ?? color === 'inherit' ? { borderColor: Colors.GREY_30 } : undefined;

  switch (variant) {
    case 'primary':
      return (
        <MuiButton sx={sx} color={color} variant="contained" {...buttonProps} ref={ref} />
      );
    case 'secondary':
      return (
        <MuiButton sx={sx} color={color} variant="outlined" {...buttonProps} ref={ref} />
      );
    default:
      return (
        <MuiButton sx={sx} color={color} variant="text" {...buttonProps} ref={ref} />
      );
  }
});
