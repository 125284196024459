import React, { useContext, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import {
  MINIMUM_PLATE_HEIGHT,
  MINIMUM_PLATE_WIDTH,
} from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/dimensions';
import toWellLocationsOnDeckItem from 'client/app/components/Parameters/WellSelector/toWellLocationsOnDeckItem';
import WellSelector from 'client/app/components/WellSelector/WellSelector';
import {
  formatWellPosition,
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';
import { PlateContentsMatrix, WellContents } from 'common/types/mix';
import {
  describeExtraParameters,
  PlateSetDescription,
} from 'common/types/plateSetDescription';
import { PlateType } from 'common/types/plateType';
import { PopoverSection } from 'common/ui/components/Popover';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import { WellTooltipTitleProps } from 'common/ui/components/simulation-details/mix/WellTooltip';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';

const ITERATION_ORDER_NUM_LABEL = 'Iteration order #';

type Props = {
  plateDescription: PlateSetDescription;
  plateType: PlateType;
  selectedWells: string[];
  onChangeWells: (wells: string[]) => void;
  liquidColors: LiquidColors;
  getColor: (name: string) => string;
  isDisabled: boolean;
};

export function PlateWellSelector({
  plateDescription,
  plateType,
  selectedWells,
  onChangeWells,
  liquidColors,
  getColor,
  isDisabled,
}: Props) {
  const { screenId } = useContext(ScreenContext);
  const initialPlateState = useMemo(() => makeWellSelector(plateType), [plateType]);
  const plateState: PlateState = {
    ...initialPlateState,
    contents: createPlateContentsMatrix(plateDescription, getColor),
  };

  return (
    <Wrapper>
      <WellSelector
        wellSelectionProps={{
          selectedWells: toWellLocationsOnDeckItem(selectedWells, plateState),
          onSelectWells: newSelectedWells =>
            onChangeWells(newSelectedWells.map(formatWellPosition)),
        }}
        deckLayout={getLayoutForWellSelector(plateState)}
        plate={plateState}
        liquidColors={liquidColors}
        googleAnalyticsCategory={screenId as string}
        showContentLabels
        getContentLabel={getContentLabel(selectedWells)}
        TooltipTitle={createToolTip}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
}

function createPlateContentsMatrix(
  plateDescription: PlateSetDescription,
  getColor: (v: string) => string,
) {
  const contents: PlateContentsMatrix = {};
  Object.entries(plateDescription.regions).forEach(([name, region]) => {
    region.wells.forEach((well, index) => {
      const col = getColumnNumberFromWellPosition(well);
      const row = getRowNumberFromWellPosition(well);
      contents[col] = {
        ...contents[col],
        [row]: {
          kind: 'liquid_summary',
          id: name,
          name: name,
          color: getColor(name),
          tags: [
            { label: 'Location', value_string: well },
            { label: 'Name', value_string: name },
            { label: ITERATION_ORDER_NUM_LABEL, value_string: `${index + 1}` },
            { label: 'Iteration order', value_string: region.wellIteration },
            { label: 'Iteration pattern', value_string: region.wellPattern },
            ...Object.entries(describeExtraParameters(region.extraParameters)).map(
              ([label, value]) => ({ label, value_string: value }),
            ),
          ],
          total_volume: { value: 0, unit: '' },
        },
      };
    });
  });
  return contents;
}

function getContentLabel(selectedWells: string[]) {
  return (
    well: string,
    wellContents: WellContents | undefined,
    _location: { row: number; col: number },
  ): WellLabelContent => {
    const selectedIndex = selectedWells.indexOf(well) + 1;
    const previousIndexTag = wellContents?.tags?.find(
      ({ label }) => label === ITERATION_ORDER_NUM_LABEL,
    );
    const prevIndexString = previousIndexTag ? previousIndexTag.value_string! : '';
    return {
      type: 'ContentLabel',
      heading: selectedIndex ? `${selectedIndex}` : prevIndexString,
    };
  };
}

function createToolTip({ wellContents }: WellTooltipTitleProps) {
  if (!wellContents) {
    return <PopoverSection text="No liquid allocated" />;
  }
  return (
    <Stack gap={1}>
      {wellContents.tags?.map(({ label, value_string }) => (
        <PopoverSection
          key={`${label}-${value_string}`}
          header={label}
          text={value_string!}
        />
      ))}
    </Stack>
  );
}

const Wrapper = styled('div')({
  minWidth: `${MINIMUM_PLATE_WIDTH}px`,
  minHeight: `${MINIMUM_PLATE_HEIGHT}px`,
  height: '100%',
  overflow: 'auto',
});
