import React, { useMemo } from 'react';

import { styled, SxProps, Theme } from '@mui/material/styles';

import { Markdown, renderMarkdownUnsafe, stringToMarkdown } from 'common/lib/markdown';

type Props = {
  message: string;
  messageType: 'markdown' | 'text';
};

type StylingProps = {
  sx?: SxProps<Theme>;
};

export function MessagePreview({ message, messageType, sx }: Props & StylingProps) {
  return messageType === 'markdown' ? (
    <MarkdownPreview text={stringToMarkdown(message)} sx={sx} />
  ) : (
    <TextPreview text={message} sx={sx} />
  );
}

function MarkdownPreview(props: { text: Markdown } & StylingProps) {
  const { text, sx } = props;
  const html = useMemo(() => renderMarkdownUnsafe(text), [text]);
  return (
    <MessagePreviewContainer
      sx={sx}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

function TextPreview(props: { text: string } & StylingProps) {
  const { text, sx } = props;
  return (
    <MessagePreviewContainer sx={{ whiteSpace: 'pre-wrap', margin: '8px 0', ...sx }}>
      {text}
    </MessagePreviewContainer>
  );
}

export const sxSmallerHeadings: SxProps<Theme> = {
  '& h1': (theme: Theme) => ({
    margin: '18px 0',
    ...theme.typography.h4,
  }),
  '& h2': (theme: Theme) => ({
    margin: '16px 0',
    ...theme.typography.h5,
  }),
  '& h3': (theme: Theme) => ({
    margin: '14px 0',
    ...theme.typography.h6,
  }),
};

export const MessagePreviewContainer = styled('div')(() => ({
  fontSize: '14px',
  lineHeight: '1.4',
  '& h1': {
    fontSize: '32px',
    padding: '0px',
    border: 'none',
    fontWeight: 700,
    margin: '32px 0',
  },
  '& h2': {
    fontSize: '24px',
    padding: '0px 0',
    border: 'none',
    fontWeight: 700,
    margin: '24px 0',
  },
  '& h3': {
    fontSize: '18px',
    margin: '18px 0',
    padding: '0px 0',
    border: 'none',
  },
  '& p': {
    margin: '8px 0',
  },
  '& a': {
    color: '#0052d9',
  },
  '& a:hover': {
    textDecoration: 'none',
  },
  '& strong': {
    fontWeight: 700,
  },
  '& ol, & ul': {
    paddingLeft: '36px',
  },
  '& li': {
    marginBottom: '4px',
  },
  '& hr': {
    marginTop: '20px',
    marginBottom: '20px',
    border: '0',
    borderTop: '1px solid #eee',
  },
  '& pre': {
    display: 'block',
    backgroundColor: '#f5f5f5',
    padding: '20px',
    lineHeight: '28px',
    borderRadius: '0',
    overflowX: 'auto',
    wordBreak: 'break-word',
  },
  '& code': {
    backgroundColor: '#f5f5f5',
    borderRadius: '0',
    padding: '3px 0',
    margin: '0',
    overflowX: 'auto',
    wordBreak: 'normal',
  },
  '& code:after, & code:before': {
    letterSpacing: 0,
  },
  '& blockquote': {
    position: 'relative',
    margin: '16px 0',
    padding: '5px 8px 5px 30px',
    background: 'none repeat scroll 0 0 rgba(102, 128, 153, 0.05)',
    border: 'none',
    color: '#333',
    borderLeft: '10px solid #d6dbdf',
  },
  '& img, & video': {
    maxWidth: '100%',
  },
  '& table': {
    lineHeight: '1.7',
    maxWidth: '100%',
    overflow: 'auto',
    border: '1px solid #f6f6f6',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    boxSizing: 'border-box',
  },
  '& table td, & table th': {
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  '& table tr': {
    border: '1px solid #efefef',
  },
  '& table tr:nth-of-type(2n)': {
    backgroundColor: 'transparent',
  },
  '& table th': {
    textAlign: 'center',
    fontWeight: 700,
    border: '1px solid #efefef',
    padding: '10px 6px',
    backgroundColor: '#f5f7fa',
    wordBreak: 'break-word',
  },
  '& table td': {
    border: '1px solid #efefef',
    textAlign: 'left',
    padding: '10px 15px',
    wordBreak: 'break-word',
    minWidth: '60px',
  },
  '& summary': {
    cursor: 'pointer',
  },
}));
