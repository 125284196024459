import React, { useCallback, useMemo, useRef, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { LabwareChip } from 'client/app/components/Parameters/DeckOptions/LabwareSelector/LabwareChip';
import { LabwareListItem } from 'client/app/components/Parameters/DeckOptions/LabwareSelector/LabwareListItem';
import { NamedPlatePlaceholder } from 'client/app/components/Parameters/DeckOptions/LabwareSelector/NamedPlatePlaceholder';
import {
  formatLabwareTypeName,
  LabwareType,
  LabwareTypePositions,
  NamedPlate,
  toNamedPlate,
} from 'client/app/state/LabwarePreference';
import Colors from 'common/ui/Colors';

// When using the default auto transition, the value for the autocomplete appeared in the input
// of the popover on exit, and we don't want that. We set the styles here so it exits
// with no animation. For the other values, these were determined inspecting the opacity
// transition of the component in dev tools, and are approximations.
// See the MUI Grow component implementation for more details.
const popoverTransitionDurations = { appear: 232, enter: 232, exit: 0 };

type Props = {
  activeLabwareType?: LabwareType;
  allLabwareTypePositions: LabwareTypePositions;
  onSelect: (labwareType?: LabwareType) => void;
  onDelete: (name: NamedPlate) => void;
  onRename: (oldName: NamedPlate, newName: NamedPlate) => void;
  isDisabled: boolean;
};

type LabwareOption = {
  /** The LabwareType for the option. */
  value: LabwareType;
  /** Used to conditionally render the NamedPlatePlaceholder option. */
  isNewNamedPlate?: boolean;
};

export default function LabwareSelector({
  activeLabwareType,
  allLabwareTypePositions,
  onSelect,
  onDelete,
  onRename,
  isDisabled,
}: Props) {
  const [inputValue, setInputValue] = useState<string>();

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const handleClick = () => setAnchorEl(popoverRef.current);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setInputValue('');
  }, []);

  const unformattedOptions = Object.keys(allLabwareTypePositions) as LabwareType[];
  const options = useMemo<LabwareOption[]>(
    () =>
      unformattedOptions
        .sort((a, b) => formatLabwareTypeName(a).localeCompare(formatLabwareTypeName(b)))
        .filter(labwareType =>
          inputValue
            ? labwareType.toLowerCase().includes(inputValue.toLowerCase())
            : true,
        )
        .map(labwareType => ({
          value: labwareType,
          isNewNamedPlate: false,
        })),
    [inputValue, unformattedOptions],
  );

  const numSelectedPositions =
    (activeLabwareType && allLabwareTypePositions?.[activeLabwareType]?.length) ?? 0;

  const checkPlateNameUnique = useCallback(
    (name: string) => {
      const cleanedName = name.trim();
      return allLabwareTypePositions[toNamedPlate(cleanedName)] === undefined;
    },
    [allLabwareTypePositions],
  );

  const handleSelect = useCallback(
    (option: LabwareOption) => {
      if (option) {
        if (option.isNewNamedPlate) {
          onSelect(option.value);
        } else {
          onSelect(option.value === activeLabwareType ? undefined : option.value);
        }
      }
      handleClose();
    },
    [activeLabwareType, handleClose, onSelect],
  );

  const handleRename = useCallback(
    (oldName: NamedPlate, newName: NamedPlate) => {
      onRename(oldName, newName);
      onSelect(newName);
    },
    [onRename, onSelect],
  );

  const handleDelete = useCallback(
    (name: NamedPlate) => {
      onDelete(name);
      onSelect(undefined);
    },
    [onDelete, onSelect],
  );

  return (
    <>
      <div ref={popoverRef}>
        <LabwareChip
          labwareType={activeLabwareType}
          numSelected={numSelectedPositions}
          onClick={handleClick}
          isExpanded={open}
        />
      </div>
      <Popover
        slotProps={{
          paper: {
            sx: theme => ({
              borderRadius: theme.spacing(3),
              padding: theme.spacing(4, 3),
              width: POPOVER_WIDTH,
              '& > main': {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
              },
            }),
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
        transitionDuration={popoverTransitionDurations}
      >
        <header>
          <SearchOrCreateInput
            autoFocus
            placeholder="Search or create"
            onChange={event => setInputValue(event.target.value)}
          />
          {!inputValue ? (
            <HelperText color="textPrimary" variant="caption">
              [x] Deck positions with allocated labware
            </HelperText>
          ) : !isDisabled ? (
            <NamedPlatePlaceholder
              plateName={inputValue}
              onCreate={() =>
                handleSelect({ value: toNamedPlate(inputValue), isNewNamedPlate: true })
              }
              disabled={false}
            />
          ) : (
            options.length === 0 && (
              <Typography
                variant="body1"
                sx={{ color: Colors.TEXT_SECONDARY, padding: '14px 8px 2px' }}
              >
                No options
              </Typography>
            )
          )}
        </header>
        <main>
          {options.map(option => (
            <LabwareListItem
              key={option.value}
              labwareType={option.value}
              checkPlateNameUnique={checkPlateNameUnique}
              numPositions={allLabwareTypePositions[option.value]?.length ?? 0}
              onSelect={() => handleSelect({ value: option.value })}
              onDelete={handleDelete}
              onRename={handleRename}
              isDisabled={isDisabled}
              isActive={
                activeLabwareType !== undefined && activeLabwareType === option.value
              }
            />
          ))}
        </main>
      </Popover>
    </>
  );
}

const POPOVER_WIDTH = 278;

const HelperText = styled(Typography)(({ theme }) => ({
  display: 'block',
  height: '28px',
  marginTop: theme.spacing(2),
}));

const SearchOrCreateInput = styled(InputBase)(({ theme }) => ({
  height: '24px',
  width: '100%',
  padding: theme.spacing(0, 3),
  margin: theme.spacing(2, 0, 5),
  backgroundColor: Colors.GREY_10,
}));
