import { EditorType } from 'common/elementConfiguration/EditorType';

const STANDARD_STRING_EDITORS = [
  EditorType.STRING,
  EditorType.AUTOCOMPLETE,
  EditorType.DROPDOWN,
  EditorType.CONNECTION_ONLY,
  EditorType.MEASUREMENT,
];
const STRING_EDITORS_DEFAULT_AUTOCOMPLETE = [
  EditorType.AUTOCOMPLETE,
  EditorType.STRING,
  EditorType.DROPDOWN,
  EditorType.CONNECTION_ONLY,
  EditorType.MEASUREMENT,
];
const STANDARD_BOOLEAN_EDITORS = [
  EditorType.CHECKBOX,
  EditorType.TOGGLE,
  EditorType.CONNECTION_ONLY,
];
const STANDARD_MAP_EDITORS = [EditorType.MAP, EditorType.CONNECTION_ONLY]; // TODO: Add table
const STANDARD_FILE_EDITORS = [EditorType.FILE, EditorType.CONNECTION_ONLY]; // TODO: Add table
const STANDARD_INT_EDITORS = [EditorType.INT, EditorType.CONNECTION_ONLY];
const STANDARD_FLOAT_EDITORS = [EditorType.FLOAT, EditorType.CONNECTION_ONLY];
const STANDARD_CHERRY_PICK_EDITORS = [EditorType.CHERRY_PICK, EditorType.CONNECTION_ONLY];
const STANDARD_DNA_EDITORS = [EditorType.DNA, EditorType.CONNECTION_ONLY];
const STANDARD_DOE_MERGE_OPTION_EDITORS = [
  EditorType.DOE_MERGE_OPTION,
  EditorType.CONNECTION_ONLY,
];
// TODO This parameter can go once all workflows using it
// are migrated to the newer `STANDARD_SIMULATION_RESULTS_EDITORS` (T449)
const STANDARD_SIMULATION_EDITORS = [EditorType.SIMULATION, EditorType.CONNECTION_ONLY];
const STANDARD_SIMULATION_RESULTS_EDITORS = [
  EditorType.SIMULATION_RESULTS,
  EditorType.CONNECTION_ONLY,
];

const STANDARD_LIQUID_EDITORS = [EditorType.LIQUID, EditorType.CONNECTION_ONLY];
const STANDARD_PLATE_READER_ABSORBANCE_PROTOCOL_EDITORS = [
  EditorType.PLATE_READER_ABSORBANCE_PROTOCOL,
  EditorType.CONNECTION_ONLY,
];
const STANDARD_PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL_EDITORS = [
  EditorType.PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL,
  EditorType.CONNECTION_ONLY,
];
const STANDARD_PLATE_READER_FLUORESCENCE_PROTOCOL_EDITORS = [
  EditorType.PLATE_READER_FLUORESCENCE_PROTOCOL,
  EditorType.CONNECTION_ONLY,
];
const STANDARD_PLATE_READER_LUMINESCENCE_PROTOCOL_EDITORS = [
  EditorType.PLATE_READER_LUMINESCENCE_PROTOCOL,
  EditorType.CONNECTION_ONLY,
];
const STANDARD_PLATE_WASH_PROTOCOL_EDITORS = [EditorType.PLATE_WASHER_PROTOCOL];
const STANDARD_PLATE_READER_SHAKING_TYPE_EDITORS = [
  EditorType.PLATE_READER_SHAKING_TYPE,
  EditorType.CONNECTION_ONLY,
];
const STANDARD_PLATE_EDITORS = [
  EditorType.PLATE,
  EditorType.PLATE_TYPE,
  EditorType.EXISTING_PLATE,
  EditorType.CONNECTION_ONLY,
];
const STANDARD_PLATE_TYPE_EDITORS = [EditorType.PLATE_TYPE, EditorType.CONNECTION_ONLY];
const STANDARD_POLICY_EDITORS = [EditorType.POLICY, EditorType.CONNECTION_ONLY];
const STANDARD_TIP_TYPE_EDITORS = [EditorType.TIP_TYPE, EditorType.CONNECTION_ONLY];
const STANDARD_WELL_COORDS_EDITORS = [EditorType.WELL_COORDS, EditorType.CONNECTION_ONLY];
const STANDARD_EXISTING_PLATE_EDITORS = [
  EditorType.EXISTING_PLATE,
  EditorType.CONNECTION_ONLY,
];

// The list below was manually extracted from the Elements that existed in the
// elements-westeros repo. There is no automatic method yet for synchronising
// this list with the elements in various places. That's something to get
// to in the future.

// This is a map of antha types to an array of parameter editors.
// By default, the first is used, but the others are available as options to be
// used for that type if this is set in the element configuration tool.
export const ParameterRegistry: Record<string, EditorType[]> = {
  // Manual overrides
  // Here you'll find a mapping of any type (including array and map types) to a
  // list of specific editors we want to offer the user. This section should only
  // contain compound types (i.e. arrays of things, maps of things) where the base type
  // is not listed below. If the desired editor for a compound type is the standard
  // array and map editors, then just add the base key and value type (for a map) or
  // item type (for an array) to the alphabetised list below (no need to add a custom
  // override here).
  '[]string': [EditorType.STRING_ARRAY, EditorType.ARRAY],
  '[]*github.com/Synthace/antha/antha/anthalib/data.Table': [EditorType.SPREADSHEET],
  '[]github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName': [
    EditorType.STRING_ARRAY,
    EditorType.ARRAY,
  ],
  // Robocolumns Prototype Element
  'github.com/Synthace/antha/Elements/Prototypes/RoboColumns.PurificationStage':
    STANDARD_STRING_EDITORS,

  // Listed below should be the exhaustive set of base types from all of the
  // elements in the entire codebase. This should live somewhere in a server-side
  // configuration, but we don't have an internal tools stack yet to support that.
  '*github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/image.AnthaImg':
    STANDARD_STRING_EDITORS,
  '*github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/image.AnthaImg':
    STANDARD_STRING_EDITORS,
  '*github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/image.AnthaPalette':
    STANDARD_STRING_EDITORS,
  '*github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/image.AnthaPalette':
    STANDARD_STRING_EDITORS,
  '*github.com/antha-lang/antha/antha/anthalib/wtype.DNASequence': STANDARD_DNA_EDITORS,
  '*github.com/Synthace/antha/antha/anthalib/wtype.DNASequence': STANDARD_DNA_EDITORS,
  '*github.com/antha-lang/antha/antha/anthalib/wtype.LHComponent':
    STANDARD_LIQUID_EDITORS,
  '*github.com/Synthace/antha/antha/anthalib/wtype.LHComponent': STANDARD_LIQUID_EDITORS,
  '*github.com/antha-lang/antha/antha/anthalib/wtype.LHPlate':
    STANDARD_PLATE_TYPE_EDITORS,
  '*github.com/Synthace/antha/antha/anthalib/wtype.LHPlate': STANDARD_PLATE_TYPE_EDITORS,
  '*github.com/antha-lang/antha/antha/anthalib/wtype.Liquid': STANDARD_LIQUID_EDITORS,
  '*github.com/Synthace/antha/antha/anthalib/wtype.Liquid': STANDARD_LIQUID_EDITORS,
  '*github.com/antha-lang/antha/antha/anthalib/wtype.Plate': STANDARD_PLATE_EDITORS,
  '*github.com/Synthace/antha/antha/anthalib/wtype.Plate': STANDARD_PLATE_EDITORS,
  '*github.com/Synthace/antha/antha/anthalib/wtype.PlateType': [
    EditorType.CONNECTION_ONLY,
  ],
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateType': [
    EditorType.CONNECTION_ONLY,
  ],
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateTypeName':
    STANDARD_PLATE_TYPE_EDITORS,
  '*github.com/tealeg/xlsx.File': STANDARD_FILE_EDITORS,
  '*image.NRGBA': STANDARD_STRING_EDITORS,
  bool: STANDARD_BOOLEAN_EDITORS,
  '*float32': STANDARD_FLOAT_EDITORS,
  float32: STANDARD_FLOAT_EDITORS,
  '*float64': STANDARD_FLOAT_EDITORS,
  float64: STANDARD_FLOAT_EDITORS,
  'github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/doe.MergeOption':
    STANDARD_DOE_MERGE_OPTION_EDITORS,
  'github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/doe.MergeOption':
    STANDARD_DOE_MERGE_OPTION_EDITORS,
  'github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/doe.Run':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/doe.Run':
    STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/enzymes.AssemblyStandard':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/enzymes.AssemblyStandard':
    STANDARD_STRING_EDITORS,
  // TODO Get rid of the types using JobID in favour of SimulationResults (T449)
  'github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/jobfile.JobID':
    STANDARD_SIMULATION_EDITORS,
  'github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/jobfile.JobID':
    STANDARD_SIMULATION_EDITORS,
  'github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/jobfile.SimulationResults':
    STANDARD_SIMULATION_RESULTS_EDITORS,
  'github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/jobfile.SimulationResults':
    STANDARD_SIMULATION_RESULTS_EDITORS,
  'github.com/antha-lang/antha/antha/AnthaStandardLibrary/Packages/sequences/oligos.Primer':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/AnthaStandardLibrary/Packages/sequences/oligos.Primer':
    STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/api.DeviceMetadata':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/api.DeviceMetadata': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/execute.HandleOpt': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/execute.HandleOpt': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/execute.IncubateOpt':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/execute.IncubateOpt': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/jobfile.JobID': STANDARD_SIMULATION_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/jobfile.JobID': STANDARD_SIMULATION_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/jobfile.SimulationResults':
    STANDARD_SIMULATION_RESULTS_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/jobfile.SimulationResults':
    STANDARD_SIMULATION_RESULTS_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.Absorbance': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.Absorbance': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.AlignedBioSequence':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.AlignedBioSequence':
    STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.DNASequence': STANDARD_DNA_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.DNASequence': STANDARD_DNA_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.File': STANDARD_FILE_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.File': STANDARD_FILE_EDITORS,
  '*github.com/antha-lang/antha/antha/anthalib/wtype.File': STANDARD_FILE_EDITORS,
  '*github.com/Synthace/antha/antha/anthalib/wtype.File': STANDARD_FILE_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.FileSeries': [EditorType.FILE_SERIES],
  '*github.com/Synthace/antha/antha/anthalib/wtype.FileSeries': [EditorType.FILE_SERIES],
  'github.com/antha-lang/antha/antha/anthalib/wtype.LHPlate': STANDARD_PLATE_TYPE_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.LHPlate': STANDARD_PLATE_TYPE_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.LHTip': STANDARD_TIP_TYPE_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.LHTip': STANDARD_TIP_TYPE_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.LHTipbox': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.LHTipbox': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.LHWell': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.LHWell': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.Liquid': STANDARD_LIQUID_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.Liquid': STANDARD_LIQUID_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.LiquidType': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.LiquidType': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.Plate': STANDARD_PLATE_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.Plate': STANDARD_PLATE_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateReaderShakingType':
    STANDARD_PLATE_READER_SHAKING_TYPE_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.PolicyName': STANDARD_POLICY_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.PolicyName': STANDARD_POLICY_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.Warning': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.Warning': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Amount': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Amount': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Angle': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Angle': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.AngularVelocity':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.AngularVelocity':
    STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Area': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Area': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Capacitance': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Capacitance': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Concentration':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Concentration': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Density': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Density': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Energy': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Energy': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.FlowRate': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.FlowRate': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Force': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Force': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Length': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Length': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Mass': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Mass': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Moles': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Moles': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Pressure': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Pressure': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Rate': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Rate': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Resistance': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Resistance': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.SpecificHeatCapacity':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.SpecificHeatCapacity':
    STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.SubstanceQuantity':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.SubstanceQuantity':
    STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Temperature': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Temperature': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Time': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Time': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Velocity': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Velocity': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Voltage': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Voltage': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.Volume': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.Volume': STANDARD_STRING_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wunit.VolumeOrConcentration':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wunit.VolumeOrConcentration':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/Elements/Product/ELISA.SampleTypeName':
    STANDARD_STRING_EDITORS,
  'image/color.Color': STANDARD_STRING_EDITORS,
  'image/color.RGBA': STANDARD_STRING_EDITORS,
  '*int': STANDARD_INT_EDITORS,
  int: STANDARD_INT_EDITORS,
  '*int32': STANDARD_INT_EDITORS,
  int32: STANDARD_INT_EDITORS,
  '*int64': STANDARD_INT_EDITORS,
  int64: STANDARD_INT_EDITORS,
  '*Synthace/stdlib/schemas/cherrypick.Transfers': STANDARD_CHERRY_PICK_EDITORS,
  '*github.com/Synthace/antha/stdlib/schemas/cherrypick.Transfers':
    STANDARD_CHERRY_PICK_EDITORS,
  'Synthace/stdlib/parsednaseqs.DNASeqAndConc': STANDARD_STRING_EDITORS,
  'Synthace/stdlib/platepreferences.PlateName': STRING_EDITORS_DEFAULT_AUTOCOMPLETE,
  'github.com/Synthace/antha/stdlib/platepreferences.PlateName':
    STRING_EDITORS_DEFAULT_AUTOCOMPLETE,
  'Synthace/stdlib/platepreferences.WellLocations': STANDARD_WELL_COORDS_EDITORS,
  'github.com/Synthace/antha/stdlib/doems.MixRules': STANDARD_MAP_EDITORS,
  'github.com/Synthace/antha/stdlib/platepreferences.WellLocations':
    STANDARD_WELL_COORDS_EDITORS,
  // TODO - Update to `wtype.WellCoords` once elements get updated.
  // `wtype.WellCoords` is the newer type, which is better and has X/Y coords
  'Synthace/stdlib/platepreferences.PlateSpecificWellLocations': STANDARD_MAP_EDITORS,
  // TODO - Update to `wtype.WellCoords` once elements get updated.
  // `wtype.WellCoords` is the newer type, which is better and has X/Y coords
  'github.com/Synthace/antha/stdlib/platepreferences.PlateSpecificWellLocations':
    STANDARD_MAP_EDITORS,
  string: STANDARD_STRING_EDITORS,
  uint8: STANDARD_INT_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.PlateReaderAbsorbanceProtocol':
    STANDARD_PLATE_READER_ABSORBANCE_PROTOCOL_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.PlateReaderAbsorbanceSpectraProtocol':
    STANDARD_PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.PlateReaderFluorescenceProtocol':
    STANDARD_PLATE_READER_FLUORESCENCE_PROTOCOL_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.PlateReaderLuminescenceProtocol':
    STANDARD_PLATE_READER_LUMINESCENCE_PROTOCOL_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateReaderAbsorbanceProtocol':
    STANDARD_PLATE_READER_ABSORBANCE_PROTOCOL_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateReaderAbsorbanceSpectraProtocol':
    STANDARD_PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateReaderFluorescenceProtocol':
    STANDARD_PLATE_READER_FLUORESCENCE_PROTOCOL_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateReaderLuminescenceProtocol':
    STANDARD_PLATE_READER_LUMINESCENCE_PROTOCOL_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.PlateWashProtocolName':
    STANDARD_PLATE_WASH_PROTOCOL_EDITORS,
  'github.com/antha-lang/antha/antha/anthalib/wtype.PlateWashProtocolName':
    STANDARD_PLATE_WASH_PROTOCOL_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.PlateFromPreviousSimulation':
    STANDARD_EXISTING_PLATE_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidProperty':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/antha/anthalib/wtype.Liquids': [EditorType.ARRAY],
  'github.com/Synthace/antha/antha/anthalib/wtype.Plates': STANDARD_MAP_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.ComponentName':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.ComponentOrLiquidName':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.ConcentrationOrVolume':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidName':
    STRING_EDITORS_DEFAULT_AUTOCOMPLETE,
  'github.com/Synthace/antha/stdlib/schemas/aliases.FilterMatrixName':
    STRING_EDITORS_DEFAULT_AUTOCOMPLETE,
  'github.com/Synthace/antha/stdlib/schemas/aliases.ResinName':
    STRING_EDITORS_DEFAULT_AUTOCOMPLETE,
  'github.com/Synthace/antha/stdlib/schemas/aliases.RoboColumnName':
    STRING_EDITORS_DEFAULT_AUTOCOMPLETE,
  'github.com/Synthace/antha/Elements/Product/RoboColumns/Define_RoboColumn_Plate.RoboColumnLayout':
    [EditorType.ROBOCOLUMN_LAYOUT_MAP, EditorType.AUTOCOMPLETE],
  'github.com/Synthace/antha/Elements/Product/RoboColumns/Run_Chromatography_Stage.ChromatographyActionsLayout':
    [EditorType.CHROMATOGRAPHY_ACTIONS_MAP],
  'github.com/Synthace/antha/Elements/Product/RoboColumns/Run_Gradient_Chromatography_Stage.GradientChromatographyActionsLayout':
    [EditorType.GRADIENT_CHROMATOGRAPHY_ACTIONS_MAP],
  'github.com/Synthace/antha/stdlib/datatypes.AnyValue': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/Elements/Product/RoboColumns.PurificationStage':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/mastermix.MixByOption': STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.PlateSetPrefix':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.WellIteration':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.WellIterator':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidIdentifier':
    STANDARD_STRING_EDITORS,
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidGroupName':
    STRING_EDITORS_DEFAULT_AUTOCOMPLETE,
};
