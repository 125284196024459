import React from 'react';

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';

import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import TextField from 'common/ui/filaments/TextField';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

// Hard coded antha types that identify a liquid. Must do this because it's not
// possible to infer them from the PlateSetDescription object specified in go
const LIQUID_IDENTIFIER_TYPE =
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidIdentifier';
const ADDITIONAL_LIQUID_IDENTIFIER_TYPES = [
  'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidName',
  'github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName',
  'github.com/Synthace/antha/stdlib/tags.Tag',
];

type Props = {
  value?: string;
  onChange: (value?: string) => void;
  isDisabled: boolean;
};

export function LiquidRegionName({ value, onChange, isDisabled }: Props) {
  return (
    <Box>
      <FormLabel>Liquid identifier</FormLabel>
      <AutocompleteWithParameterValues
        valueLabel={value}
        placeholder="Liquid name or tag"
        anthaType={LIQUID_IDENTIFIER_TYPE}
        additionalSourceTypes={ADDITIONAL_LIQUID_IDENTIFIER_TYPES}
        additionalOptions={['default']}
        acceptCustomValues
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </Box>
  );
}

export function GenericRegionName({ value, onChange, isDisabled }: Props) {
  const handleChange = useTextFieldChange(onChange);
  return (
    <Box>
      <FormLabel>Region name</FormLabel>
      <StyledTextField
        placeholder="Region name"
        value={value}
        onChange={handleChange}
        disabled={isDisabled}
      />
    </Box>
  );
}

const StyledTextField = styled(TextField)({ width: '100%' });
