import { useCallback, useEffect, useMemo, useState } from 'react';

import entries from 'lodash/entries';
import keys from 'lodash/keys';
import uniqBy from 'lodash/uniqBy';
import { v4 as uuid } from 'uuid';

import { PlateTypeByTypeName, usePlatesByType } from 'client/app/api/PlateTypesApi';
import {
  useElementContext,
  useInputLiquids,
} from 'client/app/apps/workflow-builder/lib/useElementContext';
import { isLiquidType } from 'client/app/apps/workflow-builder/output-preview/outputPreviewUtils';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorProvider';
import splitFullPlateName from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { isDefined } from 'common/lib/data';
import { formatMeasurementObj } from 'common/lib/format';
import {
  ElementError,
  FilterMatrix,
  Liquid,
  LiquidPosition,
  Measurement,
  SubLiquid,
} from 'common/types/bundle';
import {
  FilterMatrix as MixFilterMatrix,
  Liquid as MixLiquid,
  Mixture,
  VolumeOrConcentration,
  WellContents,
  WellLocation,
} from 'common/types/mix';
import { Option } from 'common/types/Option';
import { LiquidAssignment, PlateLayer, WellSet } from 'common/types/plateAssignments';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import useDialog from 'common/ui/hooks/useDialog';

/**
 * Main Element parameter that controls plate based mixing
 */
export const PLATE_BASED_MIXING_PARAMETER = 'DefinitionMode';

/**
 * Maps from the `Liquid[]` data returned by the element context API into the `PlateState`
 * format expected by the family of plate viewer components.
 * Designed for use with Mixture WellContents type as will provide additional data to returned
 * objects specific to Mixtures.
 *
 * @param outputParameter The element output parameter to read liquids from.
 * @returns An array of `PlateState` objects describing the contents of each plate.
 */
export function useOutputMixturesByPlate(
  elementInstanceId: string,
  liquidColors: LiquidColors,
  liquidParameters: LiquidParameters,
): [loading: boolean, platesByName: Map<string, PlateState>, uniqueMixtures: Mixture[]] {
  const {
    loading: inputsLoading,
    inputLiquids: [inputLiquids],
  } = useInputLiquids(elementInstanceId, liquidParameters.inputLiquids);

  const { loading: outputsLoading, context } = useElementContext(elementInstanceId);
  const outputs = context?.outputs[liquidParameters.outputLiquids]?.value;
  const outputMixtures = outputs ? (outputs as Liquid[]) : undefined;

  const [plateTypes, loadingPlates] = usePlatesByType();

  return useMemo(() => {
    const platesMap = new Map<string, PlateState>();
    const uniqueMixtures = new Map<string, Mixture>();

    if (outputMixtures && Array.isArray(outputMixtures) && !loadingPlates) {
      const subLiquids = outputMixtures
        .flatMap(mixture => mixture.subLiquids)
        .filter(isDefined);
      const uniqueSubLiquids = uniqBy(subLiquids, 'id');
      const subLiquidNames = new SubLiquidNames(inputLiquids, uniqueSubLiquids);

      outputMixtures.forEach(mixture => {
        const subComponents = entries(mixture.subComponents);

        const wellContents: Mixture = {
          kind: 'mixture_summary',
          id: mixture.id,
          name: mixture.name,
          total_volume: mixture.volume ?? { value: 0, unit: '' },
          sub_liquids: mixture.subLiquids?.map(subLiquid => ({
            ...subLiquid,
            name: subLiquidNames.getName(subLiquid),
          })),
          solutes:
            subComponents.length > 0
              ? subComponents.map(([name, value]) => ({
                  name,
                  concentration: value,
                }))
              : undefined,
        };

        // We need to pre-calculate the colors here to ensure they are consistent
        // between the plate map and the mixture cards.
        wellContents.color = liquidColors.getColorForWell(wellContents);

        if (mixture.position) {
          assignToPlateState(mixture.position, platesMap, plateTypes, wellContents);
        }

        if (!uniqueMixtures.has(wellContents.id)) {
          uniqueMixtures.set(mixture.id, wellContents);
        }
      });
    }

    return [
      inputsLoading || outputsLoading || loadingPlates,
      platesMap,
      [...uniqueMixtures.values()],
    ];
  }, [
    inputLiquids,
    inputsLoading,
    liquidColors,
    loadingPlates,
    outputMixtures,
    outputsLoading,
    plateTypes,
  ]);
}

export function useElementParameterOutput<T>(
  elementInstanceId: string,
  parameterName: string,
): [boolean, T | undefined, ElementError[] | undefined] {
  const loading = useWorkflowBuilderSelector(state => state.isSaving);
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);

  const [output, errors] = useMemo(() => {
    const elementInstance = elementInstances.find(ei => ei.Id === elementInstanceId);
    const output = elementInstance?.Meta.outputs?.[parameterName];
    const errors = elementInstance?.Meta.errors?.filter(
      error => error.severity === 'error',
    );
    return [output, errors];
  }, [elementInstanceId, elementInstances, parameterName]);

  return [loading, output as T | undefined, errors];
}

/**
 * Maps from the `Liquid[]` data returned by the element context API into the `PlateState`
 * format expected by the family of plate viewer components.
 *
 * @param outputParameter The element output parameter to read liquids from.
 * @returns An array of `PlateState` objects describing the contents of each plate.
 */
export function useOutputLiquidsByPlate(
  elementInstanceId: string,
  liquidColors: LiquidColors,
  liquidParameterName: string,
): [
  loading: boolean,
  platesByName: Map<string, PlateState>,
  outputLiquids: Liquid[] | undefined,
] {
  const [outputsLoading, outputLiquids] = useElementParameterOutput<Liquid[]>(
    elementInstanceId,
    liquidParameterName,
  );

  const [plateTypes, loadingPlates] = usePlatesByType();

  return useMemo(() => {
    const platesMap = new Map<string, PlateState>();

    if (outputLiquids && !loadingPlates) {
      outputLiquids.forEach(liquid => {
        const subComponents = entries(liquid.subComponents);

        const wellContents: MixLiquid = {
          kind: 'liquid_summary',
          id: liquid.id,
          name: liquid.name,
          total_volume: liquid.volume ?? { value: 0, unit: '' },
          solutes:
            subComponents.length > 0
              ? subComponents.map(([name, value]) => ({
                  name,
                  concentration: value,
                }))
              : undefined,
        };

        // We need to pre-calculate the colors here to ensure they are consistent
        wellContents.color = liquidColors.getColorForWell(wellContents);
        if (liquid.position) {
          assignToPlateState(liquid.position, platesMap, plateTypes, wellContents);
        }
      });
    }

    return [outputsLoading || loadingPlates, platesMap, outputLiquids];
  }, [liquidColors, loadingPlates, outputLiquids, outputsLoading, plateTypes]);
}

/**
 * Maps from the `FilterMatrix[]` data returned by the element context API into the `PlateState`
 * format expected by the family of plate viewer components.
 *
 * @param outputParameter The element output parameter to read filter matrixes from.
 * @returns An array of `PlateState` objects describing the contents of each plate.
 */
export function useOutputFilterMatrixesByPlate(
  elementInstanceId: string,
  liquidColors: LiquidColors,
  filterMatrixParameterName: string,
): [
  loading: boolean,
  platesByName: Map<string, PlateState>,
  outputFilterMatrixes: FilterMatrix[] | undefined,
] {
  const [outputsLoading, outputFilterMatrixes] = useElementParameterOutput<
    FilterMatrix[]
  >(elementInstanceId, filterMatrixParameterName);

  const [plateTypes, loadingPlates] = usePlatesByType();

  return useMemo(() => {
    const platesMap = new Map<string, PlateState>();

    if (outputFilterMatrixes && Array.isArray(outputFilterMatrixes) && !loadingPlates) {
      outputFilterMatrixes.forEach(filterMatrix => {
        const wellContents: MixFilterMatrix = {
          kind: 'filter_matrix_summary',
          id: filterMatrix.name,
          name: filterMatrix.name,
          total_volume: filterMatrix.volume ?? { value: 0, unit: '' },
          tags: filterMatrix.metaData,
        };

        // We need to pre-calculate the colors here to ensure they are consistent
        wellContents.color = liquidColors.getColorForWell(wellContents);
        if (filterMatrix.position) {
          assignToPlateState(filterMatrix.position, platesMap, plateTypes, wellContents);
        }
      });
    }

    return [outputsLoading || loadingPlates, platesMap, outputFilterMatrixes];
  }, [liquidColors, loadingPlates, outputFilterMatrixes, outputsLoading, plateTypes]);
}

export function assignToPlateState(
  position: LiquidPosition,
  platesMap: Map<string, PlateState>,
  plateTypes: PlateTypeByTypeName,
  wellContents: WellContents,
) {
  const plate = ensurePlate(position, platesMap, plateTypes);

  const contents = plate.contents!;

  if (position.wellCoords) {
    let row = contents[position.wellCoords.x];

    if (!row) {
      row = {};
      contents[position.wellCoords.x] = row;
    }

    row[position.wellCoords.y] = wellContents;
  }
}

function ensurePlate(
  position: LiquidPosition,
  platesMap: Map<string, PlateState>,
  plateTypes: PlateTypeByTypeName,
): PlateState {
  let plate = platesMap.get(position.plateName);
  if (!plate) {
    const [plateTypeName] = splitFullPlateName(position.plateType);
    const plateType = plateTypes(plateTypeName);

    if (!plateType) {
      throw new Error(`Missing plate type information for type ${plateTypeName}`);
    }

    plate = { ...makeWellSelector(plateType), contents: {} };
    platesMap.set(position.plateName, plate);
  }
  return plate;
}

type LiquidNameOrGroupNameType = 'name' | 'group';

export type LiquidNameOrGroupName = {
  id: string;
  name: string;
  type: LiquidNameOrGroupNameType;
  target?: VolumeOrConcentration;
  contents?: LiquidNameOrGroupName[];
  replicates?: number;
};

/**
 * Gets the list of unique liquid names or group names that define the input liquids.
 *
 * @returns Array of LiquidNameOrGroupName
 */
export function useInputLiquidNamesAndGroups(
  inputLiquids: (Liquid | FilterMatrix)[],
): LiquidNameOrGroupName[] {
  return useMemo(() => {
    const groups = new Map<string, LiquidNameOrGroupName>();
    const liquids = new Map<string, LiquidNameOrGroupName>();

    const countLiquidReplicates = (liquid: Liquid) =>
      inputLiquids.filter(
        inputLiquid => isLiquidType(inputLiquid) && inputLiquid.id === liquid.id,
      ).length;

    const countFilterMatrixReplicates = (filterMatrix: FilterMatrix) =>
      inputLiquids.filter(
        inputFilterMatrix => inputFilterMatrix.name === filterMatrix.name,
      ).length;

    inputLiquids?.forEach(liquid => {
      const hasLiquidId = isLiquidType(liquid);
      const hasGroups = 'groups' in liquid && !!liquid.groups?.length;

      if (!hasLiquidId) {
        /**
         * If there is no LiquidId then the liquid is a part of
         * some filter matrix in either Robocolumns or Filter plates
         */
        liquids.set(liquid.name, {
          id: liquid.name,
          name: liquid.name,
          type: 'name',
          replicates: countFilterMatrixReplicates(liquid),
        });
      } else if (hasGroups) {
        liquid.groups?.forEach(groupName => {
          let group = groups.get(groupName);

          if (!group) {
            group = {
              id: groupName,
              name: groupName,
              type: 'group',
              contents: [],
            };

            groups.set(groupName, group);
          }

          const id = `${liquid.id}-${liquid.name}`;

          if (!group.contents?.some(l => l.id === id)) {
            const { subComponents, parentConcentrationAsSubComponent } =
              getSubcomponents(liquid);
            group.contents!.push({
              id,
              name: liquid.name,
              type: 'name',
              target: parentConcentrationAsSubComponent,
              contents: subComponents,
              replicates: countLiquidReplicates(liquid),
            });
          }
        });
      } else if (!liquids.has(liquid.id)) {
        const { subComponents, parentConcentrationAsSubComponent } =
          getSubcomponents(liquid);

        liquids.set(liquid.id, {
          id: `${liquid.id}-${liquid.name}`,
          name: liquid.name,
          type: 'name',
          target: parentConcentrationAsSubComponent,
          contents: subComponents,
          replicates: countLiquidReplicates(liquid),
        });
      }
    });

    const result = [...liquids.values()];

    // Sometimes liquids have a single tag so end up in a "group" of one.
    // In that case we want to include them as standalone liquids, but we also
    // have to make sure we don't add these standalone liquids if they're also
    // part of another group.

    const liquidsIdsInAddedGroups = new Set<string>();
    const liquidsToMaybeAddIndividually: LiquidNameOrGroupName[] = [];

    for (const group of groups.values()) {
      if (group.contents?.length === 1) {
        liquidsToMaybeAddIndividually.push(group.contents[0]);
      } else {
        result.push(group);
        group.contents?.forEach(liquid => {
          liquidsIdsInAddedGroups.add(liquid.id);
        });
      }
    }

    result.push(
      ...liquidsToMaybeAddIndividually.filter(
        liquid => !liquidsIdsInAddedGroups.has(liquid.id),
      ),
    );

    const naturalTextSort = new Intl.Collator(navigator?.language, { numeric: true });
    return result.sort((a, b) => naturalTextSort.compare(a.name, b.name));
  }, [inputLiquids]);
}

function getSubcomponents(liquid: Liquid): {
  subComponents: LiquidNameOrGroupName[] | undefined;
  parentConcentrationAsSubComponent: VolumeOrConcentration | undefined; // Type is VolumeOrConcentration for convenience in components where this is used, but it will always be a concentration
} {
  const subComponents = keys(liquid.subComponents);

  const parentLiquidIsOnlySubComponent =
    subComponents.length === 1 && subComponents[0] === liquid.name;

  const parentLiquidConcentration =
    parentLiquidIsOnlySubComponent && liquid.subComponents?.[liquid.name]
      ? liquid.subComponents[liquid.name]
      : undefined;

  return {
    subComponents: parentLiquidIsOnlySubComponent
      ? undefined
      : subComponents.map(name => ({
          id: `${liquid.id}-${liquid.name}-${name}`,
          name,
          type: 'name',
          target: liquid.subComponents?.[name]
            ? {
                concentration: {
                  value: liquid.subComponents?.[name].value,
                  unit: liquid.subComponents?.[name].unit,
                },
              }
            : undefined,
        })),
    parentConcentrationAsSubComponent: parentLiquidConcentration
      ? {
          concentration: {
            value: parentLiquidConcentration.value,
            unit: parentLiquidConcentration.unit,
          },
        }
      : undefined,
  };
}

type Row = {
  id: string; // id will be the 'mixtureName' and used for uniqueness when iterating.
  mixtureName: string;
  replicates: number;
  cells: Cell[];
};

type Cell = {
  id: string; // id will be the parent 'mixtureName' + 'column header' and used for uniqueness when iterating.
  content: string;
};

/**
 * Formats mixtures into a table format for display purposes, with information about
 * the volumes of each sub-liquid that make up each mixture, as well as the replicates.
 *
 * @returns An object with two fields: 'rows' to render out into a table; 'columnHeaders' for the table header.
 */
export function useMixturesTable(
  elementInstanceId: string,
  inputLiquids: Liquid[],
  liquidParameters: LiquidParameters,
): {
  rows: Row[] | undefined;
  columnHeaders: string[] | undefined;
} {
  const { context } = useElementContext(elementInstanceId);
  const outputs = context?.outputs[liquidParameters.outputLiquids]?.value;
  const outputMixtures = outputs ? (outputs as Liquid[]) : undefined;

  return useMemo(() => {
    if (!outputMixtures) {
      return { rows: undefined, columnHeaders: undefined };
    }

    // We don't show multiple rows for identical mixture compositions. The total number used
    // will be reflected in Replicates field
    const uniqueMixtures = uniqBy(outputMixtures, 'id');

    const replicates = new Map<string, number>();
    outputMixtures.forEach(mixture => {
      replicates.set(mixture.id, (replicates.get(mixture.id) ?? 0) + 1);
    });

    const rows: Row[] = [];

    const subLiquids = outputMixtures
      .flatMap(mixture => mixture.subLiquids)
      .filter(isDefined)
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
    // The uniqueSubLiquids are effectively going to be the column headers in our table.
    // We don't want to display duplicates, so we group them here, and use this as a reference
    // for ranging over our mixtures.
    const uniqueSubLiquids = uniqBy(subLiquids, 'id');
    uniqueMixtures.map(mixture => {
      const cells: Cell[] = [];
      const subLiquidIdsForMixture = new Set(
        mixture.subLiquids?.map(subLiquid => subLiquid.id),
      );

      uniqueSubLiquids.forEach(subLiquid => {
        // If this uniqueSubLiquid is not in the current mixture, create an
        // "empty" cell.
        if (!subLiquidIdsForMixture.has(subLiquid?.id)) {
          cells.push({
            id: `${mixture.id}-${subLiquid.id}`,
            content: '-',
          });
        } else {
          const mixtureSubLiquid = mixture.subLiquids?.find(
            mixtureSubLiquid => mixtureSubLiquid.id === subLiquid?.id,
          );
          if (mixtureSubLiquid) {
            cells.push({
              id: `${mixture.id}-${subLiquid.id}`,
              content: formatMeasurementObj(mixtureSubLiquid.volume).replace('ul', ''),
            });
          }
        }
      });
      rows.push({
        id: mixture.id,
        mixtureName: mixture.name,
        cells: cells,
        replicates: replicates.get(mixture.id) ?? 0,
      });
      return rows;
    });

    const subLiquidNames = new SubLiquidNames(inputLiquids, uniqueSubLiquids);

    const columnHeaders = uniqueSubLiquids.map(
      subLiquid => `${subLiquidNames.getName(subLiquid)} (ul)`,
    );

    return { rows, columnHeaders };
  }, [inputLiquids, outputMixtures]);
}

class SubLiquidNames {
  #liquidConcentrations: Map<string, Measurement | undefined>;
  #subLiquidNameToHashes: Map<string, string[]>;

  constructor(liquids: Liquid[] | undefined, uniqueSubLiquids: SubLiquid[]) {
    // Sometimes subLiquids with the same names can be used, but at different concentrations.
    // To distinguish those, if a subLiquids with the same name has been used more than once
    // at different concentrations, we will append the header for that subLiquids with the
    // relevant concentration.
    this.#liquidConcentrations = new Map<string, Measurement | undefined>(
      liquids?.map(liquid => [liquid.id, liquid.subComponents?.[liquid.name]]) ?? [],
    );

    this.#subLiquidNameToHashes = new Map<string, string[]>();

    uniqueSubLiquids.forEach(subLiquid => {
      this.#subLiquidNameToHashes.has(subLiquid.name)
        ? this.#subLiquidNameToHashes.get(subLiquid.name)?.push(subLiquid.id)
        : this.#subLiquidNameToHashes.set(subLiquid.name, [subLiquid.id]);
    });
  }

  getName(subLiquid: SubLiquid) {
    if (this.#subLiquidNameToHashes.get(subLiquid.name)?.length === 1) {
      return subLiquid.name;
    }

    const concentration = this.#liquidConcentrations.get(subLiquid.id);
    const concentrationText = concentration
      ? ` (at ${formatMeasurementObj(concentration)}) `
      : '';

    return `${subLiquid.name}${concentrationText}`;
  }
}

export function addWellsToWellSet(
  layer: PlateLayer,
  wellSetId: string,
  wellsToAdd: readonly WellLocation[],
) {
  for (const wellSet of layer.wellSets) {
    if (wellSet.id === wellSetId) {
      wellSet.wells = [
        ...new Map([
          ...wellSet.wells.map(well => [`${well.x}-${well.y}`, well] as const),
          ...wellsToAdd.map(
            well => [`${well.col}-${well.row}`, { x: well.col, y: well.row }] as const,
          ),
        ]).values(),
      ];
    } else {
      removeWellsFromWellSet(wellSet, wellsToAdd);
    }
  }
}

export function removeWellsFromWellSet(
  wellSet: WellSet,
  wellsToRemove: readonly WellLocation[],
) {
  const retainedWells = new Map(
    wellSet.wells.map(well => [`${well.x}-${well.y}`, well] as const),
  );

  wellsToRemove.forEach(well => {
    retainedWells.delete(`${well.col}-${well.row}`);
  });

  wellSet.wells = [...retainedWells.values()];
}

/**
 * Allows deletion of a layer or liquid (and associated well sets).
 *
 * @returns An object containing the confirmDeleteDialog (to be added to the JSX of the component
 * where the hook is called), and the handleDelete callback, to call when layer or liquid
 * is deleted.
 */
export function useDeleteLayerOrLiquid() {
  const [confirmDeleteDialog, openConfirmdeleteDialog] = useDialog(ConfirmationDialog);

  const { deleteLayer, deleteLiquidAssignment } = usePlateLayoutEditorContext();

  const handleDelete = useCallback(
    async (id: string, layerOrLiquid: 'layer' | 'liquid') => {
      const isConfirmed = await openConfirmdeleteDialog({
        action: 'delete',
        isActionDestructive: true,
        object: layerOrLiquid,
      });
      if (!isConfirmed) {
        return;
      }
      layerOrLiquid === 'layer' ? deleteLayer(id) : deleteLiquidAssignment(id);
    },
    [deleteLayer, deleteLiquidAssignment, openConfirmdeleteDialog],
  );

  return { handleDelete, confirmDeleteDialog };
}

/**
 * Formats the name of the layer into a string, using the index to create an ordering
 * within the name.
 *
 * @param layerId id of the layer
 * @param plateLayers all layers
 * @returns String formatted name
 */
export function formatLayerAutomaticName(layerId: string, plateLayers: PlateLayer[]) {
  const layerIndex = plateLayers.findIndex(layer => layer.id === layerId);
  return `Layer ${plateLayers.length - layerIndex}${
    plateLayers[layerIndex].name ? `: ${plateLayers[layerIndex].name}` : ''
  }`;
}

export type LiquidParameters = {
  inputLiquids: string;
  existingLiquids: string | undefined;
  outputLiquids: string;
};

const MIX_ONTO = 'Mix_Onto';
const MAKE_MIXTURES = 'Make_Mixtures';

const LIQUID_PARAMETERS: Record<string, LiquidParameters> = {
  [MIX_ONTO]: {
    inputLiquids: 'LiquidsToAdd',
    existingLiquids: 'LiquidsInPlace',
    outputLiquids: 'MixedLiquids',
  },
  [MAKE_MIXTURES]: {
    inputLiquids: 'Liquids',
    existingLiquids: undefined,
    outputLiquids: 'Mixtures',
  },
};

export function getLiquidParametersForElement(isMixOnto: boolean) {
  return LIQUID_PARAMETERS[isMixOnto ? MIX_ONTO : MAKE_MIXTURES];
}

type InputPlateOption = Option<string> & { plateType: string };

export function useInputPlates(
  elementInstanceId: string,
  platesInputParam: string,
): {
  loading: boolean;
  inputPlates: InputPlateOption[] | undefined;
} {
  const {
    loading,
    inputLiquids: [inputLiquids],
  } = useInputLiquids(elementInstanceId, platesInputParam);

  const [inputPlates, setInputPlates] = useState<InputPlateOption[]>();

  useEffect(() => {
    if (!loading) {
      const inputPlates = new Map<string, string>();

      for (const inputLiquid of inputLiquids) {
        if (inputLiquid.position) {
          inputPlates.set(inputLiquid.position.plateName, inputLiquid.position.plateType);
        }
      }

      setInputPlates(
        [...inputPlates.entries()].map(([plateName, plateType]) => ({
          label: plateName,
          value: plateName,
          plateType,
        })),
      );
    }
  }, [inputLiquids, loading]);

  return { loading, inputPlates };
}

export function getLiquidNameForColor(liquid: LiquidAssignment) {
  const name = liquid.liquidGroup ?? liquid.liquidName;

  if (liquid.existing) {
    const { volume, concentration } = liquid.existing;
    return `${name}|${volume ? `${volume.value}${volume.unit}` : ``}|${
      concentration ? `${concentration.value}${concentration.unit}` : ``
    }`;
  }
  const value = liquid.target;

  return `${name}|${
    'volume' in value
      ? `${value.volume.value}${value.volume.unit}`
      : `${value.concentration.value}${value.concentration.unit}`
  }`;
}

/**
 * Map from liquids received from an element input to a plate layer,
 * for display as the in-place or destination liquids for a Mix Onto.
 */
export function mapLiquidsToPlateLayer(
  liquids: Liquid[],
  name: string,
  plateName: string,
  layerId: string,
): PlateLayer {
  const liquidAssigments = new Map<string, LiquidAssignment>();
  const wellSets = new Map<string, WellSet>();

  for (const liquid of liquids) {
    if (!liquid.position || plateName !== liquid.position.plateName) {
      continue;
    }

    // Identical liquids with different volumes will have the same id, but
    // we want to display them separately in the destination liquids preview.
    const id = `${liquid.id}-${liquid.volume?.value}${liquid.volume?.unit}`;

    const assignment = liquidAssigments.get(id);
    const wellSetID = assignment?.wellSetID ?? uuid();

    if (!assignment) {
      liquidAssigments.set(id, {
        liquidName: liquid.name,
        target: {
          volume: { value: 0, unit: 'ul' },
        },
        existing: {
          volume: liquid.volume ?? { value: 0, unit: 'ul' },
          concentration: liquid.subComponents?.[liquid.name],
        },
        wellSetID,
      });

      wellSets.set(wellSetID, { id: wellSetID, wells: [] });
    }

    const wellSet = wellSets.get(wellSetID);

    if (wellSet && liquid.position.wellCoords) {
      wellSet.wells.push({
        x: liquid.position.wellCoords.x,
        y: liquid.position.wellCoords.y,
      });
    }
  }

  return {
    id: layerId,
    name,
    liquids: [...liquidAssigments.values()],
    wellSets: [...wellSets.values()],
    isReadOnly: true,
  };
}

// createPlateName keeps plate naming conventions in sync with elements. When
// mixing to a new plate a suffix is always added (i.e. Plate_1, Plate_2, etc).
//
// If it is mixing onto a plate, the first plate will not have a suffix, but
// subsequent plates will (i.e. Plate, Plate_2, etc)
export function createPlateName(prefix: string, suffix: number, isMixOnto?: boolean) {
  if (isMixOnto) {
    return suffix ? `${prefix}_${suffix + 1}` : prefix;
  }
  return `${prefix}_${suffix + 1}`;
}
