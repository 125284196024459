import React, { CSSProperties } from 'react';

import { DECK_OPTIONS_COPY_OVERRIDES } from 'client/app/components/Parameters/DeckOptions/deckOptionsCopyOverrides';
import { getElementDisplayDescription } from 'client/app/lib/workflow/elementConfigUtils';
import { ellipsize } from 'common/lib/format';
import { Element } from 'common/types/bundle';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import { ErrorContext, SimulationError } from 'common/types/simulation_types';
import Colors from 'common/ui/Colors';

/**
 * Given an element, returns the short description used for components such as the Element List
 * */
export function getElementShortDescription(
  element: Element | null,
  elementConfigs?: Record<string, ElementConfigurationSpec | null>,
): string {
  if (!element) {
    return '';
  }
  const fullDescription = getElementDisplayDescription(element, elementConfigs);
  // In case there is some unnecessary whitespace
  const desc = fullDescription.trim();
  const endOfParagraphRegex = /\.\s*\n/;
  const endOfParagraphIndex = desc.search(endOfParagraphRegex);
  if (endOfParagraphIndex > 0) {
    // Show first paragraph
    return desc.substring(0, endOfParagraphIndex + 1);
  } else {
    // Just one paragraph. A common case is a short, one-line description.
    // TODO: Handle truncating markdown in a more sophisticated way.
    const descWithFullStop = desc.endsWith('.') ? desc : desc + '.';
    return ellipsize(descWithFullStop, 300);
  }
}

export function getTagLabel(tag: string) {
  return tag.replace(/_/g, ' ');
}

/** ElementParameterInterpolationConfig is the minimal ElementConfigurationSpec
 * required to interpolate error messages */
export type ElementParameterInterpolationConfig = {
  [elementType: string]: {
    elementDisplayName: string;
    parameters: { [parameterName: string]: { displayName: string } };
  } | null;
};

/**
 * Replace template values from a simulation error's template string with
 * the appropriate replacement from the element configuration if possible.
 * These are in the format of `{{}}`
 *
 * If there are additional context for errors, replace those values too.
 * These are in the format of `{}`
 *
 * Optionally provide custom interpolationOverrides.
 * These are in the format of `{{}}`
 * */
export const interpolateSimulationErrorTemplate = (
  templateString: string,
  elementConfigs?: ElementParameterInterpolationConfig,
  context?: ErrorContext,
  interpolationOverrides?: Record<string, string>,
) => {
  let result = templateString;

  // Replace any {} templates with values from the error context. We do this
  // first as the values in the error context might be {{}} templates used
  // for element and parameter names.
  result = context ? replaceContextErrors(result, context) : result;

  // Replace each {{}} template of element and parameter names with the
  // relevant configured value if possible, or values from the interpolationOverrides,
  // otherwise replace with the original name.
  result = interpolateConfiguredNames(result, elementConfigs, interpolationOverrides);

  return result;
};

/**
 * Replace template values from a given template string with
 * the appropriate replacement from the element configuration if possible.
 *
 * Templates look something like this:
 * "Element {{AliquotLiquid}} has an input {{AliquotLiquid.VolumePerAliquot}}"
 *
 * If no elementConfigs are provided, we simply remove surrounding
 * brackets from the template values.
 *
 * If interpolationOverrides are specified, these will be replaced preferentially before
 * elementConfigs. These must be full words with no special characters: "{{NameToReplace}}".
 */
export const interpolateConfiguredNames = (
  templateString: string,
  elementConfigs?: ElementParameterInterpolationConfig,
  interpolationOverrides?: Record<string, string>,
) => {
  const templateItemRegex = /{{([\w|.]+)}}/g;
  let result = templateString;
  let match;
  while ((match = templateItemRegex.exec(templateString)) !== null) {
    const [valueWithTemplate, value] = match;
    // Parameters are in the format {{elementName.parameterName}}
    if (value.includes('.')) {
      const [element, parameter] = value.split('.');

      // Defend against element developer error, i.e. writing error messages that
      // use incorrect element-parameter pairings in a template.
      const replacementValue =
        elementConfigs?.[element]?.parameters?.[parameter]?.displayName ?? parameter;
      result = result.replace(valueWithTemplate, replacementValue);
    } else {
      // Elements and custom overrides are in the format {{elementName}}
      const replacementValue =
        interpolationOverrides?.[value] ??
        elementConfigs?.[value]?.elementDisplayName ??
        value;
      result = result.replace(valueWithTemplate, replacementValue);
    }
  }

  return result;
};

/**
 * Replace any placeholder values using the context from the error.
 */
const replaceContextErrors = (templateString: string, context: ErrorContext) => {
  let result = templateString;
  if (!context) {
    return result;
  }

  const templateItemRegex = /{([\w|.]+)}/g;
  let match;

  while ((match = templateItemRegex.exec(templateString)) !== null) {
    const [valueWithTemplate, value] = match;
    if (value in context) {
      result = result.replace(valueWithTemplate, context[value]);
    }
  }

  return result;
};

type FormattedSimulationError = Omit<SimulationError, 'message_template' | 'context'>;

/**
 * Format the SimulationError and interpolate the message and details of the error
 * using the elementConfigs if provided.
 */
export function formatSimulationError(
  error: SimulationError,
  elementConfigs?: ElementParameterInterpolationConfig,
): FormattedSimulationError {
  let details = '';
  if (error.details) {
    details = interpolateSimulationErrorTemplate(
      error.details,
      elementConfigs,
      error.context,
      DECK_OPTIONS_COPY_OVERRIDES,
    );
  }
  const message = interpolateSimulationErrorTemplate(
    error.message_template ?? error.message,
    elementConfigs,
    error.context,
    DECK_OPTIONS_COPY_OVERRIDES,
  );
  return { message, details, code: error.code, message_type: error.message_type };
}

/**
 * Format a string error for the UI for the SimulationErrorScreen.
 */
export function formatSimulationErrorAsString(
  error: SimulationError,
  elementConfigs?: Record<string, ElementConfigurationSpec | null>,
): string {
  const formattedError = formatSimulationError(error, elementConfigs);
  const details = formattedError.details ? `\n\nDetails:\n${formattedError.details}` : '';
  const errorCode = formattedError.code ? `\n\nError Code: ${formattedError.code}` : '';
  return `Error:\n${formattedError.message}` + details + errorCode;
}

/*
 * Given a string and regex query, return a JSX element that contains the string
 * with parts of the string that match the query highlighted
 */
export function highlightStringMatches(
  part: string,
  query: RegExp,
  matchStyle: CSSProperties = { background: Colors.TEXT_HIGHLIGHT },
) {
  let key = 0;
  let idx = 0;
  const parts = [];
  const isGlobal = query.flags.includes('g');
  // We don't care about the key here as long as it's unique
  // and span/strong don't get the same keys across multiple renders.
  while (idx < part.length) {
    query.lastIndex = idx;
    const match = query.exec(part);
    if (match) {
      if (match.index > idx) {
        parts.push(<span key={key}>{part.substr(idx, match.index - idx)}</span>);
      }
      parts.push(
        <span style={matchStyle} key={`${key}-match`}>
          {match[0]}
        </span>,
      );
      idx = match.index + match[0].length;
      key++;
    } else {
      parts.push(<span key={key}>{part.substr(idx)}</span>);
      key++;
      break;
    }
    if (!isGlobal) {
      // If regex doesn't include 'g' flag every .exec() call
      // will start from start, so we can just break here -
      // there will be at most one match
      break;
    }
  }
  query.lastIndex = 0;
  return parts;
}

export function renderParameterDescription(description: string) {
  return description.split(/\n\n+/).map((t, idx) => <p key={idx}>{t}</p>);
}
