import React, { useMemo } from 'react';

import PlateLibraryDialog from 'client/app/components/Parameters/PlateType/PlateLibraryDialog';
import { PlateSelection } from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateMode } from 'client/app/components/Parameters/PlateType/PlatesGrid';
import { getPlateInfo } from 'client/app/components/PlatePrep/helpers';
import { AppIcon } from 'client/app/icons';
import { PlateType } from 'common/types/plateType';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

type Props = {
  /** Human readable version of the plate, e.g. "1536 square well plate, clear" */
  displayValue: string;
  selectedPlateType?: string;
  plateTypeByTypeName: (typeName: string) => PlateType;
  onChange: (plate: PlateSelection) => void;
  isDisabled?: boolean;
  plateMode?: PlateMode;
};

export default function PlateTypeEditor(props: Props) {
  const {
    onChange,
    displayValue,
    selectedPlateType,
    plateTypeByTypeName,
    isDisabled,
    plateMode,
  } = props;
  const dialogProps = useMemo<
    Omit<React.ComponentProps<typeof PlateLibraryDialog>, 'isOpen' | 'onClose'>
  >(
    () => ({
      previousPlate: selectedPlateType,
      plates: plateTypeByTypeName,
      plateMode: plateMode,
    }),
    [plateMode, plateTypeByTypeName, selectedPlateType],
  );

  const plateInfo = useMemo(() => {
    return selectedPlateType
      ? getPlateInfo(plateTypeByTypeName(selectedPlateType))
      : null;
  }, [plateTypeByTypeName, selectedPlateType]);

  return (
    <SelectFromDialogButton
      value={selectedPlateType}
      selectedValueLabel={displayValue}
      icon={<AppIcon iconId="antha:plate" />}
      dialog={PlateLibraryDialog}
      dialogProps={dialogProps}
      placeholder="Select a plate type"
      onChange={onChange}
      isDisabled={isDisabled}
      tooltipContent={plateInfo}
    />
  );
}
