import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import WavesIcon from '@mui/icons-material/Waves';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorProvider';
import {
  getLiquidNameForColor,
  useDeleteLayerOrLiquid,
  useInputLiquidNamesAndGroups,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { formatMeasurementObj } from 'common/lib/format';
import { Measurement } from 'common/types/mix';
import { LiquidAssignment } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import { DragHandleProps } from 'common/ui/components/DragDrop/DraggableList';
import IconButton from 'common/ui/components/IconButton';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import LiquidsIcon from 'common/ui/icons/LiquidsIcon';

type LiquidAssignmentProps = {
  layerId: string;
  liquidAssignment: LiquidAssignment;
  /**
   * Show border if parent is focused.
   */
  parentFocused: boolean;
  isReadonly: boolean;
  dragProps: DragHandleProps;
};

export default function Liquid(props: LiquidAssignmentProps) {
  const { layerId, liquidAssignment, parentFocused, dragProps } = props;

  const {
    selectedLiquidOrLayerId,
    setSelectedLiquidOrLayerId,
    liquidColors,
    focusedLayerId,
    highlightedLiquidId,
    setHighlightedLiquidId,
    isReadonly: isGlobalReadonly,
    inputLiquids,
  } = usePlateLayoutEditorContext();

  const isReadonly = props.isReadonly || isGlobalReadonly;

  const { handleDelete, confirmDeleteDialog } = useDeleteLayerOrLiquid();

  const handleDeleteLiquid = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await handleDelete(liquidAssignment.wellSetID, 'liquid');
  };

  const layerIsSelected = layerId === focusedLayerId;

  const [isHovering, setIsHovering] = useState(false);

  const isHighlighted = isHovering || liquidAssignment.wellSetID === highlightedLiquidId;

  const isPartOfGroup = !!liquidAssignment.liquidGroup;

  const inputLiquidNamesAndGroups = useInputLiquidNamesAndGroups(inputLiquids);
  const numberOfLiquidsInGroup = isPartOfGroup
    ? inputLiquidNamesAndGroups.find(
        liquidOrGroup => liquidOrGroup.name === liquidAssignment.liquidGroup,
      )?.contents?.length
    : undefined;

  const volOrConc = getContents(liquidAssignment);

  const color = liquidColors.getColorFromLiquidString(
    getLiquidNameForColor(liquidAssignment),
  );

  const enableHoverFunctionality = isHovering && !isReadonly;

  return (
    <>
      <StyledListItem
        dense
        selected={selectedLiquidOrLayerId === liquidAssignment.wellSetID}
        parentFocused={parentFocused}
        onClick={() => {
          setSelectedLiquidOrLayerId(liquidAssignment.wellSetID);
        }}
        onMouseEnter={() => {
          setHighlightedLiquidId(liquidAssignment.wellSetID);
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setHighlightedLiquidId(id =>
            id === liquidAssignment.wellSetID ? undefined : id,
          );
          setIsHovering(false);
        }}
        isHighlighted={isHighlighted}
        isHovering={enableHoverFunctionality}
        layerIsSelected={layerIsSelected}
      >
        {!isReadonly && (
          <StyledDragIcon {...dragProps.attributes} {...dragProps.listeners}>
            {dragProps.dragIcon}
          </StyledDragIcon>
        )}
        {isPartOfGroup ? (
          <WavesIcon className="type-icon" />
        ) : (
          <LiquidsIcon className="type-icon" />
        )}
        <TypographyWithTooltip variant="body2">
          {isPartOfGroup ? liquidAssignment.liquidGroup : liquidAssignment.liquidName}
          {numberOfLiquidsInGroup && ` (${numberOfLiquidsInGroup})`}
        </TypographyWithTooltip>
        <StyledEndIconsWrapper isHovering={enableHoverFunctionality}>
          <StyledListItemText variant="body2" layerIsSelected={layerIsSelected}>
            {volOrConc}
          </StyledListItemText>
          {enableHoverFunctionality ? (
            <IconButton onClick={handleDeleteLiquid} icon={<CloseIcon />} size="xsmall" />
          ) : (
            <LiquidColor sx={{ backgroundColor: color }} />
          )}
        </StyledEndIconsWrapper>
      </StyledListItem>
      {confirmDeleteDialog}
    </>
  );
}

const StyledListItem = styled(ListItemButton, {
  shouldForwardProp: propName =>
    propName !== 'selected' &&
    propName !== 'parentFocused' &&
    propName !== 'isHovering' &&
    propName !== 'isHighlighted' &&
    propName !== 'layerIsSelected',
})<{
  selected: boolean;
  isHovering: boolean;
  isHighlighted: boolean;
  layerIsSelected: boolean;
  parentFocused: boolean;
}>(
  ({
    theme: { palette },
    selected,
    parentFocused,
    isHovering,
    isHighlighted,
    layerIsSelected,
  }) => ({
    pointerEvents: layerIsSelected ? 'all' : 'none',
    display: 'flex',
    padding: isHovering ? '5px 12px 5px 32px' : '8px 16px 8px 32px;',
    gap: '6px',
    boxShadow: parentFocused ? `inset 3px 0 0 0 ${palette.primary.main}` : 'none',
    backgroundColor: selected
      ? Colors.BLUE_5
      : isHighlighted
      ? Colors.GREY_20
      : 'default',
    '&:hover': {
      backgroundColor: selected ? Colors.BLUE_5 : 'default',
    },
    '& .type-icon': {
      fontSize: '16px',
      color: selected ? palette.info.main : Colors.GREY_40,
    },
    '& .MuiTypography-root': {
      color: layerIsSelected ? palette.text.primary : palette.text.secondary,
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const StyledEndIconsWrapper = styled('div', {
  shouldForwardProp: propName => propName !== 'isHovering',
})<{
  isHovering: boolean;
}>(({ theme: { palette }, isHovering }) => ({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  gap: isHovering ? '10px' : '12px',
  '& .MuiButtonBase-root': {
    '& .MuiSvgIcon-root': {
      color: palette.error.dark,
    },
  },
}));

const StyledListItemText = styled(TypographyWithTooltip, {
  shouldForwardProp: propName => propName !== 'layerIsSelected',
})<{ layerIsSelected: boolean }>(({ theme: { palette }, layerIsSelected }) => ({
  color: layerIsSelected ? palette.text.primary : palette.text.secondary,
  maxWidth: '100px',
  minWidth: '50px',
  marginLeft: 'auto',
  textAlign: 'right',
}));

const LiquidColor = styled('div')(({ theme: { palette } }) => ({
  minWidth: '18px',
  minHeight: '18px',
  borderRadius: '50%',
  border: `2px solid ${palette.primary.contrastText}`,
}));

const StyledDragIcon = styled('span')(({ theme }) => ({
  '& > svg': {
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
}));

function getContents(liquid: LiquidAssignment) {
  let measurement: Measurement | undefined;

  if (liquid.existing) {
    measurement = liquid.existing.volume ?? liquid.existing.concentration;
  } else {
    measurement =
      'volume' in liquid.target ? liquid.target.volume : liquid.target.concentration;
  }

  return measurement ? formatMeasurementObj(measurement) : `-`;
}
