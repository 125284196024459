import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';

import {
  QUERY_DEVICE_BY_ID_FOR_RUN_CONFIG,
  QUERY_DEVICE_CONFIG_RESPONSE,
} from 'client/app/api/gql/queries';
import { alphanumericCompare } from 'common/lib/strings';

export type DeviceConfigOption = {
  label: string;
  value: UUID;
  version: number;
  lastModifiedAt: Moment | null;
};

/** This hook executes a cheap query to fetch all possible run configuration options */
export function useAllConfigurationOptions(mainDeviceId?: string) {
  const { data: runConfigData } = useQuery(QUERY_DEVICE_BY_ID_FOR_RUN_CONFIG, {
    variables: { id: mainDeviceId as DeviceId },
    skip: mainDeviceId === undefined,
  });

  return useMemo<DeviceConfigOption[]>(() => {
    const allConfigs = runConfigData?.device.runConfigSummaries || [];
    return allConfigs
      .map(config => ({
        label: config.name.trim(),
        value: config.id,
        version: config.version,
        lastModifiedAt: config.lastModifiedAt ? moment(config.lastModifiedAt) : null,
      }))
      .sort((a, b) => alphanumericCompare(a.label, b.label));
  }, [runConfigData?.device.runConfigSummaries]);
}

/** This hook executes an expensive query that fetches a specific configuration option */
export function useParsedConfigurationOption(
  deviceId: string = '',
  runConfigId?: string,
) {
  const { data, loading, error } = useQuery(QUERY_DEVICE_CONFIG_RESPONSE, {
    variables: { id: deviceId, runConfigId },
    skip: deviceId === undefined || deviceId === '',
  });

  const { config } = data?.parsedRunConfig || {};
  return { config, loading, error };
}
