import React from 'react';

import { PlateIcon } from 'client/app/icons';
import { LayoutPreferences } from 'common/types/bundle';
import { OpaqueAlias } from 'common/types/OpaqueAlias';
import { InputPlateIcon } from 'common/ui/icons/InputPlateIcon';
import { NamedPlateIcon } from 'common/ui/icons/NamedPlateIcon';
import { OutputPlateIcon } from 'common/ui/icons/OutputPlateIcon';
import { TipBoxIcon } from 'common/ui/icons/TipBoxIcon';
import { TipWasteIcon } from 'common/ui/icons/TipWasteIcon';

export type NamedPlate = OpaqueAlias<string, 'labware:NamedPlate'>;

const SIMPLE_LABWARE_TYPE = [
  'outputPlates',
  'inputPlates',
  'tipBoxes',
  'tipWastes',
  'temporaryLocations',
] as const;
type SimpleLabwareType = (typeof SIMPLE_LABWARE_TYPE)[number];

export type LabwareType = SimpleLabwareType | NamedPlate;
export type LabwareTypePositions = { [key in LabwareType]: string[] };
type LabwareTypesByPosition = { [deckPositionName: string]: LabwarePreference[] };
export type LabwarePreference = { labwareType: LabwareType; position: number };

const NAMED_PLATE_PREFIX = 'named_plate:';

export function toNamedPlate(name: string) {
  return (NAMED_PLATE_PREFIX + name.trim()) as NamedPlate;
}

export function fromNamedPlate(namedPlate: NamedPlate) {
  return namedPlate.slice(NAMED_PLATE_PREFIX.length);
}

export function formatLabwareTypeName(labware: LabwareType | undefined) {
  if (!labware) {
    return '';
  }
  if (isNamedPlate(labware)) {
    return fromNamedPlate(labware);
  }
  switch (labware) {
    case 'inputPlates':
      return 'Input Plates';
    case 'outputPlates':
      return 'Output Plates';
    case 'temporaryLocations':
      return 'Temporary Locations';
    case 'tipBoxes':
      return 'Tip Boxes';
    case 'tipWastes':
      return 'Tip Wastes';
  }
}

export function isNamedPlate(
  labwareType: LabwareType | undefined,
): labwareType is NamedPlate {
  return labwareType?.indexOf(NAMED_PLATE_PREFIX) === 0;
}

const LABWARETYPE_TO_LAYOUTPREFERENCES_MAP: {
  [key in SimpleLabwareType]:
    | 'tipboxes'
    | 'inputs'
    | 'outputs'
    | 'tipwastes'
    | 'temporaryLocations';
} = {
  outputPlates: 'outputs',
  inputPlates: 'inputs',
  tipBoxes: 'tipboxes',
  tipWastes: 'tipwastes',
  temporaryLocations: 'temporaryLocations',
};

export function getLayoutPreference(labwareType: LabwareType) {
  const isPlateName = isNamedPlate(labwareType);
  const key = isPlateName
    ? fromNamedPlate(labwareType)
    : LABWARETYPE_TO_LAYOUTPREFERENCES_MAP[labwareType];
  return { key, isPlateName };
}

export function getLabwareTypePositions(
  layout?: Partial<LayoutPreferences>,
): LabwareTypePositions {
  const plateEntries = Object.fromEntries(
    Object.entries(layout?.plates ?? {}).map(([prefix, value]) => {
      return [toNamedPlate(prefix), value ?? []];
    }),
  );

  return {
    ...plateEntries,
    outputPlates: layout?.outputs ?? [],
    inputPlates: layout?.inputs ?? [],
    tipBoxes: layout?.tipboxes ?? [],
    tipWastes: layout?.tipwastes ?? [],
    temporaryLocations: layout?.temporaryLocations ?? [],
  };
}

export function getLabwareTypesByPosition(
  layout?: Partial<LayoutPreferences>,
): LabwareTypesByPosition {
  const deckPositionsByLabware = getLabwareTypePositions(layout);

  return Object.entries(deckPositionsByLabware).reduce<LabwareTypesByPosition>(
    (result, [labware, deckPositions]) => {
      [...deckPositions].forEach((deckPosition, index) => {
        const previousLabware = result?.[deckPosition] ?? [];
        const toAdd = { position: index, labwareType: labware as LabwareType };
        result[deckPosition] = [...previousLabware, toAdd];
      });
      return result;
    },
    {},
  );
}

export function areLabwareTypesEqual(
  labwareType1: LabwareType | undefined,
  labwareType2: LabwareType | undefined,
) {
  return labwareType1 === labwareType2;
}

export function getLabwareIcon(labware: LabwareType | undefined) {
  if (!labware) return;

  if (isNamedPlate(labware)) {
    return <NamedPlateIcon fontSize="small" />;
  }
  switch (labware) {
    case 'inputPlates':
      return <InputPlateIcon fontSize="small" />;
    case 'outputPlates':
      return <OutputPlateIcon fontSize="small" />;
    case 'temporaryLocations':
      return <PlateIcon fontSize="small" />;
    case 'tipBoxes':
      return <TipBoxIcon fontSize="small" />;
    case 'tipWastes':
      return <TipWasteIcon fontSize="small" />;
    default:
      return;
  }
}
