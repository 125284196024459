import { v4 as uuid } from 'uuid';

// These types must match the corresponding Go structs in the antha repo.

export type PlateSetDescription = {
  id: string;
  name: string;
  plateTypeName: string;
  regions: { [identifier: string]: PlateRegion };
};

export type PlateRegion = {
  /** wells in string A1 format */
  wells: string[];
  wellIteration: WellIterationOrder;
  wellPattern: WellIterationPattern;
  extraParameters?: ExtraPlateRegionParameters;
};

export const WELL_ITERATION_PATTERNS = [
  'As Selected',
  'Typewriter',
  'Snake',
  '96 well quadrant',
  '4 well grid',
] as const;

export type WellIterationPattern = (typeof WELL_ITERATION_PATTERNS)[number];

export const WELL_ITERATION_ORDERS = ['As Selected', 'By Row', 'By Column'] as const;

export type WellIterationOrder = (typeof WELL_ITERATION_ORDERS)[number];

export type ExtraPlateRegionParameters = ExtraContentsParameters | ExtraELISAParameters;

export type ExtraELISAParameters = {
  sampleType: ELISASampleType;
  coatingName: string;
  applyToAllPlates: boolean;
};

export const ELISA_SAMPLE_TYPES = [
  'Standard',
  'TestSample',
  'PositiveControl',
  'NegativeControl',
  'IsotypeControl',
  'Blank',
] as const;

export type ELISASampleType = (typeof ELISA_SAMPLE_TYPES)[number];

export type ExtraContentsParameters = {
  volume: string;
  numWellsToUse: number;
};

export function defaultPlateSetDescription(): PlateSetDescription {
  return {
    id: uuid(),
    name: '',
    plateTypeName: '',
    regions: {},
  };
}

export function defaultPlateRegion(): PlateRegion {
  return {
    wells: [],
    wellIteration: 'As Selected',
    wellPattern: 'As Selected',
  };
}

export function defaultExtraELISAParameters(): ExtraELISAParameters {
  return {
    sampleType: 'TestSample',
    coatingName: '',
    applyToAllPlates: false,
  };
}

export function defaultExtraContentsParameters(): ExtraContentsParameters {
  return {
    numWellsToUse: 1,
    volume: '50 ul',
  };
}

export function describeExtraParameters(params?: ExtraPlateRegionParameters): {
  [label: string]: string;
} {
  if (!params) return {};
  if ('numWellsToUse' in params) {
    return {
      'Number of wells to use in region': `${params.numWellsToUse}`,
      Volume: params.volume,
    };
  }
  if ('coatingName' in params) {
    return {
      'Sample type': `${params.sampleType}`,
      'Coating name': `${params.coatingName}`,
      'Apply to all plates': params.applyToAllPlates ? 'True' : 'False',
    };
  }
  return {};
}
