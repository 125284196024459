import React, { useCallback, useState } from 'react';

import PlateLibrary from 'client/app/apps/plate-library/PlateLibrary';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { PlateSelection } from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateMode } from 'client/app/components/Parameters/PlateType/PlatesGrid';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  previousPlate?: string;
  plateMode?: PlateMode;
} & DialogProps<PlateSelection>;

export default function PlateLibraryDialog(props: Props) {
  const classes = useStyles();
  const { onClose, previousPlate, isOpen, plateMode } = props;
  const [selectedPlate, setSelectedPlate] = useState(previousPlate);
  const handleConfirm = useCallback(() => {
    onClose(selectedPlate);
  }, [onClose, selectedPlate]);

  const handleClose = useCallback(() => {
    onClose(CANCEL_CHOICE);
  }, [onClose]);
  const onPlateSelect = useCallback(
    (plateId: string) => {
      // if we are selecting the same as already selected, unselect
      // if plate is different select that plate
      setSelectedPlate(sp => (sp === plateId ? undefined : plateId));
    },
    [setSelectedPlate],
  );

  return (
    <ComplexActionDialog
      title="Plate Types"
      onClose={handleClose}
      isOpen={isOpen}
      fullHeight
      showCloseButton
      paperClassName={classes.paper}
      content={
        <PlateLibrary
          dialog
          onSelect={onPlateSelect}
          onDoubleSelect={onClose}
          selectedPlate={selectedPlate}
          plateMode={plateMode}
        />
      }
      dialogActions={
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" variant="tertiary">
            {'Select' + (selectedPlate ? '' : ' none')}
          </Button>
        </DialogActions>
      }
    />
  );
}

const useStyles = makeStylesHook({
  paper: {
    backgroundColor: Colors.GREY_5,
  },
});
