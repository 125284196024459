import { useMemo } from 'react';

import { ApolloClient, ApolloError } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import {
  MUTATION_SIMULATE_WORKFLOW,
  MUTATION_UPDATE_SIMULATION,
} from 'client/app/api/gql/mutations';
import { QUERY_WORKFLOW_BY_ID_WITH_SIMULATIONS } from 'client/app/api/gql/queries';
import * as SimulationsApi from 'client/app/api/SimulationsApi';
import {
  SimulateWorkflowMutation,
  WorkflowsBySimulationsQueryVariables,
} from 'client/app/gql';
import { FeatureToggle } from 'common/features/featureToggles';
import * as FeatureToggles from 'common/features/featureTogglesForUI';
import { ServerSideBundle } from 'common/types/bundle';
import { OpaqueAlias } from 'common/types/OpaqueAlias';
import { parseAnthaCoreError } from 'common/types/simulation_types';
import { SimulationError } from 'common/types/simulation_types';
import wait from 'common/wait';

const MIN_SIMULATION_POLL_INTERVAL_MS = 500;
const MAX_SIMULATION_POLL_INTERVAL_MS = 10000;

type SimulationRequestId = OpaqueAlias<number, 'SimulationClientId'>;

export type SimulationProgress = {
  requestId: SimulationRequestId;
  /** Reported after simulation gets started */
  simulationId?: string;
  /**
   * If the simulation was just started and the appserver sent a URL to view
   * simulation logs (employee-only feature), this will include it for display.
   */
  logsUrl?: string | null;
  /**
   * If the simulation was just started and the appserver sent a trace URL
   * (employee-only feature), this will include it for display.
   */
  traceUrl?: string | null;

  // Keeps track of what the snapshotted id of the workflow when simulation was started.
  workflowId?: string;
};

type Simulation = SimulateWorkflowMutation['simulateWorkflow']['simulation'];

export type SimulationResult = {
  requestId: SimulationRequestId;
  simulation: Simulation | null;
};

export type SimulationErrorState = {
  requestId: SimulationRequestId;
  errors: readonly SimulationError[];
};

/**
 * We are using two different mutations for running the simulations, and they
 * share this common interface.
 */
type MutationResult = {
  simulation: Simulation | null;
};

type CreateSimulationResult = {
  requestId: SimulationRequestId;
  cancelRequest: () => void;
};

/**
 * GraphQL-based simulation client (simulate a workflow that's in Postgres),
 * used from the Workflow Builder.
 */
class SimulationClient {
  private apollo: ApolloClient<object>;

  constructor(apollo: ApolloClient<object>) {
    this.apollo = apollo;
    return this;
  }
  /**
   * Starts a simulation of a workflow stored in Postgres.
   *
   * You can track the progress using the callbacks.
   *
   * Returns a unique, client-side ID that can be used to correlate simulation events
   * before we get an actual ID of a `SimulationProgress` entity from the backend.
   */
  simulateWorkflow(
    workflowId: WorkflowId,
    /** For concurrent modification check */
    workflowVersion: number,
    onSuccess: (result: SimulationResult) => void,
    onProgress: (progress: SimulationProgress) => void,
    onError: (error: SimulationErrorState) => void,
    shouldApplyDesign?: boolean,
  ): CreateSimulationResult {
    const requestId = getSimulationRequestId();
    // Report that we are about to kick off a simulation
    onProgress({ requestId });

    const executeMutation = () =>
      this.apollo
        .mutate({
          mutation: MUTATION_SIMULATE_WORKFLOW,
          variables: {
            workflowId: workflowId,
            workflowVersion: workflowVersion,
            shouldApplyDesign: shouldApplyDesign,
          },
        })
        .then(result => result.data?.simulateWorkflow ?? null);

    return this.createSimulation(
      requestId,
      executeMutation,
      onSuccess,
      onProgress,
      onError,
    );
  }

  simulateTemplate(
    workflowId: WorkflowId,
    workflowVersion: number,
    workflow: ServerSideBundle,
    updateWorkflow: (
      id: WorkflowId,
      version: number,
      name: string,
      workflow: ServerSideBundle,
    ) => Promise<{
      version: number;
    }>,
    onSuccess: (result: SimulationResult) => void,
    onProgress: (progress: SimulationProgress) => void,
    onError: (error: SimulationErrorState) => void,
    shouldApplyDesign?: boolean,
  ): CreateSimulationResult {
    const featureToggles = FeatureToggles.getAll() ?? undefined;
    const requestId = getSimulationRequestId();
    // Report that we are about to kick off a simulation
    onProgress({ requestId });

    const shouldUpdate = (
      oldFeatureToggles: FeatureToggle[] | undefined,
      newFeatureToggles: FeatureToggle[] | undefined,
    ) => {
      if (newFeatureToggles === undefined) throw 'Undefined feture toggles';
      if (oldFeatureToggles === undefined) return true;
      if (oldFeatureToggles.length !== newFeatureToggles.length) return true;

      const sortByName = (arr: FeatureToggle[]) =>
        arr.slice().sort((a, b) => a.name.localeCompare(b.name));

      const oldSorted = sortByName(oldFeatureToggles);
      const newSorted = sortByName(newFeatureToggles);

      return !oldSorted.every(
        (oldToggle, index) =>
          oldToggle.name === newSorted[index].name &&
          oldToggle.enabled === newSorted[index].enabled,
      );
    };

    const executeMutation = async () => {
      const newBundle = { ...workflow, FeatureToggles: featureToggles };

      if (shouldUpdate(workflow.FeatureToggles, featureToggles)) {
        const { version: newEditVersion } = await updateWorkflow(
          workflowId,
          workflowVersion,
          newBundle.Meta.Name,
          newBundle,
        );
        workflowVersion = newEditVersion;
      }

      return this.apollo
        .mutate({
          mutation: MUTATION_SIMULATE_WORKFLOW,
          variables: {
            workflowId: workflowId,
            workflowVersion: workflowVersion,
            shouldApplyDesign: shouldApplyDesign,
          },
        })
        .then(result => result.data?.simulateWorkflow ?? null);
    };

    return this.createSimulation(
      requestId,
      executeMutation,
      onSuccess,
      onProgress,
      onError,
    );
  }

  private createSimulation<T extends MutationResult>(
    requestId: SimulationRequestId,
    executeMutation: () => Promise<T | null>,
    onSuccess: (result: SimulationResult) => void,
    onProgress: (progress: SimulationProgress) => void,
    onError: (error: SimulationErrorState) => void,
  ): CreateSimulationResult {
    let isCancelled = false;
    const cancelRequest = () => {
      isCancelled = true;
      onError({
        requestId,
        errors: [{ message: 'Cancelled', code: null, details: null, context: null }],
      });
    };

    (async () => {
      const result = await executeMutation();
      if (isCancelled) {
        return;
      }

      if (!result?.simulation) {
        throw new Error('Simulation failed.');
      }

      onProgress({
        requestId,
        simulationId: result.simulation?.id,
        logsUrl: result.simulation?.logsUrl,
        traceUrl: result.simulation?.traceUrl,
        workflowId: result.simulation?.workflow.id,
      });

      // note: await is not needed here, but added to reduce my OCD from seeing async method
      // called without await. We just need to kick off the polling process and we don't care
      // about the result
      await this.startPollingForSimulation(
        requestId,
        result,
        onSuccess,
        onError,
        () => isCancelled,
      );
    })().catch(error => {
      const errors =
        error instanceof ApolloError
          ? error.graphQLErrors.map(e => parseAnthaCoreError(e.message))
          : [parseAnthaCoreError(error.message)];

      onError({
        requestId,
        errors,
      });
    });

    return { requestId, cancelRequest };
  }

  /**
   * Starts checking the status of the simulation. Will keep pinging the API
   * until the simulation is done.
   */
  private async startPollingForSimulation(
    requestId: SimulationRequestId,
    result: MutationResult,
    onSuccess: (result: SimulationResult) => void,
    onError: (error: SimulationErrorState) => void,
    isCancelled: () => boolean,
  ): Promise<void> {
    let waitDuration = MIN_SIMULATION_POLL_INTERVAL_MS;
    for (;;) {
      await wait(waitDuration);

      if (isCancelled()) {
        return;
      }
      const simulation = await SimulationsApi.fetchSimulationForPoll(
        this.apollo,
        result.simulation!.id,
      );

      switch (simulation.status) {
        case 'FAILED': {
          onError({ requestId, errors: simulation.errors });
          return;
        }
        case 'COMPLETED': {
          onSuccess({
            ...result,
            requestId,
          });
          return;
        }
        default:
          // Exponential increase the waiting time for the next loops.
          // Only use a 1.5 factor in the increase to avoid growing too fast. We should reach the maximum wait value after ~25s.
          waitDuration = Math.min(MAX_SIMULATION_POLL_INTERVAL_MS, waitDuration * 1.5);
      }
    }
  }

  /**
   * Update a simulation with certain information such as renaming a simulation.
   */
  async updateSimulation(
    simulationID: string,
    name: string,
    associatedWorkflowId?: WorkflowId,
    additionalVariables?: WorkflowsBySimulationsQueryVariables,
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: MUTATION_UPDATE_SIMULATION,
      variables: { simulationID: simulationID as SimulationId, name },
      refetchQueries: associatedWorkflowId
        ? [
            {
              query: QUERY_WORKFLOW_BY_ID_WITH_SIMULATIONS,
              variables: {
                workflowId: associatedWorkflowId,
                ...additionalVariables,
              },
            },
          ]
        : undefined,
    });
  }
}

// Client-side simulation ID, used for referring to in-progress simulations,
// before the backend returns a real ID
let __NEXT_SIMULATION_REQUEST_ID = 1;

function getSimulationRequestId() {
  const id = __NEXT_SIMULATION_REQUEST_ID;
  __NEXT_SIMULATION_REQUEST_ID++;
  return id as SimulationRequestId;
}

export function useSimulationClient() {
  const apollo = useApolloClient();
  return useMemo(() => new SimulationClient(apollo), [apollo]);
}
