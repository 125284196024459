import React, { useCallback, useMemo, useState } from 'react';

import KebabIcon from '@mui/icons-material/MoreVertOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PlateContentsItemContainer from 'client/app/components/Parameters/PlateContents/PlateContentsItemContainer';
import { formatWellRange } from 'common/lib/format';
import { PlateRegion } from 'common/types/plateSetDescription';
import Button from 'common/ui/components/Button';
import { AddSelectedWellsIcon } from 'common/ui/icons/AddSelectedWellsIcon';
import { RemoveSelectedWellsIcon } from 'common/ui/icons/RemoveSelectedWellsIcon';

type Props = {
  color: string;
  name: string;
  region: PlateRegion;
  selectedWells: string[];
  onUpdateRegion: (
    name: string,
    region: PlateRegion,
    resolveWellConflicts?: boolean,
  ) => void;
  renderActionsMenu: (anchorEl: HTMLElement | null, onClose: () => void) => JSX.Element;
};

export function PlateRegionCard({
  color,
  name,
  region,
  selectedWells,
  onUpdateRegion,
  renderActionsMenu,
}: Props) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleCoseMenu = useCallback(() => setMenuAnchorEl(null), []);
  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const currentWellsString = useMemo(() => formatWellRange(region.wells), [region.wells]);
  const currentWellSet = useMemo(() => new Set(region.wells), [region.wells]);
  const anySelectedWells = selectedWells.length > 0;
  const areSelectedWellsOverlappingCurrent = selectedWells.some(selected =>
    currentWellSet.has(selected),
  );

  const handleAddWells = () => {
    const resolveWellConflicts = true;
    const toAdd = selectedWells.filter(selected => !currentWellSet.has(selected));
    const update = { ...region, wells: [...region.wells, ...toAdd] };
    onUpdateRegion(name, update, resolveWellConflicts);
  };
  const handleRemoveWells = () => {
    const selectedSet = new Set(selectedWells);
    const remainder = region.wells.filter(current => !selectedSet.has(current));
    onUpdateRegion(name, { ...region, wells: remainder });
  };

  const allocationCopy =
    region.wellPattern === region.wellIteration
      ? `'${region.wellIteration}'`
      : `'${region.wellPattern}' - '${region.wellIteration}'`;

  return (
    <PlateContentsItemContainer
      title={name}
      color={color}
      endIconButtonProps={{
        icon: <KebabIcon />,
        onClick: handleOpenMenu,
        title: 'More Actions',
      }}
    >
      {renderActionsMenu(menuAnchorEl, handleCoseMenu)}
      <Stack spacing={3}>
        <Typography color="textPrimary" variant="body2">
          {currentWellsString}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {`[Allocate wells by ${allocationCopy}]`}
        </Typography>
        {anySelectedWells || areSelectedWellsOverlappingCurrent ? (
          <Divider orientation="horizontal" flexItem />
        ) : null}
        <Stack alignItems="flex-start">
          {anySelectedWells && (
            <Button
              variant="tertiary"
              onClick={handleAddWells}
              startIcon={<AddSelectedWellsIcon />}
            >
              Add selected wells
            </Button>
          )}
          {areSelectedWellsOverlappingCurrent && (
            <Button
              variant="tertiary"
              onClick={handleRemoveWells}
              startIcon={<RemoveSelectedWellsIcon />}
              sx={{ spacing: 0 }}
            >
              Remove selected wells
            </Button>
          )}
        </Stack>
      </Stack>
    </PlateContentsItemContainer>
  );
}
