import React, { useCallback, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';

import DropdownEditor from 'client/app/components/Parameters/DropdownEditor';
import PlateContentsItemContainer from 'client/app/components/Parameters/PlateContents/PlateContentsItemContainer';
import { useGenerateWellsWithSnackbar } from 'client/app/components/Parameters/PlateDescriptionsEditor/lib/generateWellIterationUtils';
import { getOptions } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/utils';
import {
  ExtraPlateRegionParameters,
  PlateRegion,
  WELL_ITERATION_ORDERS,
  WELL_ITERATION_PATTERNS,
  WellIterationOrder,
  WellIterationPattern,
} from 'common/types/plateSetDescription';
import Button from 'common/ui/components/Button';

type Props = {
  color: string;
  name: string;
  region: PlateRegion;
  otherRegionNames: string[];
  onEdit: (wells: string[]) => void;
  onSave: (name: string, newRegion: PlateRegion) => void;
  onCancel: () => void;
  renderRegionName: (props: PlateRegionRenderProps<string>) => JSX.Element;
  renderExtraParameters?: (
    props: PlateRegionRenderProps<ExtraPlateRegionParameters>,
  ) => JSX.Element | null;
  isDisabled: boolean;
};

export type PlateRegionRenderProps<T extends ExtraPlateRegionParameters | string> = {
  value?: T;
  onChange: (params?: T) => void;
  isDisabled: boolean;
};

export function PlateRegionParameters({
  color,
  name: initialName,
  region: initialRegion,
  otherRegionNames,
  onEdit,
  onSave,
  onCancel,
  renderRegionName,
  renderExtraParameters,
  isDisabled,
}: Props) {
  const generateWells = useGenerateWellsWithSnackbar();
  const [region, setRegion] = useState(initialRegion);

  const [name, setName] = useState(initialName);
  const isDuplicateName = otherRegionNames.some(n => n === name);
  const handleNameChange = useCallback((value?: string | null) => {
    setName(value ?? '');
  }, []);

  const iterationOptions = useMemo(() => getOptions(WELL_ITERATION_ORDERS), []);
  const handleChangeIterationOrder = useCallback(
    (value?: string) => {
      if (value) {
        setRegion(prev => {
          const wellIteration = value as WellIterationOrder;
          const { generatedWells, isSuccess } = generateWells(
            prev.wells,
            prev.wellPattern,
            wellIteration,
          );
          if (isSuccess) {
            onEdit(generatedWells);
            return { ...prev, wellIteration, wells: generatedWells };
          }
          return prev;
        });
      }
    },
    [generateWells, onEdit],
  );

  const patternOptions = useMemo(() => getOptions(WELL_ITERATION_PATTERNS), []);
  const handleChangeIterationPattern = useCallback(
    (value?: string) => {
      if (value) {
        setRegion(prev => {
          const wellPattern = value as WellIterationPattern;
          const { generatedWells, isSuccess } = generateWells(
            prev.wells,
            wellPattern,
            prev.wellIteration,
          );
          if (isSuccess) {
            onEdit(generatedWells);
            return { ...prev, wellPattern, wells: generatedWells };
          }
          return prev;
        });
      }
    },
    [generateWells, onEdit],
  );

  const handleChangeExtraParams = useCallback((v?: ExtraPlateRegionParameters | null) => {
    v && setRegion(prev => ({ ...prev, extraParameters: v }));
  }, []);

  const canSave = name !== '' && !isDuplicateName;

  return (
    <PlateContentsItemContainer title={name} color={color}>
      <Stack spacing={5}>
        <Divider flexItem />
        {renderRegionName({ value: name, onChange: handleNameChange, isDisabled })}
        {isDuplicateName ? (
          <FormHelperText error>
            This is a duplicate name. Please enter another.
          </FormHelperText>
        ) : null}
        <Box>
          <FormLabel>Well iteration pattern</FormLabel>
          <DropdownEditor
            anthaType=""
            useDynamicOptions={false}
            value={region.wellPattern}
            options={patternOptions}
            onChange={handleChangeIterationPattern}
            isDisabled={isDisabled}
          />
        </Box>
        <Box>
          <FormLabel>Well iteration order</FormLabel>
          <DropdownEditor
            anthaType=""
            useDynamicOptions={false}
            value={region.wellIteration}
            options={iterationOptions}
            onChange={handleChangeIterationOrder}
            isDisabled={isDisabled}
          />
        </Box>
        {renderExtraParameters?.({
          value: region.extraParameters,
          onChange: handleChangeExtraParams,
          isDisabled,
        })}
        <Stack direction="row" justifyContent="space-between">
          <Button onClick={onCancel} variant="secondary">
            {isDisabled ? 'Close' : 'Cancel'}
          </Button>
          {!isDisabled && (
            <Button
              onClick={() => onSave(name, region)}
              variant="secondary"
              color="primary"
              disabled={!canSave}
            >
              Save
            </Button>
          )}
        </Stack>
      </Stack>
    </PlateContentsItemContainer>
  );
}
