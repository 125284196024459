import BuildinDescription from 'client/app/components/Parameters/Policy/components/buildinPolicyDescriptions.json';

type BuildinDescriptionType = Record<string, string>;

const descriptions = BuildinDescription as BuildinDescriptionType;

/**
 * Returns the built-in policy description if available, otherwise undefined.
 * @param policyName The name of the policy.
 * @returns The description string if found, otherwise undefined.
 */
export function getBuildinPolicyDescription(policyName: string): string | undefined {
  return descriptions[policyName];
}
