import React from 'react';

import Typography, { TypographyProps } from '@mui/material/Typography';

import Tooltip from 'common/ui/components/Tooltip';
import useOverflow from 'common/ui/hooks/useOverflow';

type Props = Omit<TypographyProps, 'noWrap' | 'ref' | 'onMouseOver'>;

/**
 * Wraps a Typography component in a Tooltip. The Tooltip title is shown on mouse over only if
 * the Typography text is ellipsized.
 */
export default React.memo(function TypographyWithTooltip(props: Props) {
  const { children } = props;
  const { isOverflowing, handleOverflow, ref } = useOverflow();

  const typography = (
    <Typography {...props} noWrap onMouseOver={handleOverflow} ref={ref} />
  );

  // Conditionally use the wrapping Tooltip when necessary as this improves performance of the component's initial mount
  return isOverflowing ? (
    <Tooltip title={<>{children}</>}>{typography}</Tooltip>
  ) : (
    typography
  );
});
