import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

type AdditionalPanelContextType = {
  setAdditionalPanel: (opts: { contents: React.ReactNode | null; id?: string }) => void;
  clearAdditionalPanel: () => void;
  additionalPanelContents: React.ReactNode | null;
  additionalPanelId?: string;
};

export const AdditionalPanelContext = createContext<
  AdditionalPanelContextType | undefined
>(undefined);

export const useAdditionalPanelContext = () => {
  const context = useContext(AdditionalPanelContext);
  if (context === undefined) {
    throw new Error('useAdditionalPanelContext must be used within a PanelProvider');
  }
  return context;
};

/**
 * Enables child components to pass parents a component to render. The component
 * is typically a complex panel that the child cannot render but the parent may
 * be able to in an appropriate place that is less disruptive than dialog.
 */
export function AdditionalPanelProvider({ children }: PropsWithChildren) {
  const [panelContents, setPanelContents] = useState<React.ReactNode>(null);
  const [panelId, setPanelId] = useState<string | undefined>(undefined);

  const setAdditionalPanel = useCallback(
    (opts: { contents: React.ReactNode | null; id?: string }) => {
      setPanelContents(opts.contents);
      setPanelId(opts.id);
    },
    [],
  );

  const clearAdditionalPanel = useCallback(() => {
    setPanelContents(null);
    setPanelId(undefined);
  }, []);

  const state = {
    setAdditionalPanel,
    clearAdditionalPanel,
    additionalPanelId: panelId,
    additionalPanelContents: panelContents,
  };

  return (
    <AdditionalPanelContext.Provider value={state}>
      {children}
    </AdditionalPanelContext.Provider>
  );
}
