import { isEnum } from 'common/lib/enum';
import { ContentSourceType } from 'common/types/contentSource';
import { LiquidContainerComponentGeometries } from 'common/types/liquidContainers';
import { Position2d, Position3d } from 'common/types/Position';

type EmptyStringable<T> = T | '';
type EmptyStringableObject<T> = {
  [P in keyof T]: T[P] extends string | number | boolean
    ? EmptyStringable<T[P]>
    : EmptyStringableObject<T[P]>;
};

// Same as PlateType but the value fields can be empty strings.
// This is to supports forms where the user must be able to erase content before refilling it.
// It is generally safe to cast FormPlateType as PlateType as the empty string converts nicely to boolean and number.
export type FormPlateType = EmptyStringableObject<PlateType>;

export type PlateType = {
  id: string;
  type: string;
  name: string;
  manufacturer: string;
  description: string;
  catalogNumber: string;
  accessory: string;
  format: string;
  usage: string;
  columns: number;
  rows: number;
  wellBottomOffset: number;
  wellOffset: Position2d;
  wellStart: Position3d;
  dimension: Position3d;
  color: string;
  catalogUrl: string;
  version: number;
  wellShape: WellShape; // assume that each well of the plate type have the same shape
  containerType: ContainerType;
  coverType: CoverType;

  // 2 specific fields for the residual volume:
  // defaultResidualVolume defines the volume that should be applied if there is no specific value
  // residualVolumes defines volumes per device model, therefore hold specific values
  defaultResidualVolume: number;
  residualVolumes?: { [model: string]: number };

  // editable will indicate if the plate can be deleted, but is currently always false until we have proper admin capabilities
  // it is left there to allow keeping the UI code that already shows the delete button
  editable: boolean;
  contentSource: string;
};

// Describes the shape of a well, with it's overall type (CYLINDER, BOX, etc), the bottom type (flat, U, V), the dimensions, a potential defined volume
// and the list of geometries that could be used to compute this volume dynamically
export type WellShape = {
  type: WellShapeEnum;
  bottomType: WellBottomShapeEnum;
  volumeOverrideUl: number; // TODO: make optional when we support geometries and volume computation from them
  dimensionMm: Position3d;
  liquidContainerComponentGeometries?: LiquidContainerComponentGeometries; // the list of geometric figures that could represent the well. For example a cylinder on top of a cone.
};

export enum WellShapeEnum {
  CYLINDER = 'CYLINDER',
  CIRCLE = 'CIRCLE',
  ROUND = 'ROUND',
  SPHERE = 'SPHERE',
  SQUARE = 'SQUARE',
  RECTANGLE = 'RECTANGLE',
  BOX = 'BOX',
  TRAPEZOID = 'TRAPEZOID',
}

export enum WellBottomShapeEnum {
  FLAT_BOTTOM = 'FLAT_BOTTOM',
  U_BOTTOM = 'U_BOTTOM',
  V_BOTTOM = 'V_BOTTOM',
}

export enum ContainerType {
  WELL = 'WELL',
  COLUMN = 'COLUMN',
}

export enum CoverType {
  NO_COVER = 'NO_COVER',
  CAP = 'CAP',
  LID = 'LID',
}

export function wellShapeFromString(shape: string): WellShapeEnum {
  shape = shape.toUpperCase();
  if (!isEnum(WellShapeEnum)(shape)) {
    throw new Error(`${shape} is not a known well shape`);
  }
  return WellShapeEnum[shape];
}

export function wellBottomShapeFromString(shape: string): WellBottomShapeEnum {
  shape = shape.toUpperCase();
  if (!isEnum(WellBottomShapeEnum)(shape)) {
    throw new Error(`${shape} is not a known well bottom shape`);
  }
  return WellBottomShapeEnum[shape];
}

export function containerTypeFromString(type: string): ContainerType {
  type = type.toUpperCase();
  if (!isEnum(ContainerType)(type)) {
    throw new Error(`${type} is not a known container type`);
  }
  return ContainerType[type];
}

export function contentSourceFromString(type: string): ContentSourceType {
  type = type.toUpperCase();
  if (!isEnum(ContentSourceType)(type)) {
    throw new Error(`${type} is not a known content source`);
  }
  return ContentSourceType[type];
}

export function coverTypeFromString(type: string): CoverType {
  type = type.toUpperCase();
  if (!isEnum(CoverType)(type)) {
    throw new Error(`${type} is not a known cover type`);
  }
  return CoverType[type];
}

// legacy enum for the well bottom shapes, kept so far as antha-core still relies on this for describing this shape
export enum LegacyWellBottomShape {
  FLAT_BOTTOM = 0,
  U_BOTTOM = 1,
  V_BOTTOM = 2,
}
