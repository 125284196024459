import React, { useCallback } from 'react';

import PlateLibraryDialog from 'client/app/components/Parameters/PlateType/PlateLibraryDialog';
import { PlateMode } from 'client/app/components/Parameters/PlateType/PlatesGrid';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { PlateIcon } from 'common/ui/icons/Plate';

export type PlateTypeSelectProps = {
  isDisabled?: boolean;
  plateMode?: PlateMode;
  onChange: (value?: PlateParameterValue) => void;
};

export const PlateTypeSelect = ({
  isDisabled,
  plateMode = 'both',
  onChange,
}: PlateTypeSelectProps) => {
  const classes = useStyles();

  const [dialog, openDialog] = useDialog(PlateLibraryDialog);

  const handleSelectNewPlate = useCallback(async () => {
    const plateType = await openDialog({ plateMode });
    if (typeof plateType === 'string') {
      onChange(plateType);
    }
  }, [onChange, openDialog, plateMode]);

  return (
    <>
      <Button
        variant="secondary"
        startIcon={<PlateIcon />}
        className={classes.selectPlateBtn}
        onClick={handleSelectNewPlate}
        disabled={isDisabled}
      >
        Plate type
      </Button>
      {dialog}
    </>
  );
};

const useStyles = makeStylesHook(() => ({
  selectPlateBtn: {
    justifyContent: 'left',
  },
}));
