import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DeckPositionsEditorPanel, {
  DECK_POSITIONS_PANEL_ID,
} from 'client/app/components/Parameters/DeckPositions/DeckPositionsEditorPanel';
import { getSelectPositionsButtonCopy } from 'client/app/components/Parameters/DeckPositions/lib/deckPositionsParameterUtils';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { DeckOptionsIcon } from 'common/ui/icons';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

type Props = {
  value: string[] | undefined;
  onChange: (newPositions: string[]) => void;
  isDisabled?: boolean;
};

export default function DeckPositionsParameter({
  value: positions = [],
  onChange,
  isDisabled,
}: Props) {
  const { setAdditionalPanel, clearAdditionalPanel, additionalPanelId } =
    useAdditionalPanelContext();

  const handleSavePositions = (newPositions: string[]) => {
    onChange(newPositions);
    clearAdditionalPanel();
  };

  const buttonCopy = getSelectPositionsButtonCopy(positions);
  const handleClick = () => {
    setAdditionalPanel({
      id: DECK_POSITIONS_PANEL_ID,
      contents: (
        <DeckPositionsEditorPanel
          positions={positions}
          onSave={handleSavePositions}
          isDisabled={isDisabled}
        />
      ),
    });
  };

  return (
    <DeckPositionsButton
      active={additionalPanelId === DECK_POSITIONS_PANEL_ID}
      variant="secondary"
      onClick={handleClick}
      startIcon={<DeckOptionsIcon />}
    >
      <Typography variant="body2">{buttonCopy.toUpperCase()}</Typography>
    </DeckPositionsButton>
  );
}

const DeckPositionsButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>(({ active, theme }) => ({
  width: '100%',
  justifyContent: 'center',
  ...(active
    ? {
        background: Colors.BLUE_5,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      }
    : {}),
}));
