import React, { useRef, useState } from 'react';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { PanelWithoutScroll } from 'client/app/apps/workflow-builder/panels/Panel';
import { DeckLayoutEmpty } from 'client/app/components/Parameters/DeckOptions/DeckLayoutEmpty';
import DeckLayoutInitialPopper from 'client/app/components/Parameters/DeckOptions/DeckLayoutInitialPopper';
import { DeckLayoutSelector } from 'client/app/components/Parameters/DeckOptions/DeckLayoutSelector';
import DeckLayoutWithPreferences from 'client/app/components/Parameters/DeckOptions/DeckLayoutWithPreferences';
import { LabwareSelector } from 'client/app/components/Parameters/DeckOptions/LabwareSelector';
import { LabwareSelectorHelp } from 'client/app/components/Parameters/DeckOptions/LabwareSelector/LabwareSelectorHelp';
import { useDeckOptions } from 'client/app/components/Parameters/DeckOptions/useDeckOptions';
import {
  formatLabwareTypeName,
  getLabwareTypePositions,
  LabwareType,
} from 'client/app/state/LabwarePreference';
import { ConfiguredDevice } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';

export const DECK_OPTIONS_PANEL_ID = 'DeckOptions';

type Props = {
  // value is fetched from active devices in ConfiguredDeviceContext; this
  // enables the panel to fetch extra useful data while it loads
  onChange: (configuredDevice: ConfiguredDevice) => void;
  onClose: () => void;
  isDisabled: boolean;
};

/**
 * DeckOptionsPanel allows the user to be able to select the proper deck layout
 * and set the preferences.
 */
export default React.memo(function DeckOptionsPanel(props: Props) {
  const { onChange, onClose, isDisabled } = props;
  const {
    loading,
    loadingErrorMessage,
    activeMainConfiguredDevice,
    activeMainConfiguredDeviceConfig,
    availableMainDeviceConfigOptions,
    confirmChangeDialog,
    selectConfig,
    resetConfigWithDefaults,
    updateLabwareTypePreferences,
    renameNamedPlate,
    deleteNamedPlate,
  } = useDeckOptions({ onChange });

  const [labwareType, setLabwareType] = useState<LabwareType | undefined>();
  const activeMainDeviceConfigOption = availableMainDeviceConfigOptions.find(
    v => v.value === activeMainConfiguredDevice?.runConfigId,
  );
  const allLabwareTypePositions = getLabwareTypePositions(
    activeMainConfiguredDevice?.layoutPreferences,
  );
  const selectedLabwareTypePositions =
    (labwareType && allLabwareTypePositions[labwareType]) ?? [];

  // we want to suppress one error in particular - `SIM10104: XXX is invalid
  // because it is empty.` - until the user has selected a config. It's a bit
  // awkward to figure this out since the some devices (Gilson) doesn't have a
  // runConfigId but does have a config. We don't rely on the error code from
  // the backend since there's no precedence for this...
  const isConfigSelected =
    activeMainConfiguredDevice?.runConfigId ||
    (activeMainConfiguredDevice?.type === 'GilsonPipetMax' &&
      activeMainConfiguredDeviceConfig);
  const configErrorMessage = isConfigSelected ? loadingErrorMessage : undefined;

  const isResetButtonEnabled =
    activeMainConfiguredDeviceConfig?.defaultLayoutOptions !== undefined;
  const showLabwareSelector = !loading && activeMainConfiguredDeviceConfig;
  const showClearAllPreferencesButton =
    !isDisabled && !loading && selectedLabwareTypePositions.length > 0;
  const showDeckLayoutInitialPopper = !isDisabled && !loading && !isConfigSelected;

  // Allow to open the helper text when a deck layout hasn't been selected.
  const dropdownRef = useRef(null);

  return (
    <PanelWithoutScroll
      title="Deck options"
      onClose={onClose}
      onCloseVariant="done"
      panelContent="DeckOptions"
      fullWidth
    >
      <>
        <TopBar>
          <Stack>
            <Typography variant="subtitle2">Deck layout</Typography>
            <OptionContainer ref={dropdownRef}>
              <DeckLayoutSelector
                selectedOption={activeMainDeviceConfigOption}
                availableOptions={availableMainDeviceConfigOptions}
                onChange={selectConfig}
                isDisabled={isDisabled}
                loading={loading}
              />
              <DeckLayoutInitialPopper
                anchorEl={dropdownRef.current}
                open={showDeckLayoutInitialPopper}
              />
              {!isDisabled && (
                <Tooltip title="Reset defaults">
                  <span>
                    <IconButton
                      sx={{ color: Colors.TEXT_PRIMARY }}
                      size="small"
                      icon={<AutorenewIcon />}
                      onClick={resetConfigWithDefaults}
                      disabled={!isResetButtonEnabled}
                    />
                  </span>
                </Tooltip>
              )}
            </OptionContainer>
          </Stack>
          {showLabwareSelector && (
            <Stack>
              <Typography variant="subtitle2">Labware to allocate</Typography>
              <OptionContainer>
                <LabwareSelector
                  activeLabwareType={labwareType}
                  allLabwareTypePositions={allLabwareTypePositions}
                  isDisabled={isDisabled}
                  onDelete={deleteNamedPlate}
                  onRename={renameNamedPlate}
                  onSelect={setLabwareType}
                />
                <StyledDivider orientation="vertical" />
                <LabwareSelectorHelp />
                {showClearAllPreferencesButton && (
                  <>
                    <StyledDivider orientation="vertical" />
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="tertiary"
                      onClick={() => updateLabwareTypePreferences([], labwareType)}
                      disabled={selectedLabwareTypePositions.length === 0}
                    >
                      {`Clear all ${formatLabwareTypeName(labwareType)}`}
                    </Button>
                  </>
                )}
              </OptionContainer>
            </Stack>
          )}
        </TopBar>
        {!loading && configErrorMessage && (
          <Alert severity="error">{configErrorMessage}</Alert>
        )}
        <MainContainer>
          {loading && <LinearProgress />}
          {activeMainConfiguredDeviceConfig ? (
            <DeckLayoutWithPreferences
              activeLabwareType={labwareType}
              deckConfig={activeMainConfiguredDeviceConfig}
              currentPreferences={activeMainConfiguredDevice?.layoutPreferences}
              isDisabled={isDisabled}
              onAddPosition={newPosition =>
                updateLabwareTypePreferences(
                  [...selectedLabwareTypePositions, newPosition],
                  labwareType,
                )
              }
              onRemovePosition={oldPosition =>
                updateLabwareTypePreferences(
                  selectedLabwareTypePositions.filter(p => p !== oldPosition),
                  labwareType,
                )
              }
            />
          ) : (
            <DeckLayoutEmpty />
          )}
        </MainContainer>
        {confirmChangeDialog}
      </>
    </PanelWithoutScroll>
  );
});

const TopBar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(5),
  gap: theme.spacing(3),
}));

const OptionContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: Colors.GREY_10,
  borderRadius: '4px',
  display: 'flex',
  height: '48px',
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  gap: theme.spacing(0, 2),
  '& hr:first-of-type': {
    marginLeft: theme.spacing(4),
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '16px',
  margin: theme.spacing(0, 2),
}));

const MainContainer = styled('div')({
  border: `1px solid ${Colors.BLUE_5}`,
  height: '550px',
  flex: '1 1 auto',
  minHeight: 0,
});
