import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import doNothing from 'common/lib/doNothing';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';

export function LabwareSelectorHelp() {
  return (
    <IconButtonWithPopper
      content={
        <>
          <Typography variant="caption">
            <BoldTypography variant="caption">Input Plates:&nbsp;</BoldTypography>
            Plates that contain the liquids you have at the start of your workflow.
          </Typography>
          <br />
          <Typography variant="caption">
            <BoldTypography variant="caption">Output Plates:&nbsp;</BoldTypography>
            Plates that contain the output of some liquid handling.
          </Typography>
          <br />
          <Typography variant="caption">
            <BoldTypography variant="caption">Temporary Locations:&nbsp;</BoldTypography>
            Deck positions to which your robot arm will move the plates in your hotels
            when they are needed.
          </Typography>
          <br />
          <Typography variant="caption">
            <BoldTypography variant="caption">Tip Boxes:&nbsp;</BoldTypography>
            Boxes that contain disposable tips.
          </Typography>
          <br />
          <Typography variant="caption">
            <BoldTypography variant="caption">Tip Wastes:&nbsp;</BoldTypography>
            Chutes in which your liquid handler will dispose of used tips.
          </Typography>
        </>
      }
      iconButtonProps={{ size: 'small', icon: <HelpOutlineIcon /> }}
      onClick={doNothing}
    />
  );
}

const BoldTypography = styled(Typography)({ fontWeight: 'bold' });
