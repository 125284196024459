/**
 * These types are shared between:
 * - Appserver, which parses xlsx/csv files into JSON
 * - Client & Admin, which render the table
 */

/**
 * A Spreadsheet is a csv/xlsx file which has one or
 * more tabs. If only one tab is present, its name
 * will default to "Sheet1"
 */
export type Spreadsheet = {
  sheets: Sheet[];
};

export type Sheet = {
  name: string;
  table: DataTable;
};

/**
 * This follows the same table schema as Elements code.
 */
export type DataTable = {
  schema: {
    /** Collection of headers/columns and their respective type */
    fields: TableColumn[];
  };
  /** Data contained in the rows. */
  data: Row[];
};

// For some use cases (e.g. defining liquids), we allow users to
// add metadata, using these prefixes.
export const COLUMN_METADATA_PREFIXES = [
  'tag',
  'solute_concentration',
  'solute_concentration_unit',
] as const;
export const COLUMN_DATA_TYPES = ['string', 'number', 'boolean'] as const;
export type ColumnDataType = (typeof COLUMN_DATA_TYPES)[number];
export type ColumnMetadataType = (typeof COLUMN_METADATA_PREFIXES)[number];

export type TableColumn = {
  name: string;
  type: ColumnDataType;
};

export type CellValue = string | number | boolean | null;

export type Row = {
  // e.g. { "Liquid Name": "Water" }
  [header: string]: CellValue | null;
};
