import React from 'react';

import { ScreenRegistry } from 'client/app/registry';
import Workspace from 'common/ui/components/Workspace/Workspace';

export function DeckLayoutEmpty() {
  return (
    <Workspace
      isShowAllButtonVisible={false}
      isShowHelpButtonVisible={false}
      canvasControlVariant="light_float"
      logCategory={ScreenRegistry.WORKFLOW}
    >
      <div />
    </Workspace>
  );
}
