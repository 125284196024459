import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import PlateLibraryDialog from 'client/app/components/Parameters/PlateType/PlateLibraryDialog';
import toWellLocationsOnDeckItem from 'client/app/components/Parameters/WellSelector/toWellLocationsOnDeckItem';
import UILeftRight from 'client/app/components/UILeftRight';
import WellSelector, {
  WellSelectionProps,
} from 'client/app/components/WellSelector/WellSelector';
import {
  cropWellsToExistingLocations,
  formatWellPosition,
  pluralize,
} from 'common/lib/format';
import createDummyPlate, {
  COMMON_PLATE_NAMES,
  isCommonPlateSize,
} from 'common/types/dummyPlates';
import { WellLocationOnDeckItem } from 'common/types/mix';
import Button from 'common/ui/components/Button';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import Dropdown from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  value?: string[];
} & DialogProps<string[] | undefined>;

const SELECT_FROM_INVENTORY_OPTION = 'Select from inventory';
// 96 wells plate is the most common, thus we will default the dropdown to this.
const MOST_COMMON_PLATE = createDummyPlate('96 wells');

// We are selecting wells only, from empty plates. No need for colours.
const LIQUID_COLORS = LiquidColors.createDefault();

export default function WellSelectorDialog(props: Props) {
  const { onClose, isOpen, value } = props;
  const classes = useStyles();
  const [plateLibraryDialog, openPlateLibraryDialog] = useDialog(PlateLibraryDialog);
  const { screenId } = useContext(ScreenContext);
  const [selectedWells, setSelectedWells] = useState<string[] | undefined>(value);
  const [selectedPlateType, setSelectedPlateType] = useState(MOST_COMMON_PLATE);
  const [allOptions, setAllOptions] = useState(() =>
    [...COMMON_PLATE_NAMES, SELECT_FROM_INVENTORY_OPTION].map(option => ({
      label: option,
      value: option,
    })),
  );

  // When dialog is closed/opened, reset the selection
  useEffect(() => setSelectedWells(value), [isOpen, value]);

  const [plates] = usePlatesByType();

  const [plate, deckLayout] = useMemo(() => {
    const plate = makeWellSelector(selectedPlateType);
    const deckLayout = getLayoutForWellSelector(plate);
    return [plate, deckLayout];
  }, [selectedPlateType]);

  const selectedWellLocations = useMemo(() => {
    if (!selectedWells) {
      return [];
    }
    return toWellLocationsOnDeckItem(selectedWells, plate);
  }, [plate, selectedWells]);

  const handleSetSelectedWells = useCallback(
    (newSelectedWells: readonly WellLocationOnDeckItem[] | undefined) => {
      if (!newSelectedWells) {
        return;
      }
      // Take a list of DeckPosition and turn it into a string[], which is what
      // the parameter editor for well positions accepts.
      const wellsCoordParamValue = newSelectedWells.map(formatWellPosition);
      setSelectedWells(wellsCoordParamValue);
    },
    [],
  );

  const wellSelectionProps: WellSelectionProps = useMemo(
    () => ({
      selectedWells: selectedWellLocations,
      onSelectWells: handleSetSelectedWells,
    }),
    [handleSetSelectedWells, selectedWellLocations],
  );

  const handleCancel = useCallback(() => {
    // On cancel, don't clear the selection but reuse the old value
    onClose(value);
  }, [onClose, value]);

  const handleConfirm = useCallback(() => {
    if (!selectedWells || selectedWells.length === 0) {
      onClose(undefined);
    } else {
      onClose(selectedWells);
    }
  }, [onClose, selectedWells]);

  const handleClearSelection = useCallback(() => {
    setSelectedWells([]);
  }, []);

  const onSelectPlateType = useCallback(
    async (plateFromDropdown?: string) => {
      const canceledSelection = !plateFromDropdown;
      if (canceledSelection) {
        return;
      }

      const clickedOnSelectFromInventory =
        plateFromDropdown === SELECT_FROM_INVENTORY_OPTION;
      if (clickedOnSelectFromInventory) {
        // Allow users to select which plate to visualise from the inventory.
        const plateTypeFromInventory = await openPlateLibraryDialog({});

        // If users cancel their selection
        if (!plateTypeFromInventory || typeof plateTypeFromInventory !== 'string') {
          return;
        }

        const humanReadableName = plates(plateTypeFromInventory).name;
        const DropdownOption = {
          label: humanReadableName,
          value: plateTypeFromInventory,
        };
        // Add the selected plate to the list of available options,
        // so it can be correctly rendered in the dropdown
        const plateAlreadyInDropdown = allOptions.some(
          option => option.value === DropdownOption.value,
        );
        if (!plateAlreadyInDropdown) {
          setAllOptions(prev => [DropdownOption, ...prev]);
        }
        setSelectedPlateType(plates(plateTypeFromInventory));
      } else {
        // We need to check if the dropdown option users just selected is a plate size or
        // a plate type from the inventory.
        setSelectedPlateType(() =>
          isCommonPlateSize(plateFromDropdown)
            ? createDummyPlate(plateFromDropdown)
            : plates(plateFromDropdown),
        );
      }
    },
    [allOptions, openPlateLibraryDialog, plates],
  );

  // If the user changes the plate type deselect wells that don't exist on the new plate
  useEffect(() => {
    setSelectedWells(
      selectedWells =>
        selectedWells && cropWellsToExistingLocations(selectedWells, plate),
    );
  }, [plate]);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="md"
        onClose={handleCancel}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>Well selection</DialogTitle>
        <DialogContent>
          Click on wells to select or unselect them. You can also select an entire row or
          column by clicking on the corresponding label.
          <div className={classes.wellSelectorContainer}>
            <div className={classes.controlsContainer}>
              <InputLabel shrink>Plate to visualise</InputLabel>
              <Dropdown
                options={allOptions}
                valueLabel={selectedPlateType.name}
                onChange={onSelectPlateType}
              />
              <WellSelector
                wellSelectionProps={wellSelectionProps}
                deckLayout={deckLayout}
                plate={plate}
                liquidColors={LIQUID_COLORS}
                googleAnalyticsCategory={screenId as string}
              />
            </div>
            <div className={classes.selectedWellsCaption}>
              <Typography variant="h5"> Currently selected:</Typography>
              {selectedWells && (
                <p className={classes.selectedWellsList}>{selectedWells.join(', ')}</p>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActionSection}>
          <UILeftRight>
            <div className={classes.counterAndClearBtnContainer}>
              <div className={classes.displayNumberText}>
                {pluralize(selectedWells ? selectedWells.length : 0, 'well')} selected
              </div>
              <Button variant="tertiary" onClick={handleClearSelection}>
                Clear
              </Button>
            </div>
            <div>
              <Button variant="tertiary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant="tertiary" color="primary" onClick={handleConfirm}>
                Save
              </Button>
            </div>
          </UILeftRight>
        </DialogActions>
      </Dialog>
      {plateLibraryDialog}
    </>
  );
}

const useStyles = makeStylesHook({
  controlsContainer: { display: 'flex', flexDirection: 'column' },
  // Fit the well selector and its panel in the dialog
  paper: { width: '750px' },
  wellSelectorContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '2rem 0',
  },
  selectedWellsCaption: {
    width: '200px',
  },
  selectedWellsList: {
    // Prevent long list of wells to increase height of dialog
    height: '200px',
    overflow: 'scroll',
  },
  displayNumberText: {
    padding: '8px',
  },
  dialogActionSection: {
    display: 'inline',
  },
  counterAndClearBtnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
