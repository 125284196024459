import React, { ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import CardType from 'common/ui/components/CardType';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Field = {
  label: string;
};

type StringField = {
  value: string;
} & Field;

type DateField = {
  value: Date;
} & Field;

type Interaction = {
  to?: string | undefined;
  onClick?: () => void;
} & Partial<Field>;

type EntityCardProps = {
  icon: ReactNode;
  nameColumn: StringField;
  authorColumn?: StringField;
  dateColumn?: DateField;
  additionalColumn?: StringField;
  interaction?: Interaction;
  isDisabled?: boolean;
  isSelected?: boolean;
  rightSlot?: ReactNode;
  heapTrackingLabel?: string;
  className?: string;
};

/**
 * Used for displaying information about an entity (workflow, experiment, etc) in the
 * experiment UI and lists of that entity.
 */
export function EntityCard(props: EntityCardProps) {
  const { isDisabled, isSelected } = props;

  return (
    <Card
      className={props.className}
      variant="outlined"
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      <EntityCardContent {...props} />
    </Card>
  );
}

export function EntityCardContent(props: Omit<EntityCardProps, 'isSelected'>) {
  const {
    icon,
    nameColumn,
    authorColumn,
    dateColumn,
    additionalColumn,
    interaction,
    isDisabled,
    rightSlot,
    heapTrackingLabel,
  } = props;
  const gridTemplateColumns = formatEntityCardGridTemplate(props);
  return (
    <>
      <Link
        data-heap-tracking={
          heapTrackingLabel ? `entity-card-${heapTrackingLabel}` : undefined
        }
        to={isDisabled ? undefined : interaction?.to}
        onClick={isDisabled ? undefined : interaction?.onClick}
        isDisabled={isDisabled}
        isClickable={!isDisabled && !!(interaction?.to || interaction?.onClick)}
      >
        <Summary sx={{ gridTemplateColumns }}>
          <Icon icon={icon} disabled={isDisabled} />
          <NameColumn>
            <TypographyWithTooltip
              className="EntityCardContent-nameColumn-label"
              variant="overline"
            >
              {nameColumn.label}
            </TypographyWithTooltip>
            <TypographyWithTooltip
              className="EntityCardContent-nameColumn-value"
              variant="body1"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {nameColumn.value}
            </TypographyWithTooltip>
          </NameColumn>
          {additionalColumn && (
            <AdditionalColumn>
              <Typography variant="overline">{additionalColumn.label}</Typography>
              <Typography
                variant="body1"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {additionalColumn.value}
              </Typography>
            </AdditionalColumn>
          )}
          {authorColumn && (
            <AuthorColumn>
              <Typography variant="overline" whiteSpace="nowrap" overflow="hidden">
                {authorColumn.label}
              </Typography>
              <Typography
                variant="body1"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {authorColumn.value}
              </Typography>
            </AuthorColumn>
          )}
          {dateColumn && (
            <DateColumn>
              <Typography variant="overline">{dateColumn.label}</Typography>
              <Typography variant="body1" whiteSpace="nowrap">
                {formatDateTime(dateColumn.value)}
              </Typography>
            </DateColumn>
          )}
        </Summary>
      </Link>
      {rightSlot}
    </>
  );
}

function CardLink(props: {
  to?: string;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}) {
  return props.to ? <RouterLink to={props.to} {...props} /> : <div {...props} />;
}

/**
 * Use to indicate loading of entity cards.
 */
export function EntityCardSkeletonList() {
  return (
    <List>
      <EntityCardSkeleton />
      <EntityCardSkeleton />
      <EntityCardSkeleton />
    </List>
  );
}

function EntityCardSkeleton() {
  return (
    <Skeleton
      sx={{ borderRadius: `${ENTITY_CARD_BORDER_RADIUS}px` }}
      variant="rounded"
      height={ENTITY_CARD_HEIGHT}
      width="100%"
    />
  );
}

export const ENTITY_CARD_HEIGHT = 74;
export const ENTITY_CARD_BORDER_RADIUS = 8;

function formatEntityCardGridTemplate(props: EntityCardProps): string {
  let template = '[icon] 24px [name] minmax(120px, 1fr)';

  if (props.additionalColumn) {
    template += ' [additional] 120px';
  }

  if (props.authorColumn) {
    template += ' [author] minmax(60px, 188px)';
  }
  if (props.dateColumn) {
    template += ' [date] minmax(100px, 140px)';
  }
  template += ' [end]';
  return template;
}

const Card = styled(Paper, {
  shouldForwardProp: (propName: string) =>
    !['isSelected', 'isDisabled'].includes(propName),
})<{ isSelected?: boolean; isDisabled?: boolean }>(
  ({ theme, isSelected, isDisabled }) => ({
    display: 'flex',
    borderRadius: `${ENTITY_CARD_BORDER_RADIUS}px`,
    height: `${ENTITY_CARD_HEIGHT}px`,
    ...(isDisabled && {
      color: theme.palette.text.disabled,
    }),
    ...(isSelected && {
      boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
    }),
  }),
);

const Summary = styled('div')(({ theme }) => ({
  display: 'grid',
  height: '100%',
  gridAutoFlow: 'column',
  gap: theme.spacing(0, 5),
  padding: theme.spacing(5, 6),
  alignItems: 'stretch',
  margin: 0,
}));

const Field = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const NameColumn = styled(Field)(() => ({
  gridColumn: 'name',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const AuthorColumn = styled(Field)(() => ({
  gridColumn: 'author',
}));

const DateColumn = styled(Field)(() => ({
  gridColumn: 'date',
}));

const AdditionalColumn = styled(Field)({
  gridColumn: `additional`,
});

const Icon = styled(CardType)(() => ({
  gridColumn: 'icon',
  alignSelf: 'center',
}));

const Link = styled(CardLink, {
  shouldForwardProp: (propName: string) =>
    !['isDisabled', 'isClickable'].includes(propName),
})<{ isDisabled?: boolean; isClickable?: boolean }>(
  ({ theme, isDisabled, isClickable }) => ({
    width: '100%',
    height: '74px', // TODO: Remove when the EntityCardExpandable is refactored to use EntityCard
    ...(isClickable && {
      display: 'block',
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER },
      '&:active': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_ACTIVE },
    }),
    ...(isDisabled && {
      color: theme.palette.text.disabled,
    }),
  }),
);

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));
