import React, { createElement, FunctionComponent, ReactElement } from 'react';

import { OperationVariables, QueryResult } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { circularLoadingContainer } from 'common/ui/commonStyles';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type RenderQueryProps<
  TData,
  DataProps extends { data: TData },
  NoDataProps extends Record<string, any>,
  LoadingProps extends Record<string, any>,
  TVariables = OperationVariables,
> = {
  /** The apollo client query you wish to render */
  query: QueryResult<TData, TVariables>;
  /** A functional component to display when the query returns data */
  renderData: FunctionComponent<DataProps>;
  /** A functional component to display when the query returns no data */
  renderNoData: FunctionComponent<NoDataProps>;
  /** Optionally provide a custom loading component. By default we show a MUI Circular Progress */
  loadingComponent?: FunctionComponent<LoadingProps>;
  /** Allows you to specify additional ways that data can be deemed as not present from a query response e.g an empty list */
  emptyCondition?: (data: TData) => boolean;
  /** Allows for extra props to be passed to the coresponding components */
  additionalNoDataProps?: NoDataProps;
  additionalLoadingProps?: LoadingProps;
  additionalDataProps?: Omit<DataProps, 'data'>;
};

/** This component provides a standardised way to handle rendering an Apollo Client query */
export function RenderQuery<
  TData,
  DataProps extends { data: TData },
  NoDataProps extends Record<string, any>,
  LoadingProps extends Record<string, any>,
  TVariables = OperationVariables,
>({
  query,
  renderNoData,
  renderData,
  loadingComponent,
  additionalNoDataProps,
  additionalLoadingProps,
  additionalDataProps,
  emptyCondition,
}: RenderQueryProps<
  TData,
  DataProps,
  NoDataProps,
  LoadingProps,
  TVariables
>): ReactElement {
  const classes = useStyles();

  if (query.loading) {
    return loadingComponent ? (
      createElement(loadingComponent, additionalLoadingProps)
    ) : (
      <div className={classes.circularLoadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (query.error) {
    return <GraphQLErrorPanel error={query.error} onRetry={query.refetch} />;
  }

  if (!query.data || emptyCondition?.(query.data)) {
    return createElement(renderNoData, additionalNoDataProps);
  }

  return createElement(renderData, {
    data: query.data,
    ...additionalDataProps,
  } as DataProps);
}

const useStyles = makeStylesHook({
  ...circularLoadingContainer,
});
