import { formatMeasurementObj, formatVolumeConsequence } from 'common/lib/format';
import {
  isMeasurement,
  Rule,
  RuleConsequenceValue,
  VolumeConsequence,
} from 'common/types/mix';

/**
 * Each rule has multiple consequences, each comprising a property and value. If a
 * property occurs more than once, then the last value for that property overrides the
 * others.
 *
 * Iterate through the rules in reverse, marking each property as seen. If we see the
 * property again then mark it as overridden. The rules are then unreversed.
 */
export function getRulesWithOverrides(
  ruleIds: string[],
  rules: { [id: string]: Rule },
): RuleWithOverrides[] {
  const propertiesSeen = new Set<string>();
  return ruleIds
    .map(ruleId => {
      const rule = rules[ruleId];
      if (!rule) {
        throw new Error(`Rule ${ruleId} does not exist in actions.json`);
      }
      return rule;
    })
    .reverse()
    .map(rule => ({
      ...rule,
      consequences: Object.entries(rule.consequences).map(([property, value]) => {
        const overridden = propertiesSeen.has(property);
        propertiesSeen.add(property);
        return { property, value, overridden };
      }),
    }))
    .reverse();
}

export type RuleWithOverrides = Omit<Rule, 'consequences'> & {
  consequences: {
    property: string;
    value: RuleConsequenceValue;
    overridden: boolean;
  }[];
};

export function formatConsequenceValue(value: RuleConsequenceValue): string {
  const isArrayOfStrings =
    Array.isArray(value) && value.every(item => typeof item === 'string');

  if (typeof value === 'string') {
    return value;
  } else if (isArrayOfStrings) {
    return value.join('; ');
  } else if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  } else if (typeof value === 'number') {
    return String(value);
  } else if (isMeasurement(value)) {
    return formatMeasurementObj(value);
  } else {
    // We have exausted all of the options for a possible type
    // Typescript doesn't know that if(isArrayOfStrings) covers the string[] case
    return formatVolumeConsequence(value as VolumeConsequence);
  }
}

/**
 * Used by formatConsequencePropertyName to correctly capitalize acronyms used within
 * consequence properties.
 */
const acronyms = ['lld', 'llf', 'id'];

/**
 * Replace underscores with spaces and capitalize each word. We use a hard coded list of
 * acronyms to ensure they capitalized appropriately. This will be replaced by a screen in
 * antha admin UI for configuring labels for each consequence.
 */
export function formatConsequencePropertyName(property: string): string {
  return property
    .split('_')
    .map(word =>
      acronyms.includes(word)
        ? word.toUpperCase()
        : word[0].toUpperCase() + word.slice(1),
    )
    .join(' ');
}
