import React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type Props = {
  plateName: string;
  disabled: boolean;
  onCreate: () => void;
};

export const NamedPlatePlaceholder = React.memo(function NamedPlatePlaceholder({
  plateName,
  disabled,
  onCreate,
}: Props) {
  return (
    <Container>
      <CreateNameButton
        size="small"
        variant="outlined"
        color="inherit"
        disabled={disabled}
        onClick={() => onCreate()}
      >
        Create Name
      </CreateNameButton>
      <Typography
        color="textPrimary"
        variant="body2"
        sx={{ opacity: disabled ? 0.4 : 1 }}
      >
        {plateName}
      </Typography>
    </Container>
  );
});

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  gap: '10px',
}));

const CreateNameButton = styled(Button)(({ theme }) => ({
  height: '24px',
  whiteSpace: 'nowrap',
  minWidth: 'min-content',
  borderColor: theme.palette.divider,
  marginBottom: theme.spacing(2),
}));
