import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import DropdownEditor from 'client/app/components/Parameters/DropdownEditor';
import { getOptions } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/utils';
import {
  defaultExtraELISAParameters,
  ELISA_SAMPLE_TYPES,
  ELISASampleType,
  ExtraELISAParameters,
} from 'common/types/plateSetDescription';
import CheckBoxEditor from 'common/ui/components/ParameterEditors/CheckBoxEditor';

// Hard coded antha types that identify a liquid. Must do this because it's not
// possible to infer them from the PlateSetDescription object specified in go
const COATING_NAME_TYPE = 'github.com/Synthace/antha/stdlib/schemas/aliases.CoatingName';

type Props = {
  value?: ExtraELISAParameters;
  onChange: (value?: ExtraELISAParameters) => void;
  isDisabled: boolean;
};

export function ExtraELISAParametersEditor({ value, onChange, isDisabled }: Props) {
  const [params, setParams] = useState(value ?? defaultExtraELISAParameters());

  const sampleTypeOptions = useMemo(() => getOptions(ELISA_SAMPLE_TYPES), []);
  const handleChangeSampleType = useCallback((v?: string) => {
    v && setParams(prev => ({ ...prev, sampleType: v as ELISASampleType }));
  }, []);

  const [coatingWarning, setCoatingWarning] = useState('');
  const handleChangeCoating = useCallback(
    (v?: string | null) => {
      setCoatingWarning(v ? '' : 'Please enter a coating name');
      setParams(prev => {
        const next = { ...prev, coatingName: v || '' };
        if (next.coatingName) onChange(next);
        return next;
      });
    },
    [onChange],
  );

  const handleChangeApplyToAllPlates = useCallback((v?: boolean) => {
    setParams(prev => ({ ...prev, applyToAllPlates: v ?? false }));
  }, []);

  // since we can't initialise the default in the parent component, we detect if
  // the value is empty and do it here
  useEffect(() => {
    !value && onChange(params);
  }, [onChange, params, value]);

  return (
    <>
      <Box>
        <FormLabel>Sample type</FormLabel>
        <DropdownEditor
          anthaType=""
          useDynamicOptions={false}
          value={params.sampleType}
          options={sampleTypeOptions}
          onChange={handleChangeSampleType}
          isDisabled={isDisabled}
        />
      </Box>
      <Box>
        <FormLabel>Coating name</FormLabel>
        <AutocompleteWithParameterValues
          placeholder="Coating name"
          valueLabel={params.coatingName}
          anthaType={COATING_NAME_TYPE}
          acceptCustomValues
          onChange={handleChangeCoating}
          isDisabled={isDisabled}
        />
        {coatingWarning ? <FormHelperText error>{coatingWarning}</FormHelperText> : null}
      </Box>
      <Box>
        <FormLabel>Apply to all plates</FormLabel>
        <CheckBoxEditor
          value={params.applyToAllPlates}
          onChange={handleChangeApplyToAllPlates}
          isDisabled={isDisabled}
        />
      </Box>
    </>
  );
}
