import React, { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import {
  defaultExtraContentsParameters,
  ExtraContentsParameters,
} from 'common/types/plateSetDescription';
import IntegerEditor from 'common/ui/components/ParameterEditors/IntegerEditor';
import StringMeasurementEditor from 'common/ui/components/ParameterEditors/StringMeasurementEditor';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';

const VOLUME_UNITS = getSensibleMeasurementUnits('Volume');
const MIRCOLITRES = 'ul';

type Props = {
  value?: ExtraContentsParameters;
  onChange: (value?: ExtraContentsParameters) => void;
  isDisabled: boolean;
};

export function ExtraContentsParametersEditor({ value, onChange, isDisabled }: Props) {
  const [params, setParams] = useState(value ?? defaultExtraContentsParameters());

  const [volumeWarning, setVolumeWarning] = useState('');
  const handleChangeVolume = useCallback(
    (v?: string) => {
      setVolumeWarning(v ? '' : 'Please ensure a volume is set');
      setParams(prev => {
        const next = { ...prev, volume: v || '' };
        if (next.numWellsToUse && next.volume) onChange(next);
        return next;
      });
    },
    [onChange],
  );

  const [numWellsWarning, setNumWellsWarning] = useState('');
  const handleChangeNumWellsToUse = useCallback(
    (v?: number) => {
      setNumWellsWarning(v ? '' : 'Number of wells to use must be at least 1');
      setParams(prev => {
        const next = { ...prev, numWellsToUse: v || 1 };
        if (next.numWellsToUse && next.volume) onChange(next);
        return next;
      });
    },
    [onChange],
  );

  // since we can't initialise the default in the parent component, we detect if
  // the value is empty and do it here
  useEffect(() => {
    !value && onChange(params);
  }, [onChange, params, value]);

  return (
    <>
      <Box>
        <FormLabel>Volume</FormLabel>
        <StringMeasurementEditor
          value={params.volume}
          units={VOLUME_UNITS}
          defaultUnit={MIRCOLITRES}
          onChange={handleChangeVolume}
          isDisabled={isDisabled}
        />
        <Warning text={volumeWarning} />
      </Box>
      <Box>
        <FormLabel>Number of wells to use</FormLabel>
        <IntegerEditor
          type="int"
          value={params.numWellsToUse}
          onChange={handleChangeNumWellsToUse}
          isDisabled={isDisabled}
        />
        <Warning text={numWellsWarning} />
      </Box>
    </>
  );
}

function Warning({ text }: { text?: string }) {
  return text ? <FormHelperText error>{text}</FormHelperText> : null;
}
