import React, { useCallback, useRef } from 'react';

import UploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';

import { PlateTypeByTypeName } from 'client/app/api/PlateTypesApi';
import {
  addPlateOrigin,
  cherryPickDataFromParsedAnthaPlate,
  parseAnthaPlate,
  plateOriginType,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  SetCherryPick,
  SetPlatesByName,
  useCherryPickContext,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import { ScreenRegistry } from 'client/app/registry';
import getFileBytes from 'common/lib/getFileBytes';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

type TypeSelectorProps = {
  setPlatesByName: SetPlatesByName;
  setCherryPick: SetCherryPick;
  plates: PlateTypeByTypeName;
  isDisabled: boolean;
};
/**
 *  If a plate type hasn't been selected, render a dummy plate with a button
 *  to allow users to select one.
 */
export default React.memo(function PlateUploader(props: TypeSelectorProps) {
  const { setPlatesByName, plates, setCherryPick, isDisabled } = props;
  const { setPlateOrigins } = useCherryPickContext();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadPlate = useCallback(
    async (file: File) => {
      logEvent('upload-csv-plate', ScreenRegistry.CHERRY_PICKER);
      if (!file) {
        return;
      }

      const csvData = await getFileBytes(file);
      const [parsedAnthaPlate, plateType, plateName] = parseAnthaPlate(csvData);
      const cherryPick = cherryPickDataFromParsedAnthaPlate(
        parsedAnthaPlate,
        plateType,
        plateName,
      );
      setCherryPick(prev => [...cherryPick, ...prev]);

      const plate = plates(plateType);
      setPlatesByName(prev => ({ ...prev, [plateName]: plate }));
      setPlateOrigins(prev =>
        addPlateOrigin(prev, plateName, plateOriginType.UPLOAD, file.name),
      );
    },
    [plates, setCherryPick, setPlatesByName, setPlateOrigins],
  );

  const handleFileInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files) {
        return;
      }
      await handleUploadPlate(files[0]);
    },
    [handleUploadPlate],
  );

  return (
    <>
      <input
        id="cherry-pick-upload-plate"
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
      <Tooltip title="Upload a Synthace generated plate.csv">
        <label htmlFor="cherry-pick-upload-plate">
          <IconButton color="primary" component="span" disabled={isDisabled} size="large">
            <UploadIcon />
          </IconButton>
        </label>
      </Tooltip>
    </>
  );
});
