import React, { useCallback, useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { getObjectFriendlyName, trimBeforeLastFullStop } from 'common/lib/format';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NEW_ENTRY_PLACEHOLDER = 'Set key name...';

function typeBasedPlaceholder(typeName: string) {
  if (typeName !== '' && typeName !== 'string') {
    return getObjectFriendlyName(trimBeforeLastFullStop(typeName)) + '...';
  }
  return NEW_ENTRY_PLACEHOLDER;
}

type Props = {
  keySet: Set<string>;
  keyType: string;
  keyString: string;
  valueType: string;
  value: any;
  onKeyChange: (oldKey: string, newKey: string, elementInstanceId?: string) => void;
  onValueChange: (key: string, value: any) => void;
  onDeleteEntry: (key: string) => void;
  elementInstanceId?: string;
  keyEditorProps?: ParameterEditorConfigurationSpec;
  valueEditorProps?: ParameterEditorConfigurationSpec;
  /**
   * Make the key and value editors occupy the same line
   */
  inline?: boolean;
  /**
   * This locks the map so no keys can be added, removed, or changed. Only existing values
   * can be changed.
   */
  disableKeys?: boolean;
  isDisabled?: boolean;
};

export type MapKeyValueType = 'MAP_KEY' | 'MAP_VALUE';

export const MapEntryEditorBase = React.memo(function MapEntryEditorBase(props: Props) {
  const [keyEditorValue, setKeyEditorValue] = useState(props.keyString);
  const [keyErrorMessage, setKeyErrorMessage] = useState('');

  const classes = useStyles();

  useEffect(() => {
    setKeyEditorValue(props.keyString);
  }, [props.keyString]);

  const onKeyChange = useCallback(
    (value: string) => {
      // There is some sort of fiddly detail here that is worth documenting. We
      // want to trim() the value before we pass it back to the caller. This
      // prevents the super annoying case of invisible, trailing whitespace
      // breaking things. However, we also support spaces in key names. So, we
      // need to sanity check the value against the trimmed value, but let the
      // user add spaces. If we always trimmed the value before storing it, we'd
      // eat trailing whitespace even when the user was in the middle of typing
      // multiple words.
      //
      // This implementation works pretty sanely. If you're typing "foo bar" and
      // a key "foo" already exists, we'll warn you about it even when you've
      // type "foo ". As soon as the user hits "foo b" the warning will go away.
      // This gives the user the flexibility to type spaces in key names but
      // keeps the warning up as long as the trimmed value is spurious.
      const trimmedValue = value ? value.trim() : '';

      let errorMessage = '';
      if (trimmedValue === '') {
        errorMessage = 'Sorry, map keys cannot be empty';
      }

      if (props.keySet.has(trimmedValue) && value !== props.keyString) {
        errorMessage = `Sorry, key "${trimmedValue}" already exists`;
      }

      setKeyErrorMessage(errorMessage);
      setKeyEditorValue(value);
    },
    [props.keySet, props.keyString],
  );

  const applyNewKey = useCallback(
    (newKey: string | null) => {
      if (keyErrorMessage !== '') {
        return;
      }

      // Unclear what the best UX is here -- probably not wise to let the user
      // completely delete the contents of the key (also guards against the case
      // where the user starts adding a new entry and then abandons it with the
      // key still empty).
      if (!newKey) {
        return;
      }
      props.onKeyChange(
        props.keyString,
        newKey.trim(),
        // We pass this all the way down from Instance Parameters so that we know the original instance
        // this was intended to be called on so that we can apply the change as expected when the user
        // causes a dismount by clicking a different element instance.
        props.elementInstanceId,
      );
    },
    [keyErrorMessage, props],
  );

  const changeAndBlur = useCallback(
    (value: string) => {
      if (props.keySet.has(value)) {
        setKeyErrorMessage(`Sorry, key "${value}" already exists`);
        return;
      }

      onKeyChange(value);
      applyNewKey(value);
    },
    [applyNewKey, onKeyChange, props.keySet],
  );

  const onValueChange = useCallback(
    (value: any) => {
      props.onValueChange(props.keyString, value);
    },
    [props],
  );

  const deleteEntry = () => {
    props.onDeleteEntry(props.keyString);
  };

  const renderKey = (keyType: string, key: string) => {
    const {
      placeholder: keyPlaceholder,
      type: keyEditorType,
      additionalProps: keyEditorProps,
    } = props.keyEditorProps ?? {};

    return (
      <div className={cx(classes.key, { [classes.inline]: inline })}>
        {keyErrorMessage !== '' && (
          <em className={classes.inlineError}>{keyErrorMessage}</em>
        )}
        {props.disableKeys ? (
          <Typography className={classes.frozenKey} variant="body2">
            {key}
          </Typography>
        ) : (
          <ParameterEditor
            isRequired
            anthaType={keyType}
            value={key}
            onChange={keyEditorType === EditorType.DROPDOWN ? changeAndBlur : onKeyChange}
            isDisabled={props.isDisabled}
            elementInstanceId={elementInstanceId}
            placeholder={keyPlaceholder ?? typeBasedPlaceholder(keyType)}
            editorType={keyEditorType ?? EditorType.STRING}
            editorProps={keyEditorProps ?? undefined}
            onBlur={() => applyNewKey(keyEditorValue)}
            mapKeyValueType="MAP_KEY"
          />
        )}
      </div>
    );
  };

  const { keyType, valueType, value, elementInstanceId, inline } = props;
  const {
    placeholder: valuePlaceholder,
    type: valueEditorType,
    additionalProps: valueEditorProps,
  } = props.valueEditorProps ?? {};
  return (
    <div className={classes.mapEntry}>
      {!props.isDisabled && !props.disableKeys && (
        <div className={classes.clearIcon} onClick={deleteEntry}>
          <ClearIcon />
        </div>
      )}
      <div
        className={cx(classes.mapEntryInner, {
          [classes.mapEntryInnerInline]: inline,
        })}
      >
        {renderKey(keyType, keyEditorValue || '')}
        <div className={cx(classes.value, { [classes.inline]: inline })}>
          <ParameterEditor
            anthaType={valueType}
            value={value}
            onChange={onValueChange}
            isDisabled={props.isDisabled || !props.keyString}
            elementInstanceId={elementInstanceId}
            placeholder={valuePlaceholder ?? typeBasedPlaceholder(valueType)}
            editorType={valueEditorType ?? undefined}
            editorProps={valueEditorProps ?? undefined}
            mapKeyValueType="MAP_VALUE"
          />
        </div>
      </div>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  key: {
    transform: 'scale(0.8)',
    transformOrigin: 'top left',
  },
  value: {
    marginLeft: '8px',
    maxWidth: 'calc(100 - 8px)',
  },
  mapEntry: {
    marginBottom: '10px',
    paddingLeft: '20px',
    position: 'relative',
    '&:hover $clearIcon': {
      display: 'block',
    },
  },
  mapEntryInner: {
    borderLeft: '1px #c4caca solid',
    paddingLeft: '12px',
  },
  clearIcon: {
    color: Colors.RED,
    cursor: 'pointer',
    display: 'none',
    fontSize: '11px',
    position: 'absolute',
    left: '-7px',
    top: 0,
  },
  keyDisplay: {
    cursor: 'pointer',
    fontSize: '11px',
    paddingTop: '5px',
    // Preserving spaces in key names is super important. HTML will naturally
    // collapse multiple spaces into a single space, which can lead to situations
    // where the user accidentally typed "foo  bar" but then is shown "foo bar".
    // To prevent this masking, `white-space: pre` will ensure we visually
    // preserve any extra white space the user might've accidentally included.
    whiteSpace: 'pre',
  },
  enabledKeyDisplay: {
    '&:hover': {
      color: Colors.BLUE,
      textDecoration: 'underline',
    },
  },
  keyEditor: {
    fontSize: '11px',
    paddingBottom: '5px',
  },
  keyEditorSave: {
    padding: '0',
  },
  inlineError: {
    color: Colors.RED,
    fontStyle: 'italic',
    fontSize: '11px',
  },
  mapEntryInnerInline: {
    display: 'grid',
    // Make the value input wider than the key
    gridTemplateColumns: '1fr 3fr',
  },
  inline: {
    transform: 'scale(1)',
  },
  frozenKey: {
    marginTop: theme.spacing(3),
  },
}));
