import React, { useRef, useState } from 'react';

import KebabIcon from '@mui/icons-material/MoreVertOutlined';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { NamedPlatesSelectorPopover } from 'client/app/components/Parameters/DeckOptions/LabwareSelector/NamedPlatePlaceholderPopover';
import {
  formatLabwareTypeName,
  isNamedPlate,
  LabwareType,
  NamedPlate,
} from 'client/app/state/LabwarePreference';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';

const POPOVER_WIDTH = 278;

type Props = {
  labwareType: LabwareType;
  numPositions: number;
  checkPlateNameUnique: (name: string) => boolean;
  onSelect: () => void;
  onDelete: (name: NamedPlate) => void;
  onRename: (oldName: NamedPlate, newName: NamedPlate) => void;
  isActive: boolean;
  isDisabled: boolean;
};

export const LabwareListItem = React.memo(function LabwareListItem({
  labwareType,
  numPositions,
  checkPlateNameUnique,
  onSelect,
  onDelete,
  onRename,
  isDisabled,
  isActive,
}: Props) {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = !!anchorEl;

  const onKebabMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(popoverRef.current);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const isLabwareANamedPlate = isNamedPlate(labwareType);
  const label = formatLabwareTypeName(labwareType);

  return (
    <ListItem isSelected={isActive} ref={popoverRef} onClick={onSelect}>
      <Typography
        sx={isActive ? { color: Colors.PRIMARY_CONTRAST } : undefined}
        color="textPrimary"
        variant="subtitle2"
        noWrap
      >
        {`[${numPositions}] ${label}`}
      </Typography>
      {isLabwareANamedPlate && !isDisabled && (
        <>
          <IconButton
            sx={{ color: isActive ? Colors.PRIMARY_CONTRAST : Colors.TEXT_PRIMARY }}
            size="xsmall"
            icon={<KebabIcon />}
            onClick={onKebabMenuClick}
          />
          <Popover
            slotProps={{
              paper: {
                sx: ({ spacing }) => ({
                  borderRadius: spacing(3),
                  width: POPOVER_WIDTH,
                  padding: 0,
                  marginTop: spacing(2),
                }),
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClick={event => event.stopPropagation()} // Don't let clickevents propagate
            onMouseDown={event => event.stopPropagation()} // Don't let mouseevents propagate
          >
            <NamedPlatesSelectorPopover
              namedPlate={labwareType}
              checkPlateNameUnique={checkPlateNameUnique}
              onRename={onRename}
              onDelete={onDelete}
              onClose={onClose}
            />
          </Popover>
        </>
      )}
    </ListItem>
  );
});

const ListItem = styled('div')<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '32px',
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: '16px',
  width: '100%',
  padding: '8px',
  backgroundColor: isSelected ? Colors.BLUE_80 : 'transparent',
  pointerEvents: 'auto',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: isSelected ? Colors.BLUE_80 : '#e2eeff',
    borderColor: '#e2eeff',
  },
}));
