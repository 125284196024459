import React, { useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DeckOptionsParameter from 'client/app/components/Parameters/DeckOptions/DeckOptionsParameter';
import { DevicePlateTypeSelector } from 'client/app/components/Parameters/DeviceConfigurator/DevicePlateTypeSelector';
import { DeviceTipTypeSelector } from 'client/app/components/Parameters/DeviceConfigurator/DeviceTipTypeSelector';
import DeviceSelectorCard from 'client/app/components/Parameters/DeviceSelector/DeviceSelectorCard';
import { ConfiguredDevice } from 'common/types/bundle';
import {
  isDeviceThatCanPerformLiquidHandling,
  isLiquidHandlerDevice,
} from 'common/types/bundleConfigUtils';

export type DeviceConfiguratorHeader =
  | 'Mode'
  | 'Deck Layout'
  | 'Tip Types'
  | 'Input Plate Types';

type Props = {
  value?: ConfiguredDevice[];
  onChange: (newConfiguredDevices: ConfiguredDevice[]) => void;
  numStages: number;
  /** renderHeader enables custom styling of the device configuration headers */
  renderHeader?: (name: DeviceConfiguratorHeader) => React.ReactNode;
  isDisabled?: boolean;
};

/**
 * This component enables full modification of _one_ main liquid handling device
 * and its configuration as well as its accessible devices
 */
export default function DeviceConfigurator({
  value: allDevices = [],
  onChange,
  numStages,
  renderHeader = defaultHeader,
  isDisabled = false,
}: Props) {
  const mainDevice = useMemo(
    () => allDevices.find(isDeviceThatCanPerformLiquidHandling),
    [allDevices],
  );

  const mainDeviceIndex = useMemo(
    () => allDevices.findIndex(v => v.id === mainDevice?.id),
    [allDevices, mainDevice?.id],
  );

  const handleConfigChange = useCallback(
    (newMainDevice: ConfiguredDevice) => {
      onChange(allDevices.toSpliced(mainDeviceIndex, 1, newMainDevice));
    },
    [allDevices, mainDeviceIndex, onChange],
  );

  const handleTipTypesChange = useCallback(
    (tipTypes?: string[]) => {
      const newMainDevice: ConfiguredDevice = { ...mainDevice!, tipTypes };
      onChange(allDevices.toSpliced(mainDeviceIndex, 1, newMainDevice));
    },
    [allDevices, mainDevice, mainDeviceIndex, onChange],
  );

  const handlePlateTypesChange = useCallback(
    (inputPlateTypes?: string[]) => {
      const newMainDevice: ConfiguredDevice = { ...mainDevice!, inputPlateTypes };
      onChange(allDevices.toSpliced(mainDeviceIndex, 1, newMainDevice));
    },
    [allDevices, mainDevice, mainDeviceIndex, onChange],
  );

  const isComplexLiquidHandler = mainDevice && isLiquidHandlerDevice(mainDevice);
  const showPlateTypes = mainDevice && isDeviceThatCanPerformLiquidHandling(mainDevice);

  return (
    <Stack spacing={4}>
      {renderHeader('Mode')}
      <DeviceSelectorCard
        value={allDevices}
        onChange={onChange}
        numStages={numStages}
        isDisabled={isDisabled}
      />
      {isComplexLiquidHandler && (
        <>
          <Divider flexItem />
          {renderHeader('Deck Layout')}
          <DeckOptionsParameter
            value={mainDevice}
            onChange={handleConfigChange}
            isDisabled={isDisabled}
          />
          <Divider flexItem />
          {renderHeader('Tip Types')}
          <DeviceTipTypeSelector
            value={mainDevice.tipTypes}
            onChange={handleTipTypesChange}
            isDisabled={isDisabled}
          />
        </>
      )}
      {showPlateTypes && (
        <>
          <Divider flexItem />
          {renderHeader('Input Plate Types')}
          <DevicePlateTypeSelector
            value={mainDevice.inputPlateTypes}
            onChange={handlePlateTypesChange}
            isDisabled={isDisabled}
          />
        </>
      )}
    </Stack>
  );
}

function defaultHeader(name: DeviceConfiguratorHeader) {
  return <Typography>{name}</Typography>;
}
