import React, { useMemo } from 'react';

import GradientChromatographyActionsDialog from 'client/app/components/Parameters/ChromatographyActions/GradientChromatographyActionsDialog';
import { AppIcon } from 'client/app/icons';
import { pluralize } from 'common/lib/format';
import { GradientChromatographyAction } from 'common/types/robocolumns';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

type Props = {
  onChange: (newValue?: Record<string, GradientChromatographyAction>) => void;
  elementInstanceId?: string;
} & ParameterEditorBaseProps<Record<string, GradientChromatographyAction>>;

/**
 * Opens a dialog to configure the gradient chromatography actions, which
 * defines the liquid to load into a robocolumn N times to produce N fractions
 * of increasing concentration of a buffer.
 */
export default function GradientChromatographyActionsLayoutEditor({
  value,
  onChange,
  isDisabled,
  elementInstanceId,
}: Props) {
  const label = useMemo(
    () =>
      value ? pluralize(Object.keys(value).length, 'chromatography action') : undefined,
    [value],
  );

  const dialogProps = useMemo(
    () => ({ value, isDisabled, elementInstanceId }),
    [elementInstanceId, isDisabled, value],
  );

  const placeholder = isDisabled ? 'None Configured' : 'Configure';

  return (
    <SelectFromDialogButton
      selectedValueLabel={label}
      value={value}
      icon={<AppIcon iconId="antha:plate" />}
      dialog={GradientChromatographyActionsDialog}
      dialogProps={dialogProps}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}
