import { useCallback, useMemo } from 'react';

import { ApolloClient, useApolloClient, useQuery } from '@apollo/client';
import { Reference } from '@apollo/client/cache';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  MUTATION_CREATE_PLATE_TYPE,
  MUTATION_DELETE_PLATE_TYPE,
  MUTATION_UPDATE_PLATE_TYPE,
} from 'client/app/api/gql/mutations';
import {
  Fragments,
  QUERY_ALL_DEVICES,
  QUERY_LIST_ALL_PLATE_TYPES,
  QUERY_PLATE_TYPE,
} from 'client/app/api/gql/queries';
import splitFullPlateName from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { ArrayElement, PlateTypesQuery } from 'client/app/gql';
import * as xhr from 'client/app/lib/xhr';
import { indexBy } from 'common/lib/data';
import {
  PlateType,
  wellBottomShapeFromString,
  WellShape,
  wellShapeFromString,
} from 'common/types/plateType';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';

const PARSE_LHR_FILE_URL = '/appserver/parselhrfile';

// Not fetched yet
let cached: PlateType[] | null = null;

// Cache all plate types for the whole lifetime of the app. Plates types almost
// never change so we can do this.
export function useLoadAllCached() {
  const loadAll = useLoadAll();
  return useCallback(
    async function loadAllCached(): Promise<PlateType[]> {
      if (!cached) {
        cached = await loadAll();
      }
      return cached;
    },
    [loadAll],
  );
}

export function useLoadAll() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, loadAll);
}

export function useCreateFromFile() {
  const postJSON = xhr.usePostJSON();
  return useCallback(
    async function createFromFile(file: {
      filename: string;
      content: string;
    }): Promise<PlateType> {
      const { filename, content } = file;
      const fileType = (filename.match(/[^.]*$/) || ['unknown'])[0];
      const fileObj = {
        data: btoa(content),
        file_type: fileType,
        filename,
      };

      const rawPlate = await postJSON(PARSE_LHR_FILE_URL, { body: fileObj });
      return toPlateType(rawPlate);
    },
    [postJSON],
  );
}

async function loadAll(apollo: ApolloClient<object>): Promise<PlateType[]> {
  const { errors, data } = await apollo.query({
    query: QUERY_LIST_ALL_PLATE_TYPES,
    fetchPolicy: 'network-only',
  });
  if (errors || !data) {
    const errorMessage = errors ? '\n' + errors.map(e => e.message).join('\n') : '';
    throw new Error(`Failed to fetch plate types. ${errorMessage}`);
  }
  return data.plateTypes.map(plateType => toPlateType(plateType.plate));
}

async function deletePlateType(
  apollo: ApolloClient<object>,
  plateType: string,
): Promise<string> {
  const deleteResult = await apollo.mutate({
    mutation: MUTATION_DELETE_PLATE_TYPE,
    variables: {
      type: plateType,
    },
    refetchQueries: [
      {
        query: QUERY_LIST_ALL_PLATE_TYPES,
      },
    ],
  });

  return getResultOrThrow(
    deleteResult,
    'Delete plate type',
    data => data.deletePlateType!.type,
  );
}
export function useDeletePlateType() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, deletePlateType);
}

async function create(apollo: ApolloClient<object>, plateType: PlateType) {
  const residualVolumes = await setResidualVolumes(apollo, plateType);

  const createResult = await apollo.mutate({
    mutation: MUTATION_CREATE_PLATE_TYPE,
    variables: {
      ...plateType,
      residualVolumes: residualVolumes,
    },
    update(cache, response) {
      const newPlateType = response.data?.createPlateType?.plateType;
      cache.modify({
        fields: {
          plateTypes(existingPlateTypes: Reference[] = []) {
            const newPlateTypRef = cache.writeFragment({
              data: newPlateType,
              fragment: Fragments.PlateType,
              fragmentName: 'PlateTypeFragment',
            });
            return [...existingPlateTypes, newPlateTypRef];
          },
        },
      });
    },
  });

  return getResultOrThrow(
    createResult,
    'Create plate type',
    data => data.createPlateType!.plateType!.id,
  );
}

export function useCreatePlateType() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, create);
}

async function update(apollo: ApolloClient<object>, plateType: PlateType) {
  const residualVolumes = await setResidualVolumes(apollo, plateType);

  const result = await apollo.mutate({
    mutation: MUTATION_UPDATE_PLATE_TYPE,
    variables: {
      ...plateType,
      residualVolumes: residualVolumes,
    },
    update(cache, response) {
      const newPlateType = response.data?.updatePlateType?.plateType;
      cache.modify({
        fields: {
          plateTypes(existingPlateTypes: Reference[] = [], { readField }) {
            // plate types are based on their types, not their ID that is used only for internal purpose
            // therefore the update has created a new id that we need to add
            // and we must invalidate the old id too
            const newPlateTypRef = cache.writeFragment({
              data: newPlateType,
              fragment: Fragments.PlateType,
              fragmentName: 'PlateTypeFragment',
            });
            const cleanedPlateTypes = existingPlateTypes.filter(
              existingPlateType => plateType.id !== readField('id', existingPlateType),
            );
            return [...cleanedPlateTypes, newPlateTypRef];
          },
        },
      });
    },
  });

  return getResultOrThrow(
    result,
    'Update plate type',
    data => data.updatePlateType!.plateType!.id,
  );
}

export function useUpdatePlateType() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, update);
}

// set the residual volume values for every existing device models, based on the single entry passed in the 'defaultResidualVolume' field
// TODO: A UI change is required to show residual volumes per device model to avoid this forced conversion
async function setResidualVolumes(
  apollo: ApolloClient<object>,
  plateType: PlateType,
): Promise<{ [key: string]: number }> {
  const deviceModels = await getDeviceModels(apollo);
  const residualVolumes: { [key: string]: number } = {};
  for (const deviceModel of deviceModels) {
    residualVolumes[deviceModel] = plateType.defaultResidualVolume;
  }
  return residualVolumes;
}

async function getDeviceModels(apollo: ApolloClient<object>): Promise<Set<string>> {
  const { errors, data } = await apollo.query({
    query: QUERY_ALL_DEVICES,
    fetchPolicy: 'network-only',
  });
  if (errors || !data) {
    const errorMessage = errors ? '\n' + errors.map(e => e.message).join('\n') : '';
    throw new Error(`Failed to all the devices to list the models. ${errorMessage}`);
  }

  const models = data.devices.map(device => device.model.name);
  return new Set(models);
}

type GraphQLPlateType = ArrayElement<PlateTypesQuery['plateTypes']>['plate'];

function toPlateType(pt: GraphQLPlateType): PlateType {
  // we currently have no real display of the residual volume mapping
  // but as these values are always the same per device, we can pick any value as the 'global' one anyway.
  const residualVolumeKeys = Object.keys(pt.residualVolumes);
  if (!pt.wellShape) {
    throw new Error(`Plate type ${pt.type} does not have well shape details`);
  }

  // check for the maximum volume existence, this is currently required as we do not yet support computing volumes from geometries
  if (!pt.wellShape.volumeOverrideUl) {
    throw new Error(`Plate type ${pt.type} does not have a well volume`);
  }

  const wellShape: WellShape = {
    type: wellShapeFromString(pt.wellShape.type),
    bottomType: wellBottomShapeFromString(pt.wellShape.bottomType),
    volumeOverrideUl: pt.wellShape.volumeOverrideUl,
    dimensionMm: pt.wellShape.dimensionMm,
  };

  return {
    id: pt.id,
    type: pt.type,
    name: pt.name,
    columns: pt.columns,
    rows: pt.rows,
    usage: pt.usage ?? '',
    accessory: pt.accessory ?? '',
    manufacturer: pt.manufacturer ?? '',
    catalogNumber: pt.catalogNumber ?? '',
    catalogUrl: pt.catalogUrl ?? '',
    color: pt.color ?? '',
    description: pt.description ?? '',
    dimension: pt.dimension,
    format: pt.format ?? '',
    wellStart: pt.wellStart,
    wellOffset: pt.wellOffset,
    wellBottomOffset: pt.wellBottomOffset ?? 0,
    defaultResidualVolume:
      residualVolumeKeys.length > 0 ? pt.residualVolumes[residualVolumeKeys[0]] : 0,
    residualVolumes: pt.residualVolumes,
    version: pt.version,
    wellShape,
    editable: pt.isOrganisationSpecific, // non organisation specific plate types are shared between orgs so should not be updated from the UI
    containerType: pt.containerType,
    coverType: pt.coverType,
    contentSource: pt.contentSource,
  };
}

export function useAllPlates(deviceIds?: DeviceId[]): {
  allPlates: PlateType[];
  loading: boolean;
} {
  const { loading, data: allPlatesData } = useQuery(QUERY_LIST_ALL_PLATE_TYPES, {
    variables: { deviceIds: deviceIds },
  });
  const allPlates = useMemo(() => {
    const plateTypes = allPlatesData?.plateTypes ?? [];
    return Object.values(plateTypes).map(plate => toPlateType(plate.plate));
  }, [allPlatesData?.plateTypes]);

  return { allPlates, loading };
}

export type PlateTypeByTypeName = (typeName: string) => PlateType;

export function usePlatesByType(deviceIds?: DeviceId[]): [PlateTypeByTypeName, boolean] {
  const { loading, allPlates } = useAllPlates(deviceIds);

  const plateMap: { [plateName: string]: PlateType } = useMemo(
    () => indexBy(allPlates, 'type'),
    [allPlates],
  );
  const platesByType = useCallback(
    (fullPlateName: string) => {
      const [plateTypeName] = splitFullPlateName(fullPlateName);
      const plateType = plateMap[plateTypeName];
      if (!plateType) {
        throw new Error(
          `no known plate with type '${fullPlateName}' / '${plateTypeName}'`,
        );
      }
      return plateType;
    },
    [plateMap],
  );
  return [platesByType, loading];
}

type PlateTypeByID = (plateID: string) => PlateType;

export function usePlatesByID(deviceIds?: DeviceId[]): [PlateTypeByID, boolean] {
  const { loading, allPlates } = useAllPlates(deviceIds);
  const plateMap: { [plateId: string]: PlateType } = useMemo(
    () => indexBy(allPlates, 'id'),
    [allPlates],
  );
  const platesByID = useCallback(
    (plateID: string) => {
      const plateType = plateMap[plateID];
      if (!plateType) {
        throw new Error(`no known plate with id ${plateID}`);
      }
      return plateType;
    },
    [plateMap],
  );
  return [platesByID, loading];
}

export type PlateTypeWithCompatibility = {
  compatibility: PlateTypesQuery['plateTypes'][number]['compatibility'];
  plate: PlateType;
};

export function usePlatesWithCompatibility(
  deviceIds?: DeviceId[],
): [PlateTypeWithCompatibility[], boolean] {
  const { loading: isInitialLoading, data: allPlatesData } = useQuery(
    QUERY_LIST_ALL_PLATE_TYPES,
    { variables: { deviceIds: deviceIds } },
  );

  const transformedPlates = useMemo(
    () =>
      allPlatesData?.plateTypes.map<PlateTypeWithCompatibility>(
        plateTypeWithCompatibility => ({
          compatibility: plateTypeWithCompatibility.compatibility,
          plate: toPlateType(plateTypeWithCompatibility.plate),
        }),
      ) ?? [],
    [allPlatesData?.plateTypes],
  );

  return [transformedPlates, isInitialLoading];
}

export function usePlateType(plateType: string) {
  const { data } = useQuery(QUERY_PLATE_TYPE, {
    variables: { plateType },
  });

  return useMemo(() => {
    if (data?.plateType) {
      return { plateType: toPlateType(data.plateType), loading: false } as const;
    }
    return { plateType: undefined, loading: true } as const;
  }, [data?.plateType]);
}
