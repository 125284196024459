/**
 * Utility functions for chromatography
 *
 * TODO: These conversions exist in core antha, so we should investigate using
 * those in future.
 */

import { isCompatible } from 'common/lib/units';
import { FilterMatrix, Measurement } from 'common/types/mix';

/**
 * Radius of robocolumns in CM. Repligen report that Robocolumns are 0.25cm
 * radius, but we've found the calculations are more accurate with 0.2524cm
 *
 * The Tecan liquid class with the highest flow rate is
 * `Flowrate_1000cm/h_55.6ul/s`, i.e. a flow velocity of 1000cm/h and flow rate
 * of 55.6ul/s. We can deduce the robocolumn radius as follows:
 *
 * Radius[cm] = Sqrt(FlowRate[ul/s] * 3600 / 1000 / Pi / FlowVelocity[cm/h])
 *            = Sqrt(55.6 * 3600 / 1000 / Pi / 1000)
 *            = 0.2524cm
 */
const robocolumnRadius = 0.2524;
const robocolumnCrossSectionArea = Math.PI * robocolumnRadius ** 2;

/**
 * ColumnVolumeUnit expresses a volume relative to a robocolumn's volume
 */
const ColumnVolumeUnit = 'CV';

/**
 * Convert a residence time to a volumetric flow rate (volume per time), based
 * on the volume of a column. This assumes that flux in = flux out, i.e.,
 * equilibrium conditions in the column.
 *
 * Flow Rate = Column Volume / Residence Time
 */
export function residenceTimeToFlowRate(
  residenceTime?: Measurement,
  colVolume?: Measurement,
): Measurement | undefined {
  if (!residenceTime || !colVolume) {
    return;
  }
  if (!isCompatible('s', residenceTime.unit)) {
    return;
  }
  const timeValueInSeconds =
    residenceTime.unit === 'min' ? residenceTime.value * 60 : residenceTime.value;

  return { value: colVolume.value / timeValueInSeconds, unit: `${colVolume.unit}/s` };
}

/**
 * Convert a volumetric flow rate (volume per time) to a residence time, based
 * on the volume of a column. This assumes that flux in = flux out, i.e.,
 * equilibrium conditions in the column.
 */
export function flowRateToResidenceTime(
  flowRate?: Measurement,
  colVolume?: Measurement,
): Measurement | undefined {
  if (!flowRate || !colVolume) {
    return;
  }
  const [volumeUnit, timeUnit] = flowRate.unit.split('/');
  // If the units of flow rate and volume are not compatible then return
  // undefined.
  if (timeUnit !== 's' || volumeUnit !== colVolume.unit) {
    return;
  }
  return { value: colVolume.value / flowRate.value, unit: 's' };
}

/**
 * Convert residence time (s) to flow velocity (cm/h)
 */
export function residenceTimeToFlowVelocity(
  residenceTime?: Measurement,
  colVolume?: Measurement,
): Measurement | undefined {
  const flowRate = residenceTimeToFlowRate(residenceTime, colVolume);
  if (flowRate?.unit.toLowerCase() !== 'ul/s') {
    return;
  }
  const flowRateUlPerH = flowRate.value * 3600; // convert to ul/h
  const flowRateCM3PerH = flowRateUlPerH / 1000; // convert to cm3/h
  return { value: flowRateCM3PerH / robocolumnCrossSectionArea, unit: 'cm/h' }; // convert to cm/h
}

/**
 * Convert flow velocity (cm/h) to residence time (s).
 */
export function flowVelocityToResidenceTime(
  flowVelocity?: Measurement,
  colVolume?: Measurement,
): Measurement | undefined {
  if (flowVelocity?.unit.toLowerCase() !== 'cm/h') {
    return;
  }
  const flowVelocityCMPerS = flowVelocity.value / 3600; // convert to cm/s
  const flowRateCM3PerS = flowVelocityCMPerS * robocolumnCrossSectionArea; // convert to cm3/s
  const flowRate = { value: flowRateCM3PerS * 1000, unit: 'ul/s' }; // convert to ul/s
  return flowRateToResidenceTime(flowRate, colVolume);
}

/**
 * Calculates a column volume from a volume-like string (ul, ml, CV etc) and a
 * robocolumn's volume (i.e. ul)
 */
export function toColumnVolume(
  loadVolume?: Measurement,
  robocolumnVolume?: Measurement,
): Measurement | undefined {
  if (loadVolume && loadVolume.unit === ColumnVolumeUnit) {
    return loadVolume; // already in correct column volumes
  }
  if (
    !robocolumnVolume ||
    !loadVolume ||
    !isCompatible('l', loadVolume.unit) ||
    !isCompatible('l', robocolumnVolume.unit) ||
    !robocolumnVolume.value
  ) {
    return;
  }
  return { value: loadVolume.value / robocolumnVolume.value, unit: ColumnVolumeUnit };
}

/**
 * Calculates a volume from a volume-like measurement (ul, ml, CV etc) and a
 * robocolumn's volume (i.e. ul)
 */
export function fromColumnVolume(
  loadVolume?: Measurement,
  robocolumnVolume?: Measurement,
): Measurement | undefined {
  if (loadVolume && isCompatible('l', loadVolume.unit)) {
    return loadVolume; // already in correct volume units
  }
  if (
    !robocolumnVolume ||
    !loadVolume ||
    !isCompatible('l', robocolumnVolume.unit) ||
    loadVolume.unit !== ColumnVolumeUnit
  ) {
    return;
  }
  return {
    value: loadVolume.value * robocolumnVolume.value,
    unit: robocolumnVolume.unit,
  };
}

/**
 * divides a by b if both are non-zero CV measurement
 */
export function divideColumnVolumes(
  a?: Measurement,
  b?: Measurement,
): number | undefined {
  const aValue = getColumnVolumeValue(a);
  const bValue = getColumnVolumeValue(b);
  if (!aValue || !bValue) {
    return undefined;
  }
  return aValue / bValue;
}

/**
 * Try to describe individual load volumes in terms of a total load volume and a
 * fraction that can be used to divide the total to get the original individual
 * load volumes.
 *
 * @example
 *  - [1.0, 1.0, 1.0, 0.5] -> fraction: 1.0; total: 3.5
 *  - [1.8,           3.2] -> fraction: 3.2; total: 5.0
 *  - [1.0, 4.0, 3.0, 0.5] -> undefined since expecting [1.0, 1.0, 1.0, 0.5]
 *  - [1.0, 1.0, 0.5, 1.0] -> undefined since expecting [1.0, 1.0, 1.0, 0.5]
 *
 * @remarks
 * notice we expect a contiguous series of fractional values with only one
 * non-fractional remainder. This is intentional to enable faithful recovery of
 * individual load volumes
 */
export function loadVolumesToTotalAndFractionVolume(loadColumnVolumes: number[] = []) {
  const volumes = [...loadColumnVolumes]; // work on a copy as we modify in place
  const total = volumes.reduce((a, b) => a + b, 0);
  const start = volumes.shift();
  const end = volumes.pop() || 0;
  const fraction = !start ? 0 : start > end ? start : end;
  const isFeasibleConversion =
    start !== undefined && volumes.every(volume => volume === fraction);

  return isFeasibleConversion ? { total, fraction } : undefined;
}

/**
 * Calculate the individual load volumes from a total load volume and a
 * fractional volume that the total must fit within, otherwise it should result
 * in additional load volumes
 *
 * @example
 *  - fraction: 1.0; total: 3.5 -> [1.0, 1.0, 1.0, 0.5]; numFractions: 3.5
 *  - fraction: 3.2; total: 5.0 -> [3.2,           1.8]; numFractions: 1.5625
 *  - fraction: 5.0; total: 3.2 -> [3.2,              ]; numFractions: 1
 */
export function totalAndFractionVolumeToLoadVolumes(columnVolume: {
  total?: Measurement;
  fraction?: Measurement;
}): { loadColumnVolumes?: number[]; numFractions: number } {
  const total = getColumnVolumeValue(columnVolume.total);
  const fraction = getColumnVolumeValue(columnVolume.fraction);
  if (!total || !fraction) {
    return { numFractions: 0 };
  }

  const numFractions = total / fraction;
  const numWholeFractions = Math.trunc(numFractions);
  if (numWholeFractions === 0) {
    // then by definition all of the total can fit within the fraction
    return { loadColumnVolumes: [total], numFractions: 1 };
  }

  // if the remainingVolume is less than 0.01, it is less than 1% of a robocolumn
  // volume. The largest manufactured robocolumn volume is 600 ul and the
  // smallest is 100 ul. So values less than 0.01 mean a transfer of sub-6 or
  // 1ul, which is not a meaningful transfer in a robocolumn experiment. So we round
  // here to this level allowing us to remove floating point noise in calculations
  const volumes = new Array(numWholeFractions).fill(fraction);
  const remainingVolume = +((numFractions - numWholeFractions) * fraction).toFixed(2);
  return remainingVolume !== 0
    ? { loadColumnVolumes: [...volumes, remainingVolume], numFractions }
    : { loadColumnVolumes: volumes, numFractions: numWholeFractions };
}

function getColumnVolumeValue(m?: Measurement) {
  const isValid = m && m.unit === ColumnVolumeUnit;
  return isValid ? m.value : undefined;
}

/**
 * A RoboColumn (well with kind = "filter_matrix_summary") will always have a Resin Type
 * tag.
 * TODO: Change to roboColumn.resinName when T4141 is solved
 */
export function getResinName(roboColumnContents: FilterMatrix): string {
  return (
    roboColumnContents.tags?.find(tag => tag.label === 'Resin Type')?.value_string || ''
  );
}

export const ROBOCOLUMN_LOCATIONS_TYPE =
  'map[github.com/Synthace/antha/stdlib/schemas/aliases.RoboColumnName]github.com/Synthace/antha/stdlib/platepreferences.WellLocations';
