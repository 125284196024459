import React from 'react';

import { styled } from '@mui/material/styles';

import LiquidsLayoutEditor from 'client/app/components/Parameters/PlateLayout/LiquidsLayoutEditor';
import MixturesPreview from 'client/app/components/Parameters/PlateLayout/MixturesPreview';
import PlateLayoutEditorContextProvider, {
  usePlateLayoutEditorContext,
} from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorProvider';
import { PlateNamesWithAssignment } from 'common/types/plateAssignments';
import Button from 'common/ui/components/Button';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

type Props = {
  value: PlateNamesWithAssignment;
  onChange: (newValue: PlateNamesWithAssignment) => void;
  isDisabled: boolean;
  hasLiquidsInPlate: boolean;
  elementInstanceId: string;
  onClose?: () => void;
};

export enum MainPanelTab {
  LAYOUT = 'layout',
  PREVIEW = 'preview',
}

const TABS: TabsInfo<MainPanelTab> = [
  { value: MainPanelTab.LAYOUT, label: 'Mix Layout' },
  {
    value: MainPanelTab.PREVIEW,
    label: 'Mixtures Preview',
  },
];

export default function PlateLayoutEditorContentsWithContext({
  onClose,
  ...props
}: Props) {
  return (
    <PlateLayoutEditorContextProvider {...props}>
      <PlateLayoutEditorContents onClose={onClose} />
    </PlateLayoutEditorContextProvider>
  );
}

function PlateLayoutEditorContents({ onClose }: { onClose?: () => void }) {
  const { activeMainTab, setActiveMainTab } = usePlateLayoutEditorContext();
  return (
    <>
      <TitleBar>
        <Tabs
          tabsInfo={TABS}
          activeTab={activeMainTab}
          onChangeTab={id => setActiveMainTab(id)}
        />
        {onClose && (
          <Button size="small" onClick={onClose} variant="tertiary" color="primary">
            Done
          </Button>
        )}
      </TitleBar>
      {activeMainTab === MainPanelTab.PREVIEW ? (
        <MixturesPreviewTab />
      ) : (
        <LiquidsLayoutEditor />
      )}
    </>
  );
}

const TitleBar = styled('div')(({ theme }) => ({
  gridArea: 'titlebar',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

const MixturesPreviewTab = styled(MixturesPreview)({
  gridArea: 'main',
  placeSelf: 'stretch',
});
