import React, { useCallback, useMemo, useState } from 'react';

import MUIAutocomplete from '@mui/material/Autocomplete';
import cx from 'classnames';

import { indexBy } from 'common/lib/data';
import { Option } from 'common/types/Option';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import Keys from 'common/ui/lib/keyboard';

export type { Option } from 'common/types/Option';

type Props<T> = {
  valueLabel?: string | null;
  options: Option<T>[];
  onChange: (value?: T) => void;
  onBlur?: () => void;
  placeholderValue?: T;
  /**
   * Allow the user to provide values outside of the options list.
   * This can only be used with string options and the label and value of
   * the custom option will be equal to what the user enters.
   * */
  acceptCustomValues?: boolean;
  disableClearable?: boolean;
  label?: string;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  className?: string;
} & Omit<ParameterEditorBaseProps<T>, 'value'>;

export default function Autocomplete<T>(props: Props<T>) {
  const classes = useStyles();
  const labelOptionMap = useMemo(() => indexBy(props.options, 'label'), [props.options]);
  const optionLabels = useMemo(() => Object.keys(labelOptionMap), [labelOptionMap]);
  const { valueLabel: value, acceptCustomValues } = props;

  if (
    acceptCustomValues &&
    props.options[0] &&
    typeof props.options[0]?.value !== 'string'
  ) {
    throw new Error(
      'Autocomplete error: You cannot set canAcceptCustomValues to true and provide non-string options simultaneously. Either use string values or disable canAcceptCustomValues.',
    );
  }

  const [inputValue, setInputValue] = useState(value ?? '');

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: string | null) => {
      const newLabel = newValue || '';

      // always update text seen by user
      setInputValue(newLabel);

      // only allow partially entered text if parent allows it
      const option = labelOptionMap[newLabel];
      if (acceptCustomValues && option === undefined) {
        props.onChange(newLabel as unknown as T);
        return;
      }

      // otherwise require it to be a valid option
      if (option) {
        props.onChange(option.value);
      }
    },
    [acceptCustomValues, labelOptionMap, props],
  );

  const handlePressEnter = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== Keys.ENTER) {
      return;
    }

    e.currentTarget.blur();
  }, []);

  return (
    <MUIAutocomplete
      inputValue={inputValue}
      onInputChange={onInputChange}
      freeSolo={acceptCustomValues}
      options={optionLabels}
      autoSelect={false}
      onBlur={props.onBlur}
      disabled={props.isDisabled}
      disableClearable={props.disableClearable}
      className={cx(props.className, { [classes.fullWidth]: props.fullWidth })}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option}>
            {option}
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          error={props.hasError}
          placeholder={props.placeholder}
          label={props.label}
          required={props.isRequired}
          inputProps={{
            ...params.inputProps,
            // Chrome does not support autoComplete="off". Setting autocomplete
            // value to any other string will prevent Chrome from autofilling
            // inputs with personal information the browser saves. (T4858)
            autoComplete: 'new-password',
            onKeyDown: handlePressEnter,
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: props.disableUnderline,
          }}
        />
      )}
    />
  );
}

const useStyles = makeStylesHook({
  fullWidth: {
    width: '100%',
  },
});
