import React, { useCallback } from 'react';

import { DevicePlateTypeItem } from 'client/app/components/Parameters/DeviceConfigurator/DevicePlateTypeSelector/DevicePlateTypeItem';
import ArrayEditor from 'common/ui/components/ParameterEditors/ArrayEditor';

type Props = {
  value?: string[];
  onChange: (plateTypeNames?: string[]) => void;
  isDisabled?: boolean;
};

export default function DevicePlateTypeSelector({
  value: plateTypeNames = [],
  onChange: handlePlateTypesChange,
  isDisabled = false,
}: Props) {
  const handlePlateTypeDelete = useCallback(
    (index: number) => handlePlateTypesChange(plateTypeNames.toSpliced(index, 1)),
    [handlePlateTypesChange, plateTypeNames],
  );

  return (
    <ArrayEditor
      anthaType=""
      component={DevicePlateTypeItem}
      value={plateTypeNames}
      onChange={handlePlateTypesChange}
      onItemDelete={handlePlateTypeDelete}
      overrideAddNewItemCopy="Add plate type"
      isDisabled={isDisabled}
    />
  );
}
