import React from 'react';

/**
 * One link to point to the Liquid Policies Intercom article.
 */
export default function LiquidPolicyIntercomLink() {
  return (
    <a
      href="https://intercom.help/antha/en/articles/10495339-the-liquid-policy-inventory"
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginLeft: 2 }}
    >
      here
    </a>
  );
}
