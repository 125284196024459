/**
 * Check if an element is a text or search input (such as `<input type='text' />`).
 *
 * Accepts an `EventTarget` which makes this especially useful for testing targets
 * of browser events. Example usage:
 *
 * ```
 * handleKeyDown = (e: KeyboardEvent) => {
 *   if (isInteractiveInput(e.target)) { ... }
 * ```
 */
export function isInteractiveInput(targetElement: EventTarget | null): boolean {
  if (!(targetElement instanceof HTMLElement)) {
    // If it's not a HTML element, it's not an input
    return false;
  }
  const isInput = targetElement.tagName.toLowerCase() === 'input';
  const isTextBox = targetElement.role === 'textbox';
  const type = targetElement?.attributes.getNamedItem('type');
  const isTypeText = type?.value === 'text';
  const isTypeSearch = type?.value === 'search';

  return isTextBox || (isInput && (isTypeText || isTypeSearch));
}
