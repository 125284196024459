import React from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button from 'common/ui/components/Button';
import IconWithPopover from 'common/ui/components/IconWithPopover';

type Props = {
  numWellsSelected: number;
  onCreateRegion: () => void;
  onClearSelection: () => void;
  onSelectAll: () => void;
  isDisabled: boolean;
};

export function WellSelectionActions({
  numWellsSelected,
  onCreateRegion,
  onClearSelection,
  onSelectAll,
  isDisabled,
}: Props) {
  return (
    <Container>
      <WellSelectionHelperText />
      <Stack direction="row" spacing={3}>
        <Button
          variant="secondary"
          onClick={numWellsSelected ? onClearSelection : onSelectAll}
          disabled={isDisabled}
        >
          {numWellsSelected ? 'Clear All Selections' : 'Select All'}
        </Button>
        <Button
          onClick={onCreateRegion}
          variant="secondary"
          color="primary"
          startIcon={<AddOutlinedIcon />}
          disabled={isDisabled || numWellsSelected === 0}
        >
          Assign Region
        </Button>
      </Stack>
    </Container>
  );
}

function WellSelectionHelperText() {
  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <Typography variant="body2" color="textPrimary">
        Select wells on the plate to create a well set.
      </Typography>
      <IconWithPopover
        icon={<HelpIcon color="inherit" />}
        popoverContent={
          <>
            <Typography variant="caption">
              The numbering of wells determines the order in which liquids (or groups of
              liquids) will be arranged on the final plate.
            </Typography>
            <br />
            <Typography variant="caption">
              <strong>Note:&nbsp;</strong>this may be different to the order in which
              Synthace dispenses liquids, which aims to maximise multichannel pipetting.
            </Typography>
          </>
        }
      />
    </Stack>
  );
}

const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  marginBottom: theme.spacing(5),
}));
