// We don't want the wellSelector (and plate) to shrink down so much that it is so small to use.
// These values are the minimum dimensions associated with some of our common plates
// in SBS format, so should be generalizable enough for us to set here to ensure we don't shrink
// the plate too much.
export const MINIMUM_PLATE_WIDTH = 383;
export const MINIMUM_PLATE_HEIGHT = 256;

// Width of the plate contents list.
export const CONTENTS_LIST_WIDTH = 252;

// If the panel is minimized we want to wrap the contents.
export const PANEL_WRAP_WIDTH = MINIMUM_PLATE_WIDTH + CONTENTS_LIST_WIDTH;
