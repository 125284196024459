import { formatWellPosition } from 'common/lib/format';
import { PlateType } from 'common/types/plateType';

export function getOptions<T extends string>(v: readonly T[]) {
  return Object.values(v).map(v => ({ label: v, value: v }));
}

export function getAllWells(plateType?: PlateType) {
  if (!plateType) return [];
  const allWells = [];
  for (let row = 0; row < plateType.rows; row++) {
    for (let column = 0; column < plateType.columns; column++) {
      allWells.push(formatWellPosition(row, column));
    }
  }
  return allWells;
}
