import React, { useCallback, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';

import PlateLayoutEditorPanel from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorPanel';
import { defaultPlateAssignment } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorProvider';
import { useInputPlates } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { PlateLayoutEditorAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { PlateAssignment, PlateNamesWithAssignment } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { useArrayEditorItemContext } from 'common/ui/components/ParameterEditors/ArrayEditor';
import TextField from 'common/ui/filaments/TextField';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

type Props = {
  value: PlateNamesWithAssignment | null;
  onChange: (param: PlateNamesWithAssignment | null, instanceName?: string) => void;
  isDisabled: boolean;
  configuration?: PlateLayoutEditorAdditionalProps;
  elementInstanceId?: string;
};

/**
 * PlateLayoutParameter represents the plate name and button that launches the
 * PlateLayoutContents in a panel or dialog
 *
 * Dependencies:
 *  - AdditionalPanelContext to render more content
 *  - Array editor context. The parameter assumes it is rendered as part of an
 *    array of related parameters
 */
export default function PlateLayoutParameter({
  value,
  onChange,
  isDisabled,
  configuration,
  elementInstanceId,
}: Props) {
  const { setAdditionalPanel, clearAdditionalPanel, additionalPanelId } =
    useAdditionalPanelContext();
  const { allItems, index } = useArrayEditorItemContext<PlateNamesWithAssignment>();
  const isActive = additionalPanelId === value?.id;

  const isDuplicate = useMemo(
    () =>
      !!value &&
      allItems.some(
        (item, i) => item?.plateNames.includes(value.plateNames[0]) && i < index,
      ),
    [allItems, index, value],
  );

  const handleClick = () => {
    if (elementInstanceId && value?.plateNames.length) {
      setAdditionalPanel({
        id: value.id,
        contents: (
          <PlateLayoutEditorPanel
            value={value}
            onChange={onChange}
            onClose={clearAdditionalPanel}
            hasLiquidsInPlate={!configuration?.plateOptionsInput}
            elementInstanceId={elementInstanceId}
            isDisabled={isDisabled}
          />
        ),
      });
    }
  };

  useEffect(() => {
    if (value && !value.id) {
      onChange({
        ...value,
        id: uuid(),
      });
    }
  }, [onChange, value]);

  // if the number of items in array change, then the user is either:
  //  1. creating a new item, in which case the current editor is unimportant
  //  2. deleting an item. Ideally we would only close the editor if it is the
  //     active parameter's item that is deleted, but it's hard to determine
  //     this without far more context. So take the simpler option and close the editor
  useEffect(() => {
    clearAdditionalPanel();
    return () => {
      // always close if it's the last item being unmounted
      allItems.length === 1 && clearAdditionalPanel();
    };
  }, [allItems.length, clearAdditionalPanel]);

  return (
    <Wrapper>
      {elementInstanceId && configuration?.plateOptionsInput ? (
        // TODO: Limit which plates can be selected so only those of the same type can be chosen.
        <SelectPlates
          elementInstanceId={elementInstanceId}
          value={value}
          isDisabled={isDisabled}
          plateOptionsInput={configuration.plateOptionsInput}
          onChange={onChange}
          allItems={allItems}
        />
      ) : (
        <PlateName
          value={value?.plateNames[0]}
          onChange={name => {
            onChange({
              id: value?.id ?? uuid(),
              plateNames: name ? [name] : [],
              plateAssignment: value?.plateAssignment ?? defaultPlateAssignment(),
            });
          }}
          isDisabled={isDisabled}
          isDuplicate={isDuplicate}
        />
      )}
      <EditButton
        variant="secondary"
        disabled={
          value?.plateNames === undefined ||
          value.plateNames.length === 0 ||
          isDuplicate ||
          !elementInstanceId
        }
        onClick={handleClick}
        active={isActive}
      >
        <Typography variant="body2">Edit Mix Layout</Typography>
      </EditButton>
      {!elementInstanceId ? (
        <FormHelperText error>
          Cannot open editor unless element is selected
        </FormHelperText>
      ) : null}
    </Wrapper>
  );
}

function PlateName({
  isDisabled,
  value,
  onChange,
  isDuplicate,
}: {
  isDisabled?: boolean;
  value?: string;
  onChange: (value?: string) => void;
  isDuplicate: boolean;
}) {
  return (
    <>
      <TextField
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        placeholder="Plate Name"
        disabled={isDisabled}
        error={isDuplicate}
      />
      {isDuplicate ? <FormHelperText error>Duplicate plate name</FormHelperText> : null}
    </>
  );
}

function SelectPlates({
  elementInstanceId,
  isDisabled,
  value,
  plateOptionsInput,
  onChange,
  allItems,
}: {
  elementInstanceId: string;
  isDisabled?: boolean;
  value: PlateNamesWithAssignment | null;
  plateOptionsInput: string;
  onChange: (param: PlateNamesWithAssignment | null, instanceName?: string) => void;
  allItems: (PlateNamesWithAssignment | null)[];
}) {
  const { loading, inputPlates: plateOptions } = useInputPlates(
    elementInstanceId,
    plateOptionsInput,
  );

  const selectablePlates = useMemo(() => {
    if (plateOptions) {
      // Don't let the user select a plate that is already being mixed onto.
      let result = plateOptions.filter(
        option =>
          !allItems.some(
            item => item !== value && item?.plateNames.includes(option.value),
          ),
      );

      // Don't let the user select plates of a different type to mix onto.
      if (value?.plateAssignment.plateType) {
        result = result.filter(
          option => option.plateType === value.plateAssignment.plateType,
        );
      }

      return result;
    }

    return plateOptions;
  }, [allItems, plateOptions, value]);

  const noOptions = !selectablePlates || selectablePlates.length === 0;

  const onSelected = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      const plateName = Array.isArray(e.target.value)
        ? e.target.value[0]
        : e.target.value;
      const option = plateName
        ? plateOptions?.find(o => o.value === plateName)
        : undefined;

      if (option) {
        const plateAssignment: PlateAssignment = {
          ...defaultPlateAssignment(true),
          ...value?.plateAssignment,
          plateType: option.plateType,
        };

        onChange({
          id: value?.id ?? uuid(),
          plateNames: Array.isArray(e.target.value) ? e.target.value : [e.target.value],
          plateAssignment,
        });
      } else {
        onChange(null);
      }
    },
    [onChange, plateOptions, value?.id, value?.plateAssignment],
  );

  return (
    <Select
      multiple
      value={value?.plateNames ?? []}
      displayEmpty
      onChange={onSelected}
      size="small"
      renderValue={selected => (
        <MenuBox>
          {loading && noOptions ? (
            <Typography color="textSecondary">Loading...</Typography>
          ) : !loading && noOptions ? (
            <Typography color="textSecondary">No plates found...</Typography>
          ) : selected.length === 0 ? (
            <Typography color="textSecondary">Plates to mix onto...</Typography>
          ) : (
            selected.map(value => <Chip key={value} label={value} size="small" />)
          )}
        </MenuBox>
      )}
      disabled={isDisabled || noOptions}
    >
      {selectablePlates?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const MenuBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

const EditButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})<{
  active: boolean;
}>(({ active, theme }) => ({
  padding: theme.spacing(3, 4),
  ...(active
    ? {
        background: Colors.BLUE_5,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      }
    : {}),
}));
