import React from 'react';

import TextField from '@mui/material/TextField';

import AdminFileUploadEditor from 'admin-client/app/components/ElementConfiguration/AdminFileUploadEditor';
// Unfortunately this is a huge tangled rabbit hole to unravel as MapEditor depends on many client components
// eslint-disable-next-line no-restricted-imports
import MapEditor from 'client/app/components/Parameters/MapEditor';
import {
  AdditionalEditorProps,
  ArrayAdditionalProps,
  MapAdditionalProps,
  MeasurementAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import canSpecifyDefaultValueForEditor from 'common/elementConfiguration/canSpecifyDefaultValueForEditor';
import { EditorType } from 'common/elementConfiguration/EditorType';
import Liquids from 'common/lib/liquids';
import { ParameterValue } from 'common/types/bundle';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import ArrayEditor from 'common/ui/components/ParameterEditors/ArrayEditor';
import CheckBoxEditor from 'common/ui/components/ParameterEditors/CheckBoxEditor';
import DOEMergeOptionDropdownEditor from 'common/ui/components/ParameterEditors/DOEMergeOptionDropdownEditor';
import FloatEditor from 'common/ui/components/ParameterEditors/FloatEditor';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import IntegerEditor from 'common/ui/components/ParameterEditors/IntegerEditor';
import PlateReaderShakingTypeDropdownEditor from 'common/ui/components/ParameterEditors/PlateReaderShakingTypeDropdownEditor';
import StringArrayEditor from 'common/ui/components/ParameterEditors/StringArrayEditor';
import StringMeasurementEditor from 'common/ui/components/ParameterEditors/StringMeasurementEditor';
import ToggleEditor from 'common/ui/components/ParameterEditors/ToggleEditor';
import Dropdown from 'common/ui/filaments/Dropdown';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  editorProps?: AdditionalEditorProps;
  anthaType: string;
  onChange: (param: ParameterValue) => void;
  editorType: EditorType;
} & ParameterEditorBaseProps<ParameterValue>;

const LIQUID_OPTIONS = Liquids.map(value => ({ label: value, value }));

const DefaultValueEditor = React.memo(function DefaultValueEditor(props: Props) {
  const onTextFieldChange = useTextFieldChange(props.onChange);
  if (!canSpecifyDefaultValueForEditor(props.editorType, props.editorProps)) {
    return null;
  }
  switch (props.editorType) {
    case EditorType.ARRAY: {
      const itemEditorProps = (props.editorProps as ArrayAdditionalProps)?.itemEditor;
      return (
        <ArrayEditor
          component={DefaultValueEditor}
          anthaType={props.anthaType}
          value={props.value || []}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          itemEditorProps={itemEditorProps}
        />
      );
    }
    case EditorType.MAP: {
      const { keyEditor, valueEditor } = (props.editorProps as MapAdditionalProps) ?? {};
      return (
        <MapEditor
          anthaType={props.anthaType}
          value={props.value || {}}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          keyEditorProps={keyEditor}
          valueEditorProps={valueEditor}
        />
      );
    }
    case EditorType.AUTOCOMPLETE:
    case EditorType.DROPDOWN:
    case EditorType.PLATE_TYPE:
    case EditorType.POLICY:
      return (
        <TextField
          value={props.value}
          onChange={onTextFieldChange}
          disabled={props.isDisabled}
          fullWidth
        />
      );
    case EditorType.CHECKBOX:
      return (
        <CheckBoxEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.DOE_MERGE_OPTION:
      return (
        <DOEMergeOptionDropdownEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.FILE:
      return (
        <AdminFileUploadEditor
          targetFolder="parameterDefaults"
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.FLOAT:
      return (
        <FloatEditor
          type={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
        />
      );
    case EditorType.INT:
      return (
        <IntegerEditor
          type={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
        />
      );
    case EditorType.LIQUID:
      return (
        <Dropdown
          options={LIQUID_OPTIONS}
          valueLabel={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.STRING:
      return (
        <GenericInputEditor
          type={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
        />
      );
    case EditorType.STRING_ARRAY:
      return (
        <StringArrayEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
        />
      );
    case EditorType.TOGGLE:
      return (
        <ToggleEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.PLATE_READER_SHAKING_TYPE:
      return (
        <PlateReaderShakingTypeDropdownEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.MEASUREMENT: {
      // for historic reasons measurements were serialised as string hence we
      // still use the StringMeasurementEditor
      const { units, defaultUnit } = props.editorProps as MeasurementAdditionalProps;
      return (
        <StringMeasurementEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          units={units}
          defaultUnit={defaultUnit}
          placeholder={props.placeholder}
        />
      );
    }
    default:
      // We want to keep editorTypesWithDefaultValues updated so that other parts
      // of the app can use it and we want it to stay in sync with this switch.
      // If somebody removes a case statement from the switch without also removing
      // the relevant editorType, it'll hit this point so we throw an error so this
      // gets rectified.
      throw new Error(
        `The ${props.editorType} editor is listed as having an associated default value editor in the editorTypesWithDefaultValues list, but none has been provided in <DefaultValueEditor/>. Either add its default value editor to the component or remove ${props.editorType} from editorTypesWithDefaultValues.`,
      );
  }
});

export default DefaultValueEditor;
