import React from 'react';

import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateTypeSelect } from 'client/app/components/Parameters/PlateType/PlateTypeSelect';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';

type Props = ParameterEditorBaseProps<string> & {
  onChange: (plateTypeName?: PlateParameterValue) => void;
};

export function DevicePlateTypeItem(props: Props) {
  return (
    <PlateSelectionEditor
      value={props.value}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      plateSelectors={[
        { Component: PlateTypeSelect, additionalProps: { plateMode: 'input' } },
      ]}
    />
  );
}
