import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { tooltipClasses } from '@mui/material/Tooltip';

import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { ConfiguredDevice } from 'common/types/bundle';
import { isDeviceThatCanPerformLiquidHandling } from 'common/types/bundleConfigUtils';
import Colors from 'common/ui/Colors';
import { usePopover } from 'common/ui/hooks/usePopover';
import useThrottle from 'common/ui/hooks/useThrottle';

export const DEFAULT_LAYOUT_LABEL = 'Default Layout';
export const DECK_LOCATION_CSS_CLASS = 'deckLocation';
export const SMALL_DECK_POSITION_SIZE = 100;

/*
 * We need to determine which device is the main device (which is assumed to be one liquid handler)
 * Currently we are assuming it is the main device if there is only one device selected, if the device is a Gilson,
 * has a run config already, has accessible device ids, or isLiquidHandlingDevice().
 *
 * We will be enforcing that you can only have one liquid handler device before you get to picking the run config.
 * Note: Device will always be selected first.
 */
export function getSelectedMainDevice(
  configuredDevices: ConfiguredDevice[],
  devices: DeviceCommon[],
) {
  if (configuredDevices.length === 1) {
    const selectedDevice = configuredDevices[0];

    if (isDeviceThatCanPerformLiquidHandling(selectedDevice)) {
      return {
        selectedDevice,
        selectedDeviceCommon: devices.find(
          device => device.id === selectedDevice.deviceId,
        ),
      };
    }
  } else {
    for (const configuredDevice of configuredDevices) {
      if (
        configuredDevice.runConfigId ||
        configuredDevice.accessibleDeviceConfigurationIds ||
        isDeviceThatCanPerformLiquidHandling(configuredDevice)
      ) {
        return {
          selectedDevice: configuredDevice,
          selectedDeviceCommon: devices.find(
            device => device.id === configuredDevice.deviceId,
          ),
        };
      }
    }
  }

  // happens when the user clears or deselects all devices
  return {
    selectedDevice: undefined,
    selectedDeviceCommon: undefined,
  };
}

/**
 * Returns all devices in the format of DeviceCommon[].
 */
export function useGetDeviceCommon(
  configuredDevices: ConfiguredDevice[],
): DeviceCommon[] {
  const { data } = useQuery(QUERY_ALL_DEVICES);
  const deviceIds = configuredDevices.map(cd => cd.deviceId);
  return data?.devices.filter(device => deviceIds.includes(device.id)) ?? [];
}

/**
 * Some plates are too small to show deck position name or any other extra information.
 * For this there is a tooltip to contain that information for smaller plates.
 * This function returns styles for this tooltip.
 */
export function getSmallDeckPositionTooltipStyles(
  isDeckPositionSelectable: boolean = false,
) {
  return {
    backgroundColor: Colors.GREY_0,
    border: `1px solid ${isDeckPositionSelectable ? Colors.BLUE_50 : Colors.GREY_30}`,
    padding: 4,

    [`& .${tooltipClasses.arrow}`]: {
      color: isDeckPositionSelectable ? Colors.BLUE_50 : Colors.GREY_0,
    },
  };
}

export function useDeckPositionTooltip() {
  const { isPopoverOpen, onShowPopover, onHidePopover } = usePopover();
  const [lock, setLock] = useState(false);

  return {
    open: !lock && isPopoverOpen,
    lock: () => setLock(true),
    unlock: () => setLock(false),
    show: (event: React.MouseEvent<HTMLElement>) => !lock && onShowPopover(event),
    hide: () => onHidePopover(),
    hideThrottled: useThrottle(onHidePopover, 100),
  };
}
