import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import {
  getPlateParameterValueAsString,
  PlateParameterValue,
} from 'client/app/components/Parameters/PlateType/processPlateParameterValue';

type Props = {
  nameWithRiser?: string;
  onChange: (nameWithRiser?: string) => void;
  isDisabled: boolean;
};

export function PlateSelector({ nameWithRiser, onChange, isDisabled }: Props) {
  const handleChange = useCallback(
    (v?: PlateParameterValue) => {
      const newNameWithRiser = v ? getPlateParameterValueAsString(v) : undefined;
      onChange(newNameWithRiser);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <Typography variant="subtitle2" noWrap>
        Plate type
      </Typography>
      <PlateSelectionEditor
        value={nameWithRiser}
        onChange={handleChange}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  width: '100%',
}));
