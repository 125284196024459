import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';

import {
  mapPlateWells,
  WellAvailability,
} from 'client/app/components/Parameters/PlateLayout/lib/WellAvailability';
import {
  createPlateName,
  LiquidNameOrGroupName,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { formatMeasurementObj } from 'common/lib/format';
import { WellCoord } from 'common/types/bundle';
import { Measurement, VolumeOrConcentration } from 'common/types/mix';
import {
  NamingMode,
  PlateAssignment,
  PlateAssignmentMode,
  PlateLayer,
  WellSortMode,
} from 'common/types/plateAssignments';
import { PlateType } from 'common/types/plateType';

export type CombinatorialLiquidAndTarget = {
  nameOrGroup: string;
  target: VolumeOrConcentration;
  isPartOfGroup?: boolean;
};
/**
 * Calculates the combinatorial mixes required to satisfy the liquids specified in plate
 * layout editor when in Combinatorial mode. Accounts for multiple plates.
 *
 * @returns A map of unique plate names and the PlateLayer contents for each plate.
 */
export function calculateCombinatorialLayouts(
  plateAssignment: PlateAssignment,
  plateType: PlateType,
  liquidNamesorGroupNames: LiquidNameOrGroupName[],
  plateName: string,
  wellAvailability?: WellAvailability,
): Map<string, PlateAssignment> {
  /**
   * This creates a 2D array, where each entry in the parent array
   * represents a layer, and each entry in the child array represents the
   * liquid.
   *
   * We handle groups by populating the child array with as many duplicates
   * of the liquid group to match the number of liquids in that group.
   *
   * e.g.
   * [
   * [{nameOrGroup: 'CellsA'}, {nameOrGroup: 'CellsB'}, {nameOrGroup: 'LiquidGroup'}, {nameOrGroup: 'LiquidGroup'}],
   * [{nameOrGroup: 'BufferGroup'}, {nameOrGroup: 'BufferGroup'}],
   * ]
   */
  const liquidNamesByLayer: CombinatorialLiquidAndTarget[][] = [];
  plateAssignment.plateLayers.forEach(layer => {
    if (layer.liquids.length > 0) {
      const liquidNamesToAdd: CombinatorialLiquidAndTarget[] = [];
      layer.liquids.forEach(liquid => {
        if (liquid.liquidName) {
          liquidNamesToAdd.push({
            nameOrGroup: liquid.liquidName,
            target: liquid.target,
          });
        } else if (liquid.liquidGroup) {
          const liquidsInGroup =
            liquidNamesorGroupNames.find(
              liquidOrGroup => liquidOrGroup.name === liquid.liquidGroup,
            )?.contents?.length ?? 1;
          liquidNamesToAdd.push(
            ...Array<CombinatorialLiquidAndTarget>(liquidsInGroup).fill({
              nameOrGroup: liquid.liquidGroup,
              target: liquid.target,
              isPartOfGroup: true,
            }),
          );
        }
      });
      liquidNamesByLayer.unshift(liquidNamesToAdd);
    }
  });

  const combinatorialMixes = generateCombinatorialMixes(
    liquidNamesByLayer,
    plateAssignment.replicates,
  );
  return generateLayersForPlate(
    combinatorialMixes,
    plateType,
    plateName,
    plateAssignment.replicates,
    plateAssignment.totalVolume,
    wellAvailability,
  );
}

/**
 * This creates a combinatorial mix (i.e. a cartesian product) of the liquids.
 *
 * @param liquids A 2D array, where each entry in the parent array
 * represents a layer, and each entry in the child array represents the liquid.
 *
 * @param replicates How many times each combinatorial mix should be present.
 *
 * @returns A 2D array in the form of a cartesian product, where each child array is a combination
 * of liquids. The number of each individual child array will be identical to replicates.
 */
export function generateCombinatorialMixes(
  liquids: CombinatorialLiquidAndTarget[][],
  replicates: number = 1,
): CombinatorialLiquidAndTarget[][] {
  /**
   * The base algorithm was adapted from the link below, and there are many examples of
   * JS-based cartiesian functions in the linked stackoverflow.
   *
   * https://amitd.co/code/typescript/cartesian-product
   * https://stackoverflow.com/questions/12303989/cartesian-product-of-multiple-arrays-in-javascript
   *
   */
  return liquids
    .reduce<CombinatorialLiquidAndTarget[][]>(
      (results, names) =>
        results
          .map(result => names.map(name => [...result, name]))
          .reduce((nested, result) => [...nested, ...result]),
      [[]],
    )
    .flatMap(combinations =>
      Array.from({ length: replicates }).fill(combinations),
    ) as CombinatorialLiquidAndTarget[][];
}

/**
 * Assigns the combinatorialMixes to wells on plates, overspilling to multiple plates if needed.
 *
 * @param combinatorialMixes A 2D array, where each entry is the list of liquids to mix together.
 *
 * @param plateType The plate type used.
 *
 * @returns A map of unique plate name and the PlateLayer contents for each plate.
 */
export function generateLayersForPlate(
  combinatorialMixes: CombinatorialLiquidAndTarget[][],
  plateType: PlateType,
  plateName: string,
  replicates: number,
  totalVolume?: Measurement,
  wellAvailability?: WellAvailability,
): Map<string, PlateAssignment> {
  /**
   * Split the combinatorialMixesPerPlate so they fit on each plate/available wells.
   * Prioritise wellavailability, and if it's not specified, we use the real plate dimensions.
   */
  const divisor = wellAvailability
    ? wellAvailability.available.length
    : plateType.rows * plateType.columns;
  const combinatorialMixesPerPlate = chunk(combinatorialMixes, divisor);

  /**
   * We go over the "chunks" of mixes and create liquids and wellsets for each
   * of these. Each individual mixture will be mixed into the same well.
   */
  const wellsOnPlate = wellAvailability
    ? wellAvailability.available.map(well => ({
        x: well.col,
        y: well.row,
      }))
    : mapPlateWells<WellCoord>(plateType, WellSortMode.BY_ROW, (col, row) => ({
        x: col,
        y: row,
      }));

  const layersPerPlate = new Map<string, PlateAssignment>();
  combinatorialMixesPerPlate.forEach((combinatorialMixes, plateIndex) => {
    const orderedPlateName = createPlateName(plateName, plateIndex);

    const plateLayersInOrder = new Map<number, PlateLayer>();

    combinatorialMixes.forEach((liquidCombination, wellIndex) => {
      const well = wellsOnPlate[wellIndex];

      liquidCombination.forEach((liquid, liquidIndex) => {
        if (!plateLayersInOrder.has(liquidIndex)) {
          plateLayersInOrder.set(liquidIndex, {
            id: `${orderedPlateName}-layer-${liquidIndex + 1}`,
            name: '',
            wellSets: [],
            liquids: [],
          });
        }

        const targetString =
          'concentration' in liquid.target
            ? formatMeasurementObj(liquid.target.concentration)
            : formatMeasurementObj(liquid.target.volume);
        const wellSetId = `${orderedPlateName}-${liquid.nameOrGroup}-${targetString}`;

        if (
          !plateLayersInOrder.get(liquidIndex)?.liquids.find(liquidAssigmnent => {
            if (liquid.isPartOfGroup) {
              return (
                liquidAssigmnent.liquidGroup === liquid.nameOrGroup &&
                isEqual(liquidAssigmnent.target, liquid.target)
              );
            }
            return (
              liquidAssigmnent.liquidName === liquid.nameOrGroup &&
              isEqual(liquidAssigmnent.target, liquid.target)
            );
          })
        ) {
          plateLayersInOrder.get(liquidIndex)?.liquids.push({
            wellSetID: wellSetId,
            liquidName: liquid.isPartOfGroup ? undefined : liquid.nameOrGroup,
            liquidGroup: liquid.isPartOfGroup ? liquid.nameOrGroup : undefined,
            target: liquid.target,
          });
        }

        if (
          !plateLayersInOrder
            .get(liquidIndex)
            ?.wellSets.find(wellSet => wellSet.id === wellSetId)
        ) {
          plateLayersInOrder.get(liquidIndex)?.wellSets.push({
            id: wellSetId,
            wells: [],
          });
        }
        plateLayersInOrder
          .get(liquidIndex)
          ?.wellSets.find(wellSet => wellSet.id === wellSetId)
          ?.wells.push(well);
      });
    });

    // We display these in reverse order (last layer, first) in UI.
    const plateLayersInReverseOrder = [...plateLayersInOrder.values()].reverse();

    layersPerPlate.set(orderedPlateName, {
      plateType: plateType.type,
      plateLayers: plateLayersInReverseOrder,
      assignmentMode: PlateAssignmentMode.COMBINATORIAL,
      replicates: replicates,
      totalVolume: totalVolume,
      namingMode: NamingMode.NONE,
      namingPlateLayerID:
        plateLayersInReverseOrder[plateLayersInReverseOrder.length - 1]?.id ?? '',
    });
  });

  return layersPerPlate;
}
