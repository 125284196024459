import React, { useCallback, useMemo, useState } from 'react';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import { useGenerateWellsWithSnackbar } from 'client/app/components/Parameters/PlateDescriptionsEditor/lib/generateWellIterationUtils';
import { ExtraPlateDescriptionProps } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateDescriptionEditor';
import { PlateRegionRenderProps } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateRegionParameters';
import { getAllWells } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/utils';
import {
  ExtraPlateRegionParameters,
  PlateRegion,
  PlateSetDescription,
} from 'common/types/plateSetDescription';
import { PlateType } from 'common/types/plateType';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  value: PlateSetDescription;
  onChange: (value: PlateSetDescription | null) => void;
  isDisabled: boolean;
  extraProps: Omit<ExtraPlateDescriptionProps, 'elementInstanceId'>;
};

export function usePlateDescriptionState(props: Props) {
  // these props are only provided with the initial values on opening the
  // panel. We are in charge of maintaining the state internally
  const { value, onChange, isDisabled, extraProps } = props;
  const { RegionNameComponent, ExtraParametersComponent, isLayoutOptimised } = extraProps;
  const [getPlateType] = usePlatesByType();

  const [plateDescription, setPlateDescription] = useState(() => value);
  const [deletionDialog, openDeletionDialog] = useDialog(ConfirmationDialog);
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);
  const generateWells = useGenerateWellsWithSnackbar();
  const [plateType, setPlateType] = useState(
    plateDescription.plateTypeName
      ? getPlateType(plateDescription.plateTypeName)
      : undefined,
  );

  const canUpdatePlateRegion = plateType !== undefined;

  const getColor = useCallback(
    (value: string = '') => liquidColors.getColorFromLiquidString(value),
    [liquidColors],
  );

  const updatePlateType = useCallback(
    async (plateTypeName?: string) => {
      if (!plateTypeName) {
        const isDeletionConfirmed = await openDeletionDialog({
          action: 'delete',
          isActionDestructive: true,
          object: 'plate type',
          additionalMessage:
            'This action will delete all plate regions. If you chose a different plate type, the regions will be automatically adjusted.',
        });
        if (!isDeletionConfirmed) {
          return;
        }
      }
      setPlateDescription(prev => {
        if (!plateTypeName) {
          setPlateType(undefined);
          const nextDescription = { ...prev, plateTypeName: '', regions: {} };
          onChange(nextDescription);
          return nextDescription;
        }

        let nextRegions = { ...prev.regions };
        setPlateType(prevPlateType => {
          const nextPlateType = getPlateType(plateTypeName);
          const excessWells = getWellsExclusiveToPrevPlate(nextPlateType, prevPlateType);
          nextRegions = removeWellsFromRegions(nextRegions, excessWells);
          nextRegions = removeRegionsWithNoWells(nextRegions);
          return nextPlateType;
        });

        const nextDescription = { ...prev, plateTypeName, regions: nextRegions };
        onChange(nextDescription);
        return nextDescription;
      });
    },
    [getPlateType, onChange, openDeletionDialog],
  );

  const updatePlateRegion = useCallback(
    (name: string, update: PlateRegion, resolveWellConflicts?: boolean) => {
      if (!canUpdatePlateRegion) return;
      setPlateDescription(prev => {
        const nextRegions = resolveWellConflicts
          ? removeWellsFromRegions(prev.regions, update.wells)
          : prev.regions;

        const { generatedWells, isSuccess } = generateWells(
          update.wells,
          update.wellPattern,
          update.wellIteration,
        );
        if (!isSuccess) {
          return prev;
        }

        const next = {
          ...prev,
          regions: removeRegionsWithNoWells({
            ...nextRegions,
            [name]: {
              ...(nextRegions?.[name] || {}),
              ...update,
              wells: generatedWells,
            },
          }),
        };
        onChange(next);
        return next;
      });
    },
    [canUpdatePlateRegion, generateWells, onChange],
  );

  const deletePlateRegions = useCallback(
    async (regionNames: string[]) => {
      const isConfirmed = await openDeletionDialog({
        action: 'delete',
        isActionDestructive: true,
        object: regionNames.length > 1 ? 'all plate regions' : 'plate region',
      });
      if (!isConfirmed) {
        return;
      }
      setPlateDescription(prev => {
        const nextRegions = { ...prev.regions };
        regionNames.forEach(toDelete => {
          delete nextRegions[toDelete];
        });
        const next = { ...prev, regions: nextRegions };
        onChange(next);
        return next;
      });
    },
    [onChange, openDeletionDialog],
  );

  const renderRegionName = useCallback(
    (props: PlateRegionRenderProps<string>) => <RegionNameComponent {...props} />,
    [RegionNameComponent],
  );

  const renderExtraParameters = useCallback(
    (props: PlateRegionRenderProps<ExtraPlateRegionParameters>) => {
      return ExtraParametersComponent ? <ExtraParametersComponent {...props} /> : null;
    },
    [ExtraParametersComponent],
  );

  return {
    renderRegionName,
    renderExtraParameters,
    isLayoutOptimised,
    plateDescription,
    plateType,
    liquidColors,
    getColor,
    updatePlateType,
    updatePlateRegion,
    deletePlateRegions,
    deletionDialog,
    isDisabled,
  };
}

function getWellsExclusiveToPrevPlate(curr: PlateType, prev?: PlateType) {
  if (!prev) return [];
  if (prev.rows === curr.rows && prev.columns === curr.columns) return [];
  const prevWells = getAllWells(prev);
  const currWellSet = new Set(getAllWells(curr));
  return prevWells.filter(well => !currWellSet.has(well));
}

function removeWellsFromRegions(
  regions: { [name: string]: PlateRegion },
  wells: string[],
): { [name: string]: PlateRegion } {
  if (!wells.length) {
    return { ...regions };
  }
  const toRemove = new Set(wells);
  const entries = Object.entries(regions).map(([name, region]) => {
    const wellsToKeep = region.wells.filter(well => !toRemove.has(well));
    return [name, { ...region, wells: wellsToKeep }];
  });
  return Object.fromEntries(entries);
}

function removeRegionsWithNoWells(regions: { [name: string]: PlateRegion }) {
  const entries = Object.entries(regions).filter(
    ([_, region]) => region.wells.length > 0,
  );
  return Object.fromEntries(entries);
}
