import {
  ArrayAdditionalProps,
  MapAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import canSpecifyDefaultValueForEditor from 'common/elementConfiguration/canSpecifyDefaultValueForEditor';
import { BACKUP_EDITOR_TYPE, EditorType } from 'common/elementConfiguration/EditorType';
import { getAdditionalEditorPropsForEditorType } from 'common/elementConfiguration/getEditorTypeInfo';
import {
  getArrayTypeFromAnthaType,
  getCompoundEditorTypeFromAnthaType,
  getDefaultEditorForAnthaType,
  getDefaultPlaceholder,
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { groupBy } from 'common/lib/data';
import { getObjectFriendlyName } from 'common/lib/format';
import { stringToMarkdown } from 'common/lib/markdown';
import { APIElement, APIElementPort } from 'common/types/api';
import { ParameterValueDict } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import {
  ElementConfigurationSpec,
  ParameterConfigurationSpec,
  ParameterGroupConfigurationSpec,
} from 'common/types/elementConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';

/**
 * Creates a new ElementConfigurationSpec.
 *
 * When creating a new ElementConfigurationSpec we will do either of the following:
 * 1) If a currentConfiguration is provided, we will source configuration fields from this.
 * 2) If not, then we will source the configuration fields from the element (APIElement).
 *
 * When creating a parameterConfigurationSpec we will do either of the following:
 * 1) If a currentConfiguration is provided, we will source the parameterConfigurationSpec from these.
 * 2) If no currentConfiguration is supplied, but typeConfigurations are supplied, then we will source the parameterConfigurationSpec from these.
 * 3) If neither are provided, we will create a new parameterConfigurationSpec with default values.
 *
 * @param element The APIElement of the element for which the spec is being created.
 * @param currentConfiguration An existing ElementConfigurationSpec.
 * @param defaults A map of default values by type name.
 * @param typeConfigurations A map of type configurations by type name.
 */
export function createConfigurationSpec(
  element: APIElement,
  currentConfiguration: ElementConfigurationSpec | null,
  defaults?: ParameterValueDict,
  typeConfigurations?: Record<string, TypeConfigurationSpec>,
): ElementConfigurationSpec {
  function createParameterConfig(port: APIElementPort): ParameterConfigurationSpec {
    return (
      currentConfiguration?.parameters?.[port.name] ??
      createParameterConfigSpecFromTypeConfigs(port, typeConfigurations, defaults)
    );
  }

  return {
    elementDisplayName:
      currentConfiguration?.elementDisplayName ?? getObjectFriendlyName(element.name),

    elementDisplayDescription:
      currentConfiguration?.elementDisplayDescription ??
      stringToMarkdown(element.description),

    // current configuration for parameters might be out of date - some parameters might have been
    // removed, some might have been added. New configuration must contain configuration only for
    // parameters that are currently present at the element, and it must also drop removed parameters
    parameters: [...element.in_ports, ...element.out_ports].reduce(
      (acc, port: APIElementPort) => ({
        ...acc,
        [port.name]: createParameterConfig(port),
      }),
      {},
    ),
    inputGroups: getParameterGroups(element.in_ports, currentConfiguration?.inputGroups),
    outputOrder: getOutputOrder(element.out_ports, currentConfiguration?.outputOrder),
    rules: currentConfiguration?.rules ?? element.configuration?.rules,
  };
}

export function getDefaultsForParameterEditor(
  type: string,
  typeConfiguration?: TypeConfigurationSpec,
) {
  const defaultEditor =
    getDefaultEditorForAnthaType(type, typeConfiguration) ?? BACKUP_EDITOR_TYPE;

  const additionalProps =
    typeConfiguration?.defaultEditorConfiguration.additionalProps ||
    getAdditionalEditorPropsForEditorType(defaultEditor, type);

  const placeholder =
    typeConfiguration?.defaultEditorConfiguration.placeholder ||
    getDefaultPlaceholder(type);

  return { defaultEditor, additionalProps, placeholder };
}

type APIElementPortWithoutGroupProps = Omit<
  APIElementPort,
  'groupName' | 'groupDescription'
>;

/**
 * Generates a ParameterConfigurationSpec for a given port.
 *
 * This is used when no element configuration is associated with the element
 * that the given port comes from. It provides the relevant fallback type
 * configuration data along with the port to the UI.
 *
 * @param port The port (parameter) a spec should be generated for.
 * @param typeConfigurations A map of type configurations by type name.
 */
export function createParameterConfigSpecFromTypeConfigs(
  port: APIElementPortWithoutGroupProps,
  typeConfigurations?: Record<string, TypeConfigurationSpec | undefined>,
  defaults?: ParameterValueDict,
): ParameterConfigurationSpec {
  function getEditorConfigurationFromTypeConfig(typeName: string) {
    const typeConfiguration = typeConfigurations?.[typeName];
    return typeConfiguration?.defaultEditorConfiguration ?? undefined;
  }

  const parameterEditorConfigurationSpec = createParameterEditorConfigurationSpec(
    port.type,
    getEditorConfigurationFromTypeConfig,
  );

  const canAddDefaultValue =
    !!defaults?.[port.name] &&
    canSpecifyDefaultValueForEditor(
      parameterEditorConfigurationSpec.type,
      parameterEditorConfigurationSpec.additionalProps ?? undefined,
    );

  return {
    displayName: port.name,
    displayDescription: stringToMarkdown(port.description),
    isVisible: true,
    isConnectable: true,
    isInternalOnly: false,
    editor: parameterEditorConfigurationSpec,
    ...(canAddDefaultValue ? { defaultValue: defaults?.[port.name] } : {}),
  };
}

export function getParameterGroups(
  ports: APIElementPort[],
  currentGroups?: ParameterGroupConfigurationSpec[],
): ParameterGroupConfigurationSpec[] {
  const existingParameters = currentGroups?.flatMap(group => group.parameterNames) ?? [];
  const existingGroups = currentGroups?.map(group => group.groupName) ?? [];

  // Remove any parameters from the existing grouping that have been removed from the element
  const updatedExistingGroups = currentGroups
    ? currentGroups.map(({ groupName, groupDescription, parameterNames }) => ({
        groupName,
        groupDescription,
        parameterNames: parameterNames.filter(name =>
          ports.find(newPort => newPort.name === name),
        ),
      }))
    : [];

  const newParameters = ports.filter(port => !existingParameters.includes(port.name));
  const newParametersInExistingGroups = newParameters.filter(port =>
    existingGroups.includes(port.groupName),
  );
  const newParametersInNewGroups = newParameters.filter(
    port => !existingGroups.includes(port.groupName),
  );

  // Add new parameters that should be part of an existing group to that group
  newParametersInExistingGroups.forEach(parameter => {
    const group = updatedExistingGroups.find(
      group => group.groupName === parameter.groupName,
    );
    group?.parameterNames.push(parameter.name);
  });

  const newGroupedPorts = groupBy(
    // change groupName with value null/undefined to empty string
    newParametersInNewGroups.map(port => ({
      ...port,
      groupName: port.groupName ?? '',
    })),
    'groupName',
  );

  const newGroups = Object.entries(newGroupedPorts).map(([groupName, ports]) => ({
    groupName,
    parameterNames: ports.map(port => port.name),
  }));

  return [...updatedExistingGroups, ...newGroups];
}

export function getOutputOrder(ports: APIElementPort[], existingPortOrder?: string[]) {
  if (!existingPortOrder) {
    return ports.map(port => port.name);
  }

  // Remove any ports from the existing order if they've been removed from the element
  const filteredExistingPorts = existingPortOrder.filter(name =>
    ports.find(port => port.name === name),
  );
  const newPortNames = ports
    .map(port => port.name)
    .filter(port => !existingPortOrder.includes(port));
  return filteredExistingPorts.concat(newPortNames);
}

/**
 * Given an antha type, returns the default ParameterEditorConfigurationSpec
 * for that type. If getEditorConfigurationFromTypeConfig is provided, defaults
 * will be sourced from that. If nothing is returned from it or it is not
 * provided, defaults will be sourced from the Parameter Registry.
 *
 * @param anthaType Can be compound or non-compound.
 * @param getEditorConfigurationFromTypeConfig Used to extract defaults from
 * type configs rather than the Parameter Registry where possible.
 */
export function createParameterEditorConfigurationSpec(
  anthaType: string,
  getEditorConfigurationFromTypeConfig?: (
    typeName: string,
  ) => ParameterEditorConfigurationSpec | undefined,
  editorType?: EditorType,
): ParameterEditorConfigurationSpec {
  const typeConfigEditorConfigSpec = getEditorConfigurationFromTypeConfig?.(anthaType);
  const typeConfigPlaceholder = typeConfigEditorConfigSpec?.placeholder;
  const compoundEditorType = editorType ?? getCompoundEditorTypeFromAnthaType(anthaType);

  if (compoundEditorType && !typeConfigEditorConfigSpec) {
    if (compoundEditorType === EditorType.ARRAY) {
      const itemAnthaType = getArrayTypeFromAnthaType(anthaType);
      const itemEditor = createParameterEditorConfigurationSpec(
        itemAnthaType,
        getEditorConfigurationFromTypeConfig,
      );

      return {
        type: EditorType.ARRAY,
        placeholder: typeConfigPlaceholder ?? getDefaultPlaceholder(anthaType),
        additionalProps: {
          editor: EditorType.ARRAY,
          itemEditor,
        } as ArrayAdditionalProps,
      };
    }

    if (compoundEditorType === EditorType.MAP) {
      const keyAnthaType = getKeyTypeFromAnthaType(anthaType);
      const keyEditor = createParameterEditorConfigurationSpec(
        keyAnthaType,
        getEditorConfigurationFromTypeConfig,
      );

      const valueAnthaType = getValueTypeFromAnthaType(anthaType);
      const valueEditor = createParameterEditorConfigurationSpec(
        valueAnthaType,
        getEditorConfigurationFromTypeConfig,
      );

      return {
        type: EditorType.MAP,
        placeholder: typeConfigPlaceholder ?? getDefaultPlaceholder(anthaType),
        additionalProps: {
          editor: EditorType.MAP,
          keyEditor,
          valueEditor,
        } as MapAdditionalProps,
      };
    }
  }

  const editor =
    editorType ??
    typeConfigEditorConfigSpec?.type ??
    getDefaultEditorForAnthaType(anthaType);

  const additionalEditorProps =
    typeConfigEditorConfigSpec?.additionalProps ??
    getAdditionalEditorPropsForEditorType(editor, anthaType);

  return {
    type: editor,
    placeholder: typeConfigPlaceholder ?? getDefaultPlaceholder(anthaType),
    additionalProps: additionalEditorProps,
  };
}
