import React, { useCallback } from 'react';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import TextField from 'common/ui/filaments/TextField';
import useMultilineStringArrayChange, {
  splitMultilineStringByLine,
} from 'common/ui/hooks/useMultilineStringArrayChange';

type Props = {
  onChange: (newValue: string[] | undefined, elementInstanceId?: string) => void;
  /**
   * Needed to mitigate the fact that switching instances in the Workflow Builder doesn't
   * cause blurring. :'( */
  elementInstanceId?: string;
} & ParameterEditorBaseProps<string[]>;

const StringArrayEditor = React.memo(function StringArrayEditor(props: Props) {
  const { value, isDisabled, placeholder, elementInstanceId } = props;

  // According to an old comment from 2019, we have some string[] parameters
  // that have a string stored instead.
  // TODO: Check whether this is still the case.
  const safeValue = typeof value === 'string' ? [value] : value ?? [];
  const [_, valueString, handleChangeEvent] = useMultilineStringArrayChange(safeValue);

  const propsOnChange = props.onChange;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChangeEvent(e);
      // If it wasn't for the hack we're doing here to ensure blurring the input
      // by switching which element instance is selected in the Workflow Builder actually
      // reflects user updates afterwards, we could rely on the first value
      // from the string array hook and utilise onBlur. ;_;
      // TODO: Do the above when we fix the inability to rely on blur events in
      // the Instance Parameters pane.
      const stringArray = splitMultilineStringByLine(e.target.value);
      propsOnChange(stringArray, elementInstanceId);
    },
    [handleChangeEvent, elementInstanceId, propsOnChange],
  );

  return (
    <TextField
      value={valueString}
      onChange={onChange}
      disabled={isDisabled}
      placeholder={placeholder}
      multiline
      fullWidth
    />
  );
});

export default StringArrayEditor;
