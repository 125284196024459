import { useEffect, useRef, useState } from 'react';

/**
 * useOverflow enables you to detect if a HTML elements contents are overflowing.
 *
 * @example
 *  ```
 *    const { isOverflowing, handleOverflow, ref } = useOverflow()
 *    const text = "My supercalifragilisticexpialidocious sentence keeps going on and on."
 *    const content = <Typography noWrap onMouseOver={handleOverflow} ref={ref}>{text}</Typography>
 *    return isOverflowing ? <Tooltip={text}>{content}</Tooltip> : content
 *  ```
 */
export default function useOverflow() {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleOverflow = () => {
    if (ref.current) {
      setIsOverflowing(ref?.current?.scrollWidth > ref?.current?.clientWidth);
    }
  };

  useEffect(() => {
    handleOverflow();
    window.addEventListener('resize', handleOverflow);
    return () => window.removeEventListener('resize', handleOverflow);
  }, []);

  return {
    handleOverflow,
    isOverflowing,
    ref,
  };
}
