import React from 'react';

import { styled } from '@mui/material/styles';

import Button from 'common/ui/components/Button';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  title: string;
  onClose?: () => void;
};

export function Header({ title, onClose }: Props) {
  return (
    <TitleBar>
      <TypographyWithTooltip variant="h5">{title}</TypographyWithTooltip>
      {onClose ? (
        <Button size="small" onClick={onClose} variant="tertiary" color="primary">
          Done
        </Button>
      ) : null}
    </TitleBar>
  );
}

const TitleBar = styled('div')(({ theme }) => ({
  gridArea: 'titlebar',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  height: '50px',
}));
