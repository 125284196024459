import { useCallback, useEffect } from 'react';

import { DeviceConfigOption } from 'client/app/state/ConfiguredDevicesProvider/configurationOptions';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import {
  fromNamedPlate,
  getLayoutPreference,
  LabwareType,
  NamedPlate,
} from 'client/app/state/LabwarePreference';
import { ConfiguredDevice } from 'common/types/bundle';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  onChange: (configuredDevice: ConfiguredDevice) => void;
};

export function useDeckOptions({ onChange }: Props) {
  const [confirmChangeDialog, openConfirmChangeDialog] = useDialog(ConfirmationDialog);
  const {
    activeMainConfiguredDevice,
    activeMainConfiguredDeviceConfig,
    availableMainDeviceConfigOptions,
    loadingConfigOptions,
    loadingErrorMessage,
  } = useConfiguredDevicesContext();

  const selectConfig = useCallback(
    async (newRunConfiguration?: DeviceConfigOption) => {
      if (!newRunConfiguration) return;

      // in order to even show options, we must have an active device hence !
      if (activeMainConfiguredDevice!.runConfigId) {
        const confirmed = await openConfirmChangeDialog({
          object: 'deck layout',
          action: 'switch',
          additionalMessage:
            'You will lose the changes you have made to labware positions and will require manual updates to Move Plates elements. Please select the positions again.',
          isActionDestructive: true,
        });
        if (!confirmed) {
          return;
        }
      }

      const newConfiguredDevice: ConfiguredDevice = {
        ...activeMainConfiguredDevice!,
        layoutPreferences: undefined,
        runConfigId: newRunConfiguration.value as string,
        runConfigVersion: newRunConfiguration.version,
      };

      onChange(newConfiguredDevice);
    },
    [activeMainConfiguredDevice, onChange, openConfirmChangeDialog],
  );

  const resetConfigWithDefaults = useCallback(async () => {
    // by now we expect an active device and config with defaults to be
    // selected; if not something went wrong
    const confirmed = await openConfirmChangeDialog({
      object: 'deck positions',
      action: 'reset',
      additionalMessage:
        'Resetting will restore the default labware positions for this deck configuration.',
      cancelButtonLabel: 'cancel',
      confirmButtonLabel: 'reset',
      isActionDestructive: true,
    });
    if (!confirmed) {
      return;
    }

    const newConfiguredDevice: ConfiguredDevice = {
      ...activeMainConfiguredDevice!,
      layoutPreferences: {
        ...activeMainConfiguredDeviceConfig!.defaultLayoutOptions,
        plates: {},
      },
    };

    onChange(newConfiguredDevice);
  }, [
    activeMainConfiguredDevice,
    activeMainConfiguredDeviceConfig,
    onChange,
    openConfirmChangeDialog,
  ]);

  const updateLabwareTypePreferences = useCallback(
    (positions: string[], labwareType?: LabwareType) => {
      if (!labwareType || !activeMainConfiguredDevice?.layoutPreferences) return;

      const { key, isPlateName } = getLayoutPreference(labwareType);
      if (isPlateName) {
        const newConfiguredDevice: ConfiguredDevice = {
          ...activeMainConfiguredDevice,
          layoutPreferences: {
            ...activeMainConfiguredDevice.layoutPreferences,
            plates: {
              ...activeMainConfiguredDevice.layoutPreferences.plates,
              [key]: positions,
            },
          },
        };
        onChange(newConfiguredDevice);
        return;
      }

      const newConfiguredDevice: ConfiguredDevice = {
        ...activeMainConfiguredDevice,
        layoutPreferences: {
          ...activeMainConfiguredDevice.layoutPreferences,
          [key]: positions,
        },
      };
      onChange(newConfiguredDevice);
    },
    [activeMainConfiguredDevice, onChange],
  );

  const renameNamedPlate = useCallback(
    (oldName: NamedPlate, newName: NamedPlate) => {
      const oldPrefix = fromNamedPlate(oldName);
      const newPrefix = fromNamedPlate(newName);
      if (!newPrefix || !activeMainConfiguredDevice?.layoutPreferences) return;

      const update = { ...activeMainConfiguredDevice.layoutPreferences.plates };
      update[newPrefix] = update[oldPrefix];
      delete update[oldPrefix];

      const newConfiguredDevice: ConfiguredDevice = {
        ...activeMainConfiguredDevice,
        layoutPreferences: {
          ...activeMainConfiguredDevice.layoutPreferences,
          plates: update,
        },
      };
      onChange(newConfiguredDevice);
    },
    [activeMainConfiguredDevice, onChange],
  );

  const deleteNamedPlate = useCallback(
    (name: NamedPlate) => {
      const namePrefix = fromNamedPlate(name);
      if (!namePrefix || !activeMainConfiguredDevice?.layoutPreferences) return;

      const update = { ...activeMainConfiguredDevice.layoutPreferences.plates };
      delete update[namePrefix];

      const newConfiguredDevice: ConfiguredDevice = {
        ...activeMainConfiguredDevice,
        layoutPreferences: {
          ...activeMainConfiguredDevice.layoutPreferences,
          plates: update,
        },
      };
      onChange(newConfiguredDevice);
    },
    [activeMainConfiguredDevice, onChange],
  );

  // after selecting a device config for the first time, re-initialise the
  // device with the defaults once they are fetched without confirmation
  useEffect(() => {
    if (
      activeMainConfiguredDevice &&
      activeMainConfiguredDeviceConfig &&
      activeMainConfiguredDevice.layoutPreferences === undefined
    ) {
      const deviceWithDefaults: ConfiguredDevice = {
        ...activeMainConfiguredDevice,
        layoutPreferences: {
          ...activeMainConfiguredDeviceConfig.defaultLayoutOptions,
          plates: {},
        },
      };
      onChange(deviceWithDefaults);
    }
  }, [
    activeMainConfiguredDevice,
    activeMainConfiguredDevice?.layoutPreferences,
    activeMainConfiguredDeviceConfig,
    onChange,
    resetConfigWithDefaults,
  ]);

  return {
    loading: loadingConfigOptions,
    loadingErrorMessage,
    activeMainConfiguredDevice,
    activeMainConfiguredDeviceConfig,
    availableMainDeviceConfigOptions,
    confirmChangeDialog,
    selectConfig,
    resetConfigWithDefaults,
    updateLabwareTypePreferences,
    renameNamedPlate,
    deleteNamedPlate,
  };
}
