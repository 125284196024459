import { useMemo } from 'react';

import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { pluralize } from 'common/lib/format';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';

export function useSelectedDeckConfiguration() {
  const { activeMainConfiguredDeviceConfig, loadingConfigOptions } =
    useConfiguredDevicesContext();

  const deckState = activeMainConfiguredDeviceConfig?.layout;
  const deckLayout = useMemo(
    () =>
      deckState
        ? new DeckLayout({
            before: deckState,
            after: deckState,
            version: '',
          })
        : null,
    [deckState],
  );

  if (loadingConfigOptions) {
    return {
      loading: true,
      deckLayout,
      plateMoveToPositions: null,
    };
  }

  const moveablePositions =
    activeMainConfiguredDeviceConfig?.validAddresses.moveableLocations || [];
  const plateMoveToPositions = moveablePositions.length
    ? new Set<string>(moveablePositions)
    : null;

  return {
    loading: false,
    deckLayout,
    plateMoveToPositions,
  };
}

export const getSelectPositionsButtonCopy = (selectedPositions: string[] | undefined) => {
  return !selectedPositions?.length
    ? 'Select deck positions'
    : pluralize(selectedPositions.length, 'position') + ' selected';
};
