import React, { useCallback } from 'react';

import { TipTypeDropdownEditor } from 'client/app/components/Parameters/DeviceConfigurator/TipTypeDropdownEditor';
import { EditorType } from 'common/elementConfiguration/EditorType';
import ArrayEditor from 'common/ui/components/ParameterEditors/ArrayEditor';

type Props = {
  value?: string[];
  onChange: (tipTypeValues?: string[]) => void;
  isDisabled?: boolean;
};

export function DeviceTipTypeSelector({
  value: tipTypes = [],
  onChange: handleTipTypesChange,
  isDisabled = false,
}: Props) {
  const handleTipTypeDelete = useCallback(
    (index: number) => handleTipTypesChange(tipTypes?.toSpliced(index, 1)),
    [handleTipTypesChange, tipTypes],
  );

  return (
    <ArrayEditor
      anthaType=""
      component={TipTypeDropdownEditor}
      value={tipTypes}
      onChange={handleTipTypesChange}
      onItemDelete={handleTipTypeDelete}
      overrideAddNewItemCopy="Add tip type preference"
      itemEditorProps={{
        placeholder: '',
        type: EditorType.TIP_TYPE,
      }}
      isDisabled={isDisabled}
    />
  );
}
